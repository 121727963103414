// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import moment from 'moment';
import { FC } from 'react';
import { i18n } from '@lingui/core';
import { BookitupNotification } from '../../../../Types/LogT';
import { RoutingS } from '../../../../Service/RoutingS';

const ProcedureFailed: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { eventId, eventDate, value, personDescription } = notification;

  return (
    <Text>
      Der Workflow{'  '}
      <Text span weight="bolder">
        {i18n._(`actionType.${value}`)}
      </Text>
      {'  '}ist fehlgeschlagen und kann für ein{'  '}
      <Text
        span
        weight="bolder"
        variant="link"
        color="blue"
        onClick={() => RoutingS.openInNewTab(`/events/${eventId}/details`)}
        sx={{ cursor: 'pointer' }}
      >
        Event{'  '}
      </Text>
      {eventDate && (
        <>
          am
          <Text span weight="bolder">
            {` ${moment(eventDate).format('LL')} `}
          </Text>
          für Kunde{'  '}
        </>
      )}
      {!eventDate && 'von '}
      <Text span weight="bolder">
        {personDescription}
      </Text>
      {'  '}nicht ausgeführt werden.
    </Text>
  );
};

export default ProcedureFailed;
