import React, { useContext, useEffect, useState } from 'react';
import { Pane } from '../../../Molecules/Pane';
import TooltipButton from '../../../Atoms/buttons/TooltipButton';
import { EditableProfileProperty } from '../../../Atoms/EditableProperties/EditableProfileProperty';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import { fetchFile } from '../../../Service/restapi/fileService';
import { HTMLEditableText } from '../../../Atoms/inputs/HTMLEditableText';
import { HtmlEditButtonGroup } from '../../../Atoms/ButtonGroup';
import { HtmlFormatter } from '../../../Atoms/Formatter/HtmlFormatter';
import { EditableDivInput } from '../../../Atoms/inputs/EditableDivInput';
import PanePaper from '../../../Molecules/PanePaper';
import { RoutingS } from '../../../Service/RoutingS';
import BookitupTutorialIcon from "../../../Atoms/BookitupTutorialIcon";

export default function () {
  const { profile } = useContext(ProfileContext);
  const [signature, setSignature] = useState(profile.signature);
  const [sigContainer, setSigContainer] = useState({
    id: profile.id,
    signature,
    lastModified: new Date().toString(),
  });
  const [editor, setEditor] = useState('Text_Editor');

  useEffect(() => {
    setSignature(profile.signature);
    setSigContainer({
      id: profile.id,
      signature: profile.signature,
      lastModified: new Date().toString(),
    });
    replaceWithImages(profile.signature);
  }, [profile.signature]);

  useEffect(() => {
    RoutingS.changeTitle('Signatur');
    replaceWithImages(signature);
  }, []);

  function replaceWithImages(input) {
    if (input) {
      const placeholderArray = input.match(/\{image_(.*?)\}/g);
      if (placeholderArray) {
        for (let i = 0; i < placeholderArray.length; i++) {
          const imageName = placeholderArray[i].substring(7, placeholderArray[i].length - 1);

          fetchFile('profiles', profile.id, imageName)
            .then((resp) => {
              if (resp.ok) {
                resp.blob().then((blob) => {
                  input = input.replace(
                    placeholderArray[i],
                    `<img title='${imageName}' src='${window.URL.createObjectURL(blob)}'/>`,
                  );
                  if (i === placeholderArray.length - 1) {
                    console.log('no more placeholders -> ', input);
                    setSignature(input);
                    setSigContainer({
                      id: profile.id,
                      signature: input,
                      lastModified: new Date().toString(),
                    });
                  }
                });
              } else {
                console.error(`could not load file ${imageName}`);
              }
            })
            .catch((error) => {
              console.error({ error });
              console.error(`could not load file ${imageName}`);
            });
        }
      }
    }
  }

  return (
    <div style={{ maxWidth: 1000 }}>
      <PanePaper title="applicationUser.signatur" tooltip="help.settings.signature.pane" rightSection={<BookitupTutorialIcon videoUrl={"https://app.bookitup.de/academy/channels/automatic-responses?episodeId=00qto1iesx"} name={"EmailSignature"} />}>
        <>
          <div style={{ marginBottom: 20 }}>
            <HtmlEditButtonGroup value={editor} onChange={setEditor} />
          </div>

          {editor === 'HTML_Editor' && (
            <EditableProfileProperty
              name="signature"
              patchField="signature"
              profile={sigContainer}
              placeholder="applicationUser.signatur"
              editFormatter={HTMLEditableText}
              editOnly
              settings
            />
          )}
          {editor === 'Text_Editor' && (
            <EditableProfileProperty
              name="signature"
              patchField="signature"
              profile={sigContainer}
              placeholder="applicationUser.signatur"
              big
              editFormatter={EditableDivInput}
              labelFormatter={HtmlFormatter}
              settings
            />
          )}
        </>
      </PanePaper>
    </div>
  );
}
