import { addMonths } from 'date-fns';
import { JwtPayloadInterface, Licence } from './Context/AuthContext';

export type LicenceRestriction = {
  requiredLicence: Licence;
  title: string;
  description: string;
  trialForbidden?: boolean;
  addons?: string[];
};

interface RestrictionInterface {
  [name: string]: LicenceRestriction;
}

const Restrictions: RestrictionInterface = {
  AGENCY_MODE: {
    requiredLicence: 'FLOW',
    title: 'Überblick über alle Vermittlungen',
    description:
      'Mit dem Agenturmodus siehst du in der Eventübersicht alle Vermittlungen inkl. Status und hast so schnell einen Überblick über alle Vermittlungen.',
  },
  MULTIPLE_DOCUMENT_TEMPLATE: {
    requiredLicence: 'PRO',
    title: 'Dokument-Vorlagen',
    description:
      'Bereite Dir mehrere Dokument-Vorlagen vor, um noch schneller individuelle Angebote etc. zu erstellen.',
  },
  MULTIPLE_EMAIL_TEMPLATE: {
    requiredLicence: 'FLOW',
    title: 'Mehr E-Mail-Vorlagen',
    description: 'Hinterlege weitere E-Mail-Vorlagen um Kunden schneller oder automatisiert zu antworten.',
  },
  DOCUMENT_PAYMENTS: {
    requiredLicence: 'PRO',
    title: 'Zahlungen erfassen',
    description: 'Erfasse deine Zahlungen direkt in bookitup und behalte den Überblick über unbezahlte Rechnungen.',
  },
  PRODUCT_USAGE: {
    requiredLicence: 'PRO',
    title: 'Produkte als Vorlage speichern',
    description:
      'Definiere einmal alle Deine Produkte/Dienstleistungen (auch mit Platzhaltern) und nutze sie wann immer Du sie braucht.',
  },
  SAVE_PARTIAL_INVOICE: {
    requiredLicence: 'PRO',
    title: 'Abschlags- und Schlussrechnungen erstellen',
    description:
      'Stelle ganz einfach z.B. 30% als Anzahlung in Rechnung - und nach dem Event mit wenigen Klicks die Schlussrechnung.',
  },
  CUSTOM_ATTRIBUTE: {
    requiredLicence: 'PRO',
    title: 'Füge eigene Felder hinzu',
    description:
      'Individualisiere bookitup nach Deinen Bedürfnissen. Eigene Felder ermöglichen es Dir Informationen zum Event geordnet abzulegen und auch mit Hilfe von Platzhaltern in allen Dokumenten und E-Mail weiterzuverwenden. Zudem können eigene Felder schon durch Dein Kontaktformular auf Deiner Webseite befüllt werden.',
  },
  NETWORK_PROVIDER: {
    requiredLicence: 'FLOW',
    title: 'Beschleunige Deine Vermittlungen',
    description:
      'Kollegen die ebenfalls bookitup nutzen, kannst du hier hinzufügen und siehst dann sofort, welcher Dienstleister noch Zeit hat - ohne ihm extra eine E-Mail-Anfrage zu schicken.',
  },
  CONTRACT_USAGE: {
    requiredLicence: 'PRO',
    title: 'Verträge erstellen',
    description:
      'Erstelle Deine Verträge direkt mit bookitup und nutze Platzhalter um mit wenigen Klicks Deinen Vertrag versandbereit zu machen.',
  },
  FRAGEBOGEN_USAGE: {
    requiredLicence: 'PRO',
    title: 'Infos einholen mit Fragebögen',
    description:
      'Mit Fragebögen holst Du Dir schnell und einfach alle relevanten Informationen (Event-Infos, Musikwünsche, ...) von Deinem Kunden.',
  },
  SHARE_PROFILE: {
    requiredLicence: 'FLOW',
    title: 'Zugänge für Dein Team',
    description:
      'Teile deinen bookitup Account mit deinen Teammitgliedern - sie erhalten einen eigenen Zugang und werden ebenso wie Du über alles per E-Mail informiert.',
  },
  SAVE_TASK: {
    requiredLicence: 'PRO',
    title: 'Aufgaben im Überblick',
    description:
      'Lege Aufgaben direkt zum Event (oder auch Allgemein) an. In der Aufgaben-Übersicht siehst du - auch im Zusammenhang mit dem Event-Status - sofort, welche Aufgaben Priorität haben.',
  },
  SAVE_QUESTIONNAIRE: {
    requiredLicence: 'PRO',
    title: 'Infos einholen mit Fragebögen',
    description:
      'Mit Fragebögen holst Du Dir schnell und einfach alle relevanten Informationen (Event-Infos, Musikwünsche, ...) von Deinem Kunden.',
  },
  EVENT_UPLOAD_FILE: {
    requiredLicence: 'PRO',
    title: 'Lade weitere Dateien hoch',
    description:
      'Hier kannst du weitere Dateien hochladen, die Du außerhalb von bookitup erstellst, damit alles an einem Ort ist und Du nichts vergisst.',
  },
  PORTAL: {
    requiredLicence: 'INTERACTIVE',
    title: 'Lass den Papierkram hinter Dir',
    description:
      'Mit dem Interactive-Modul kannst Du Deinem Kunden Fragebögen erstellen und freigeben, Verträge mit digitaler Unterschrift bereitstellen und Dein Angebot mit Bildern und Videos zu jeder Position besser präsentieren.',
  },
  LEX_OFFICE: {
    requiredLicence: 'PRO',
    title: 'lexoffice verbinden',
    description:
      'Übertrage Deine in bookitup erstellen Rechnungen mit einem Klick und vervollständige damit deinen Workflow bis zum Finanzamt.',
  },
  SEVDESK: {
    requiredLicence: 'PRO',
    title: 'sevDesk verbinden',
    description:
      'Übertrage Deine in bookitup erstellen Rechnungen mit einem Klick und vervollständige damit deinen Workflow bis zum Finanzamt.',
  },
  MULTIPLE_EVENT_NOTES: {
    requiredLicence: 'PRO',
    title: 'Mehrere Notizen',
    description:
      'Teile Deine Notizen auf z.B. um Gesprächsnotizen zu machen und wähle Zeitpunkt und Dauer des Gesprächs.',
  },
  PRIVATE_CALENDAR: {
    requiredLicence: 'FLOW',
    title: 'Private Kalender-Termine',
    description:
      'bookitup prüft automatisch auch deine privaten Kalendar, damit bei deinem Verfügbarkeits-Check nichts übersehen wird.',
  },
  WORKFLOW: {
    requiredLicence: 'FLOW',
    title: 'Wiederkehrende Aufgaben',
    description:
      'Du hast für Deine Aufträge oft die selben ToDos und möchtest diese besser im Blick behalten? bookitup kann diese ToDos bezugnehmend auf den Buchungsstatus automatisiert erstellen, sodass kein Auftrag auf der Strecke bleibt.',
  },
  CONTACT_FORM: {
    requiredLicence: 'PRO',
    title: 'Mehrere Kontaktformulare',
    description:
      'Erstelle neben deinem normalen ausführlichen Kontaktformular z.B. noch ein kurzes mit E-Mail und Datum oder erstelle verschiedene Stile für unterschiedliche Seiten (Hochzeitsseite, Firmeneventseite, usw.)',
  },
  CONTACT_FORM_ZAPIER: {
    requiredLicence: 'INTERACTIVE',
    title: 'Zapier-Anbindung',
    description:
      'Sende die Daten Deiner Anfrage an Zapier, um Deine Kunden z.B. in ein Newsletter-Programm aufzunehmen.',
  },
  AUTOMATIONS: {
    requiredLicence: 'INTERACTIVE',
    title: 'Workflow-Vorlagen',
    description:
      'Automatisierung, die begeistert: Maximale Effizienz für Ihren Erfolg! Erleben Sie die Zukunft der Produktivität mit unserer innovativen Automatisierungsfunktion.',
  },
  GOOGLE_CONTACTS: {
    requiredLicence: 'INTERACTIVE',
    title: 'Automatisch Kontakte ins Smartphone eintragen lassen',
    description:
      'Du kennst es - du möchtest mit deinem Kunden per WhatsApp schreiben, aber musst zunächst den Kontakt abspeichern. bookitup trägt automatisch deine Kundenkontakte ein und du kannst z.B. sofort nachdem Du eine Anfrage erhalten hast anrufen oder mit deinem Kunden schreiben. Zudem siehst du so natürlich auch welcher Kunde dich gerade anruft und kannst ihn direkt mit seinem Namen grüßen.',
  },
  GOOGLE_TASKS: {
    requiredLicence: 'INTERACTIVE',
    title: 'Automatisch Aufgaben synchronisieren',
    description:
      'bookitup trägt automatisch deine bookitup Aufgaben in Google in die Liste "bookitup" ein. Somit siehst du z.B. auch in deinem Google-Kalender immer, welche Aufgaben heute (neben den Terminen) anstehen. ',
  },
  DJ_CONTRACT_TEMPLATE: {
    requiredLicence: 'PRO',
    title: 'Professionellen Muster-Vertrag für DJs',
    description:
      'Wir haben zusammen mit einem Anwalt einen Mustervertrag für mobile DJs ausgearbeitet, welchen du hier mit einem klick erstellen kannst.',
    trialForbidden: true,
  },
  RESOURCE_PLANNING: {
    requiredLicence: 'PRO',
    title: 'Zeitleiste über alle Events und Ressourcen Planungen',
    description:
      'Mit dem "Resource Planning" Addon kannst Du Vermietartikel verwalten, in Deinen Events einplanen und in einer Zeitleiste Überschneidungen, sowie Überbuchungen sofort erkennen. Du siehst schon bei der Anfrage, welche Ressourcen noch verfügbar sind und kannst zudem nichts mehr vergessen.',
    addons: ['Resource Planning'],
  },
  PRODUCT_RESOURCE: {
    requiredLicence: 'INTERACTIVE',
    title: 'Automatisch Ressourcen einplanen',
    description:
      'Wenn du eine Ressource mit einem Produkt verknüpfst, wird die Ressource automatisch dem Event hinzugefügt und auch bestätigt, sobald das Angebot oder der Vertrag bestätigt wurde. Somit kannst du keine Ressourcen mehr vergessen einzuplanen.',
    addons: ['Resource Planning'],
  },
  STATISTICS: {
    requiredLicence: 'INTERACTIVE',
    title: 'Statistiken',
    description:
      'Wie hoch sind meine Einnahmen in den nächsten Monaten? Woher kamen meine Anfragen, auf welchen Events war ich unterwegs und wie war die Buchungsquote pro Auftragsart oder Marketing-Kanal? Mit unseren Statistiken hast Du Deine Zahlen im Blick, kannst sie im Jahresvergleich analysieren und somit die Entscheidungen treffen, um Dein Business auf das nächste Level zu bringen.',
  },
  SCHEDULE_MAIL: {
    requiredLicence: 'FLOW',
    title: 'E-Mails planen',
    description:
      'Schreibe Deine E-Mails Stunden, Tage oder sogar Jahre vorher, um Deinem Kunden z.B. einen weiteren Fragebogen zukommen zu lassen oder zum Hochzeitstag zu gratulieren und Dich so noch einmal in Erinnerung zu rufen.',
  },

  CUSTOM_EVENT_STATE: {
    requiredLicence: 'INTERACTIVE',
    title: 'Eigene Event-Status',
    description:
      'Lege Dir eigene Event-Status an wie "Warte auf Vertrag", "in Vorbereitung" oder "Abgeschlossen". Dabei kannst du Emojis verwenden, die in der Eventliste bei jedem Event angezeigt werden. Damit hast du einen noch besseren Überblick, was bei welchem Event aktuell ansteht.',
  },
};

const DefaultViolation: LicenceRestriction = {
  requiredLicence: 'BASIC',
  title: 'TBD',
  description: 'TBD',
};

const getRestriction = (name: string) => {
  const violation = Restrictions[name];
  if (violation) {
    return violation;
  }
  // eslint-disable-next-line no-console
  console.error(`Unable to find restriction details per name: ${name}`);
  return DefaultViolation;
};

const hasSuitableLicence = (licence: Licence, userLicence: Licence) => {
  if (licence === 'PRO') {
    if (!userLicence.includes('PRO') && !userLicence.includes('FLOW') && !userLicence.includes('INTERACTIVE')) {
      return false;
    }
  }
  if (licence === 'FLOW') {
    if (!userLicence.includes('FLOW') && !userLicence.includes('INTERACTIVE')) {
      return false;
    }
  }
  if (licence === 'INTERACTIVE') {
    if (!userLicence.includes('INTERACTIVE')) {
      return false;
    }
  }
  return true;
};

const inTrialPeriod = (licence: Licence | undefined) => licence && licence.includes('TRIAL');

/**
 * Determinate whenever the user can redeem trial licence for free.
 *
 * 1) Current licence is BASIC
 * 2) Last trial period expired at least 3 months ago
 * 3) Have overall less then 5 websessions
 *
 * @param jwtClaims of the customer
 * @returns true if redeem of trial licence is possible, otherwise false
 */

const trialRedeemable = (jwtClaims: JwtPayloadInterface) => {
  const { licence, trialEndsAt, webSessions } = jwtClaims;
  const trialEndsDate = new Date(trialEndsAt);
  const test = addMonths(trialEndsDate, 3).getTime() <= new Date().getTime();
  return licence === 'BASIC' && webSessions && webSessions < 5 && test;
};

const getRemainingTrialDays = (trialEndsAt: Date) => {
  const dateOneUTC = Date.UTC(trialEndsAt.getFullYear(), trialEndsAt.getMonth(), trialEndsAt.getDate());
  const today = new Date();
  const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
  const difference = dateOneUTC - todayUTC;
  return difference / (1000 * 60 * 60 * 24);
};

const getUserDays = (registeredOn: Date) => {
  const dateOneUTC = Date.UTC(registeredOn.getFullYear(), registeredOn.getMonth(), registeredOn.getDate());
  const today = new Date();
  const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
  const difference = todayUTC - dateOneUTC;
  return difference / (1000 * 60 * 60 * 24);
};

const hasAccessToResourceModule = (profileId: number) => true;

// Welcome deal offer
const welcomeDealRedeemeble = (jwtClaims: JwtPayloadInterface) => {
  const { licence, registeredOn } = jwtClaims;
  return licence === 'INTERACTIVE_TRIAL_30DAYS' && (registeredOn ? getUserDays(new Date(registeredOn)) <= 14 : false); // registeredOn was just added to decodedJwt
};

// Seasonal discounts offers
const isDiscountRedeemeble = (licence: Licence, userLicence: Licence) => {
  if (inTrialPeriod(userLicence) || userLicence === 'BASIC') {
    return true;
  }
  if (userLicence === 'INTERACTIVE') {
    return false;
  }
  return userLicence === 'PRO' && licence === 'INTERACTIVE';
};

export const LicenceS = {
  Restrictions,
  getRestriction,
  hasSuitableLicence,
  inTrialPeriod,
  trialRedeemable,
  getRemainingTrialDays,
  hasAccessToResourceModule,
  isDiscountRedeemeble,
  welcomeDealRedeemeble,
  getUserDays,
};
