// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Flex, Skeleton, Text } from '@mantine/core';
import { FC, useContext, useEffect, useState } from 'react';
import LocationDistance from '../LocationDistance';
import { openEditContactModal } from '../../../Organismns/Contact/EditContactModal';
import { Contact, ContactS } from '../../../Service/ContactS';
import { RecommendationDto } from '../../../Types/RecommendationT';
import RecommendationActions from './RecommendationActions';
import RecommendationAnswerStatus from './RecommendationAnswerStatus';
import RecommendationStateStatus from './RecommendationState';
import ExportEventInfoStatus from './ExportEventInfoStatus';
import { EventContext } from '../../../Service/Context/EventContext';
import { EventDateDTO } from '../../../Types/MessageT';
import { EventS } from '../../../Service/EventS';
import { StringUtils } from '../../../Utils/utils';
import { IconCalendarEvent } from '@tabler/icons';

const RecommendationRequest: FC<{ recommendation: RecommendationDto }> = ({ recommendation }) => {
  const [contact, setContact] = useState<Contact>();
  const [loadingContact, setLoadingContact] = useState(false);
  const { event } = useContext<any>(EventContext);
  const { dates } = event;
  const { recommendedContactId, answer, eventDatesIds } = recommendation;

  const selectedDates = dates
    .filter((d: EventDateDTO) => eventDatesIds.includes(d.id))
    .map((d: EventDateDTO) => EventS.formatEventDate(new Date(d.date)));

  useEffect(() => {
    setLoadingContact(true);
    ContactS.fetchById(recommendedContactId)
      .then((_contact) => _contact && setContact(_contact))
      .finally(() => setLoadingContact(false));
  }, [recommendedContactId]);

  if (loadingContact) {
    return <Skeleton width="100%" height={100} />;
  }
  if (!contact) {
    return null;
  }

  const { personDescription, email } = contact;
  const showLocationInfo = answer === 'UNKNOWN' || answer === 'AVAILABLE';
  const showExportInfo = email && answer === 'AVAILABLE';

  return (
    <Box sx={{ borderRadius: 10, backgroundColor: '#F1F1F1' }}>
      <Box p="sm">
        <Flex mb="xs">
          <Text
            weight="bolder"
            size="md"
            onClick={() => openEditContactModal(contact, undefined, setContact)}
            sx={{ cursor: 'pointer', flexGrow: 1 }}
            className="overflow-ellipsis"
          >
            {personDescription ?? email}
          </Text>
          <RecommendationActions recommendation={recommendation} contactEmail={email} />
        </Flex>
        {showLocationInfo && <LocationDistance contactId={recommendedContactId} />}

        <RecommendationAnswerStatus recommendation={recommendation} />
        {showExportInfo && (
          <>
            <Box ml={18} sx={{ width: 5, height: 5, backgroundColor: 'white' }} />
            <ExportEventInfoStatus recommendation={recommendation} />
          </>
        )}
        <Box ml={18} sx={{ width: 5, height: 5, backgroundColor: 'white' }} />
        <RecommendationStateStatus recommendation={recommendation} contactEmail={email} />
      </Box>
      {selectedDates.length > 0 && (
        <Text
          p={3}
          size="xs"
          sx={{
            backgroundColor: '#1D5191',
            color: 'white',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            width: '100%',
          }}
          className="overflow-ellipsis"
        >
          <IconCalendarEvent size={16} style={{ marginRight: 5, marginLeft: 5, marginTop: -4 }} />{' '}
          {StringUtils.concatStrings(selectedDates)}
        </Text>
      )}
    </Box>
  );
};

export default RecommendationRequest;
