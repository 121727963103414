// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Center, Group, Space, Stack, Text } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { useJwtClaims } from '../../../Service/Context/AuthContext';
import { LicenceS } from '../../../Service/helper/LicenceService';
import { useUpdateBillingPlans } from '../../../Service/hooks/useUpdateBillingPlans';
import { RoutingS } from '../../../Service/RoutingS';
import { BillingInterval } from '../../../Types/Billing';
import BookitupSegmentedControl from '../../../Atoms/BookitupSegmentedControl';
import { DataS } from '../../../Service/DataS';
import { WindowContext } from '../../../Service/Context/WindowContext';
import { BookitupSubscriptionContext } from '../../../Service/Context/BookitupSubscriptionContext';
import ThankYou from './ThankYou';
import AvailableAddons from './AvailableAddons';
import AvailablePlans from './AvailablePlans';
import SubscriptionPane from './SubscriptionPane';
import TrialRedeemAlert from './TrialRedeemAlert';

const PackageSection = () => {
  const jwtClaims = useJwtClaims();
  const { subscription } = useContext(BookitupSubscriptionContext);
  const [billingInterval, setBillingInterval] = useState<BillingInterval>('Yearly');
  const { sm } = useContext(WindowContext);
  const { licence } = jwtClaims;
  const freeLicence = LicenceS.isFreeLicence(licence);

  useUpdateBillingPlans(freeLicence);

  useEffect(() => {
    RoutingS.changeTitle('Paket');
  }, []);

  useEffect(() => {
    if (subscription) {
      if (subscription.interval !== billingInterval) {
        setBillingInterval(subscription.interval);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  return (
    <>
      <Box mt="md" sx={{ textAlign: 'center' }}>
        <Text size={sm ? 22 : 28} weight="bold">
          360° Eventverwaltung mit bookitup
        </Text>
        <Space h="sm" />
        <Text color="dimmed">Du bezahlst nur, was Du brauchst (und wenn du es brauchst)</Text>
      </Box>
      <Space h="sm" />
      <Center>
        <BookitupSegmentedControl
          color="blue"
          value={billingInterval}
          data={DataS.billingIntervals}
          onChange={(val: BillingInterval) => val && setBillingInterval(val)}
        />
      </Center>
      <Group mt="md" sx={{ alignItems: 'flex-start' }}>
        <AvailablePlans billingInterval={billingInterval} />
        <Stack sx={{ maxWidth: 450 }}>
          {LicenceS.trialRedeemable(jwtClaims) && <TrialRedeemAlert />}
          <SubscriptionPane />
          <AvailableAddons billingInterval={billingInterval} />
          {!freeLicence && <ThankYou />}
        </Stack>
      </Group>
    </>
  );
};

export default PackageSection;
