// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Badge, Button, Center, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { FC, useContext } from 'react';
import { IconCheck } from '@tabler/icons';
import PanePaper from '../../../Molecules/PanePaper';
import { DataS } from '../../../Service/DataS';
import { BillingInterval } from '../../../Types/Billing';
import { ResourceAddonContext } from '../../../Service/Context/ResourceAddonsContext';
import { BookitupSubscriptionContext } from '../../../Service/Context/BookitupSubscriptionContext';
import AddonManagementPane from './AddonManagementPane';

const AvailableAddons: FC<{ billingInterval: BillingInterval }> = ({ billingInterval }) => {
  const { trialAvailable, openTrialModal } = useContext(ResourceAddonContext);
  const { resourceAddon } = useContext(BookitupSubscriptionContext);
  const { interval: addonInterval, price: addonPrice } = resourceAddon ?? {};

  return (
    <>
      {DataS.billingAddons.map((addon) => {
        const { name, description, price } = addon;
        return (
          <PanePaper
            key={addon.name}
            withShadow
            withoutBorder
            rightSection={
              <Badge mb="md" radius="sm" sx={{ color: 'white' }} variant="gradient">
                Addon
              </Badge>
            }
          >
            <Text size="xl" weight="bolder">
              {name}
            </Text>
            {!addonInterval && <AddonManagementPane />}
            <Text mt="md" mb="sm">
              {description}
            </Text>
            <Text weight="bolder" mt="md" mb={5}>
              Anzahl der Ressourcen
            </Text>
            <Stack spacing={5} sx={{ borderTop: `2px solid #e2e2e2` }} pt={5}>
              {price[billingInterval].map((tier) => {
                const { from, to, price: amount } = tier;
                const activeTier = addonPrice === amount && addonInterval === billingInterval;
                return (
                  <Group key={amount} position="apart" pl={5} pr={5}>
                    {activeTier ? (
                      <Group spacing={5}>
                        <ThemeIcon radius="xl" size="xs">
                          <IconCheck />
                        </ThemeIcon>
                        <Text italic weight="bolder">{`${from} - ${to}`}</Text>
                      </Group>
                    ) : (
                      <Text italic>{`${from} - ${to}`}</Text>
                    )}
                    <Text weight="bolder">{amount} € / Monat</Text>
                  </Group>
                );
              })}
            </Stack>
            {trialAvailable && !addonInterval && (
              <Center mt="md">
                <Button onClick={openTrialModal}>Jetzt kostenlos testen</Button>
              </Center>
            )}
            <Text italic mt="md" size="xs" color="dimmed">
              Bei einer größeren Anzahl an Ressourcen wende Dich bitte an unseren Support unter{' '}
              <a href="mailto:support@bookitup.de?subject=Angebot für mehr Ressourcen">support@bookitup.de</a>. Preise
              zzgl. gesetzl. MwSt.
            </Text>
          </PanePaper>
        );
      })}
    </>
  );
};

export default AvailableAddons;
