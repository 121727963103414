// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Flex, Space } from '@mantine/core';
import { FC } from 'react';
import { DataS } from '../../../Service/DataS';
import { BillingInterval } from '../../../Types/Billing';
import Plan from './Plan';

const AvailablePlans: FC<{ billingInterval: BillingInterval }> = ({ billingInterval }) => (
  <div>
    <Flex gap="md" wrap="wrap" justify="center">
      {DataS.billingPlans.map((plan) => (
        <Plan key={plan.name} billingPlan={plan} billingInterval={billingInterval} />
      ))}
    </Flex>
    <Space h="md" />
  </div>
);

export default AvailablePlans;
