// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Flex, Group, Space, Stack, Text, Tooltip } from '@mantine/core';
import { FC, useContext } from 'react';
import Moment from 'react-moment';
import { IconSettingsAutomation } from '@tabler/icons';
import { BookitupNotification } from '../../Types/LogT';
import { NotificationsContext } from '../../Service/Context/NotificationsContext';
import BookitupNotificationIcon from '../../Atoms/BookitupNotificationIcon';
import ContractSigned from './Type/General/ContractSigned';
import InvoiceNotPaid from './Type/General/InvoiceNotPaid';
import OfferConfirmed from './Type/General/OfferConfirmed';
import OfferOverdue from './Type/General/OfferOverdue';
import QuestionnaireFilled from './Type/General/QuestionnaireFilled';
import RecommendationAvailabilityChanged from './Type/General/RecommendationAvailabilityChanged';
import RecommendationStateChanged from './Type/General/RecommendationStateChanged';
import RequestReceived from './Type/General/RequestReceived';
import ScheduledMail from './Type/General/ScheduledMail';
import TaskOverdue from './Type/General/TaskOverdue';
import MailOpened from './Type/General/MailOpened';
import MailReceived from './Type/General/MailReceived';
import NotificationOptions from './NotificationOptions';
import WorkflowCompleted from './Type/General/WorkflowCompleted';
import ConditionNotFulfilled from './Type/General/ConditionNotFulfilled';
import ProcedureFailed from './Type/General/ProcedureFailed';
import ApprovalRequired from './Type/General/ApprovalRequired';
import CalendarUnreachable from './Type/General/CalendarUnreachable';
import ExternalEmailConnected from './Type/General/ExternalEmailConnected';

const GeneralNotification: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { updateFilter } = useContext(NotificationsContext);
  const { occurrenceType, createdOn, seen, timeLabel } = notification;

  return (
    <>
      {timeLabel && (
        <Text p="xs" weight="bolder" size="lg">
          {timeLabel}
        </Text>
      )}
      <Flex p="xs" sx={{ backgroundColor: seen ? 'inherit' : '#E8EEF4', borderRadius: 10 }}>
        <Stack>
          <BookitupNotificationIcon notification={notification} />
        </Stack>
        <Space w="md" />
        <Stack spacing={0} sx={{ flexGrow: 1 }}>
          {occurrenceType === 'EXTERNAL_EMAIL_CONNECTED' && <ExternalEmailConnected notification={notification} />}
          {occurrenceType === 'APPROVAL_REQUIRED' && <ApprovalRequired notification={notification} />}
          {occurrenceType === 'PROCEDURE_FAILED' && <ProcedureFailed notification={notification} />}
          {occurrenceType === 'CONDITION_NOT_FULFILLED' && <ConditionNotFulfilled notification={notification} />}
          {occurrenceType === 'WORKFLOW_COMPLETED' && <WorkflowCompleted notification={notification} />}
          {occurrenceType === 'MAIL_OPENED' && <MailOpened notification={notification} />}
          {occurrenceType === 'MAIL_RECEIVED' && <MailReceived notification={notification} />}
          {occurrenceType === 'REQUEST_RECEIVED' && <RequestReceived notification={notification} />}
          {occurrenceType === 'OFFER_CONFIRMED' && <OfferConfirmed notification={notification} />}
          {occurrenceType === 'CONTRACT_SIGNED' && <ContractSigned notification={notification} />}
          {occurrenceType === 'QUESTIONNAIRE_FILLED' && <QuestionnaireFilled notification={notification} />}
          {occurrenceType === 'TASK_OVERDUE' && <TaskOverdue notification={notification} />}
          {(occurrenceType === 'SCHEDULED_MAIL_FAILED' || occurrenceType === 'SCHEDULED_MAIL_SUCCESSFUL') && (
            <ScheduledMail notification={notification} />
          )}
          {occurrenceType === 'OFFER_OVERDUE' && <OfferOverdue notification={notification} />}
          {occurrenceType === 'INVOICE_NOT_PAID' && <InvoiceNotPaid notification={notification} />}
          {occurrenceType === 'RECOMMENDATION_AVAILABILITY_CHANGED' && (
            <RecommendationAvailabilityChanged notification={notification} />
          )}
          {occurrenceType === 'RECOMMENDATION_STATE_CHANGED' && (
            <RecommendationStateChanged notification={notification} />
          )}
          {occurrenceType === 'EXTERNAL_CALENDAR_UNREACHABLE' && <CalendarUnreachable notification={notification} />}
          <Group spacing={5}>
            <Text color="blue" size="xs" mt={3}>
              <Moment fromNow>{createdOn}</Moment>
            </Text>
            {notification.automated && (
              <Tooltip withArrow position="right" label="Erstellt durch Automation." transition="slide-right">
                <div>
                  <IconSettingsAutomation size={20} />
                </div>
              </Tooltip>
            )}
          </Group>
        </Stack>
        <NotificationOptions onDelete={() => updateFilter({ pageNumber: 0 })} notification={notification} />
      </Flex>
    </>
  );
};

export default GeneralNotification;
