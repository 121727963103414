// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { TextInput, Switch, Text, Stack, Select, MultiSelect, Group, Box } from '@mantine/core';
import { FC, useContext, useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import ConfigOption from '../../../Atoms/ConfigOption';
import { OccurrenceType, WorkflowTemplateDTO } from '../../../Types/LogT';
import { EventKind } from '../../../Types/EventT';
import { EventS } from '../../../Service/EventS';
import { DataS } from '../../../Service/DataS';
import { WorkflowTemplateContext } from './WorkflowTemplate';

const WorkflowTemplateSettings: FC<{ template: WorkflowTemplateDTO }> = ({ template }) => {
  const { enabled, name, generalWorkflow, runAutomatically, kindsOfInterest, typeOfInterest, uniquePerEvent, steps } =
    template;
  const { patchTemplate } = useContext(WorkflowTemplateContext);
  const [loading, setLoading] = useState(true);
  const [kinds, setKinds] = useState<EventKind[]>([]);
  const kindNames = kinds.map((_kind) => _kind.name);

  useEffect(() => {
    EventS.fetchEventKinds()
      .then(setKinds)
      .finally(() => setLoading(false));
  }, []);

  return (
    <Stack mb="md">
      <Text size="md">Allgemein</Text>
      <TextInput
        sx={{ maxWidth: 250 }}
        label="Name"
        pl="md"
        defaultValue={name}
        onBlur={(e) => patchTemplate({ name: e.currentTarget.value })}
      />
      <ConfigOption
        pl="md"
        title="Aktiv"
        description={i18n._('workflow.option.enabled.description')}
        rightSection={
          <Switch
            disabled={steps.length === 0}
            checked={enabled}
            onChange={() => patchTemplate({ enabled: !enabled })}
          />
        }
      />
      <Text size="md">Erstellung</Text>
      <ConfigOption
        pl="md"
        title={i18n._('workflow.option.general')}
        description={i18n._('workflow.option.general.description')}
        rightSection={
          <Switch checked={generalWorkflow} onChange={() => patchTemplate({ generalWorkflow: !generalWorkflow })} />
        }
      />
      {!generalWorkflow && (
        <Box pl="md">
          <Group>
            <MultiSelect
              label="Eventart"
              value={kindsOfInterest}
              onChange={(val) => patchTemplate({ kindsOfInterest: val })}
              data={kindNames}
            />
            <Select
              label="Ereignis"
              clearable
              defaultValue={typeOfInterest}
              onChange={(val: OccurrenceType) => patchTemplate({ typeOfInterest: val })}
              data={[
                ...DataS.getObservableEventOptions(i18n),
                { value: 'REQUEST_RECEIVED', label: i18n._('events.REQUEST_RECEIVED') },
              ]}
            />
          </Group>
          <Text mt="sm" size="xs" color="dimmed">
            Der Workflow wird erstellt, wenn die angegebene Bedingung erfüllt ist.
          </Text>
        </Box>
      )}
      {typeOfInterest && (
        <ConfigOption
          pl="md"
          title="Mehrfacherstellung"
          description="Gib an, ob der Workflow mehrfach pro Event erstellt werden soll (also jedesmal wenn das oben ausgewählte Ereignis auftritt)."
          rightSection={
            <Switch checked={!uniquePerEvent} onChange={() => patchTemplate({ uniquePerEvent: !uniquePerEvent })} />
          }
        />
      )}
      <Text size="md">Ausführung</Text>
      <ConfigOption
        pl="md"
        title={i18n._('workflow.option.run-auto')}
        description={i18n._('workflow.option.run-auto.description')}
        rightSection={
          <Switch checked={runAutomatically} onChange={() => patchTemplate({ runAutomatically: !runAutomatically })} />
        }
      />
    </Stack>
  );
};

export default WorkflowTemplateSettings;
