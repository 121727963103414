import { useContext } from 'react';
import { EventsContext } from '../../Service/Context/EventsContext';
import EventsListView from '../Event/EventListView';
import EventsTimelineView from './EventsTimelineView';
import EventsCalendarView from './EventsCalendarView';
import DatelessEventsList from './DatelessEventsList';

const EventsPageContent = () => {
  const { view, filter } = useContext(EventsContext);
  const { sorting } = filter;

  if (view === 'calendar') {
    return <EventsCalendarView />;
  }
  if (sorting === 'dateless') {
    return <DatelessEventsList />;
  }
  if (view === 'list') {
    return <EventsListView />;
  }
  return <EventsTimelineView />;
};

export default EventsPageContent;
