import { Anchor, Box, Breadcrumbs, Divider, Group, Header } from '@mantine/core';
import { IconLayoutSidebarLeftCollapse, IconLayoutSidebarRightCollapse } from '@tabler/icons';
import { FC, ReactNode, useContext } from 'react';
import LicenceHeader from '../../Atoms/LicenceHeader';
import { AuthContext } from '../../Service/Context/AuthContext';
import { WindowContext } from '../../Service/Context/WindowContext';
import PrimaryHeader from './PrimaryHeader';

const PageHeader: FC<{
  title: ReactNode;
  navBarOpen: boolean;
  setNavBarOpen: (val: boolean) => void;
  rightSection?: ReactNode;
  height?: number;
}> = ({ title, navBarOpen, setNavBarOpen, rightSection, height }) => {
  const { sm } = useContext(WindowContext);
  const { jwtClaims } = useContext(AuthContext);
  const { licence } = jwtClaims;
  const showLicenceHeader = licence && (licence === 'BASIC' || licence === 'INTERACTIVE_TRIAL_30DAYS');
  const finalHeight = (height || 105) + (showLicenceHeader ? 40 : 0);

  return (
    <div>
      {sm ? (
        <Header height={finalHeight}>
          {showLicenceHeader && <LicenceHeader />}
          <PrimaryHeader />
          <Group p="md" sx={{ position: 'relative' }}>
            {navBarOpen ? (
              <IconLayoutSidebarLeftCollapse onClick={() => setNavBarOpen(false)} />
            ) : (
              <IconLayoutSidebarRightCollapse onClick={() => setNavBarOpen(true)} />
            )}
            <Divider orientation="vertical" />
            <Breadcrumbs>
              <Anchor size="xl" variant="text">
                {title}
              </Anchor>
            </Breadcrumbs>
            {rightSection && <Box sx={{ position: 'absolute', right: 15, top: 18 }}>{rightSection}</Box>}
          </Group>
        </Header>
      ) : (
        <div>
          {showLicenceHeader && (
            <Header height={40}>
              <LicenceHeader />
            </Header>
          )}
        </div>
      )}
    </div>
  );
};

export default PageHeader;
