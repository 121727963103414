import { useContext, useEffect, useState } from 'react';
import { Trans, useLingui } from '@lingui/react';
import { Alert } from 'reactstrap';
import { Grid, Stack } from '@mantine/core';
import { Icon } from '../../Atoms/Icons';
import { EventContext } from '../../Service/Context/EventContext';
import { useQuery } from '../../Helpers/UrlQuery';
import { getTemplateUnresolvedPlaceholders, getUnresolvablePlaceholders } from '../../Service/restapi/documentService';
import EventLogbook from '../Log/EventLogbook';
import EventCustomFieldsUpgrader from '../../Molecules/Event/CustomAttributes/EventCustomFieldsUpgrader';
import NotesList from '../../Molecules/Logs/NotesList';
import WorkflowsPane from '../../Molecules/Event/Workflows/WorkflowsPane';
import { RoutingS } from '../../Service/RoutingS';
import AvailabilityPane from './AvailabilityPane';
import EventTimeline from './EventTimeline';

const EventDetails = () => {
  const { i18n } = useLingui();
  const { event } = useContext(EventContext);
  const { id: eventId } = event;
  const [unresolvablePlaceholder, setUnresolvablePlaceholder] = useState(undefined);

  const styles = {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    unresolvedPlaceholder: {},
  };

  const query = useQuery();

  useEffect(() => {
    RoutingS.changeTitle('Event erstellen');
    if (query.get('template_name')) {
      const documentTemplateDTO = {
        documentType: query.get('document_type').toUpperCase(),
        templateName: query.get('template_name'),
      };
      if (documentTemplateDTO.documentType && documentTemplateDTO.templateName) {
        getTemplateUnresolvedPlaceholders(
          documentTemplateDTO.documentType,
          documentTemplateDTO.templateName,
          eventId,
        ).then((unresolvedPlaceholders) => {
          if (unresolvedPlaceholders.length > 0) {
            setUnresolvablePlaceholder(unresolvedPlaceholders);
          }
        });
      }
    } else if (query.get('entityType') && query.get('entityId')) {
      const entityType = query.get('entityType');
      if (entityType === 'documents' || entityType === 'contracts') {
        getUnresolvablePlaceholders(entityType, query.get('entityId')).then((unresolvedPlaceholders) => {
          if (unresolvedPlaceholders.length > 0) {
            setUnresolvablePlaceholder(unresolvedPlaceholders);
          }
        });
      }
    } else if (query.get('message_template')) {
      getUnresolvablePlaceholders('messageTemplates', query.get('message_template')).then((unresolvedPlaceholders) => {
        if (unresolvedPlaceholders.length > 0) {
          setUnresolvablePlaceholder(unresolvedPlaceholders);
        }
      });
    }
  }, []);

  return (
    <div>
      {unresolvablePlaceholder && (
        <Alert color="info">
          <Icon value="CAUTION" />
          &nbsp;
          {query.get('template_name') && (
            <Trans
              id={`Für die ${i18n._(`template.${query.get('document_type')}`)} '${query.get(
                'template_name',
              )}' werden folgende Platzhalter benötigt:`}
            />
          )}
          {query.get('entityId') && query.get('entityType') === 'documents' && (
            <Trans id="Für das Dokument werden folgende Platzhalter benötigt:" />
          )}
          {query.get('entityId') && query.get('entityType') === 'contracts' && (
            <Trans id="Für den Vertrag werden folgende Platzhalter benötigt:" />
          )}
          {query.get('message_template') && (
            <Trans
              id={`Für die E-Mail-Vorlage '${i18n._(
                `message.type.${query.get('message_template').toLowerCase()}`,
              )}' werden folgende Platzhalter benötigt:`}
            />
          )}
          {unresolvablePlaceholder.map((placeholder, index) => (
            <div key={index} style={styles.unresolvedPlaceholder}>
              {i18n._(`${placeholder}.description`)}
              <br />
            </div>
          ))}
        </Alert>
      )}

      <Grid>
        <Grid.Col md={12} xl={4}>
          <Stack>
            <AvailabilityPane />
            <EventTimeline />
          </Stack>
        </Grid.Col>
        <Grid.Col md={12} xl={8}>
          <Stack mb={70}>
            <EventCustomFieldsUpgrader />
            <NotesList />
            <WorkflowsPane />
            <EventLogbook />
          </Stack>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default EventDetails;
