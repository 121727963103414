import React, { useContext } from 'react';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { CustomAttributesContext } from '../../../Service/Context/CustomAttributeContext';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import { useModalState } from '../../Dialogs';
import PanePaper from '../../PanePaper';
import { CustomAttributeCreateModal } from './CustomAttributeCreateModal';
import CustomAttributeField from './CustomAttributeField';
import EventCustomAttributeList from './EventCustomAttributeList';
import BookitupTutorialIcon from "../../../Atoms/BookitupTutorialIcon";

const EventCustomFieldsUpgrader = ({ asideBar }) => {
  const { refreshValue, refreshGroups } = useContext(CustomAttributesContext);
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const { show, toggle } = useModalState();

  const refreshAll = () => {
    refreshValue();
    refreshGroups();
  };

  return (
    <PanePaper
      withShadow={asideBar}
      withoutBorder={asideBar}
      title="Eigene Felder"
      tooltip="help.event.customFields.pane"
      rightSection={
        <>
          <BookitupTutorialIcon videoUrl={"https://app.bookitup.de/academy/channels/perfect-overview?episodeId=77tp5g4owb"} name={"CreateCustomAttribute"} />
          <BookitupAddIcon onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.CUSTOM_ATTRIBUTE, toggle)} />
        </>
      }
    >
      <div>
        <EventCustomAttributeList customAttributeFormatter={CustomAttributeField} />
      </div>
      <CustomAttributeCreateModal entityType="event" toggle={toggle} show={show} finish={refreshAll} />
    </PanePaper>
  );
};

export default EventCustomFieldsUpgrader;
