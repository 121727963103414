// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Button, Center, List, Space, Text, ThemeIcon } from '@mantine/core';
import { IconCheck, IconCircleCheck, IconFlame } from '@tabler/icons';
import { FC, useContext, useMemo } from 'react';
import PanePaper from '../../../Molecules/PanePaper';
import { ChargebeeS } from '../../../Service/ChargebeeS';
import { AuthContext, useJwtClaims } from '../../../Service/Context/AuthContext';
import { BillingInterval, BillingPlan } from '../../../Types/Billing';
import { ResourceAddonContext } from '../../../Service/Context/ResourceAddonsContext';
import { LicenceS } from '../../../Service/LicenceS';
import { BookitupSubscriptionContext } from '../../../Service/Context/BookitupSubscriptionContext';

interface Props {
  billingPlan: BillingPlan;
  billingInterval: BillingInterval;
}

const Plan: FC<Props> = ({ billingPlan, billingInterval }) => {
  const { refreshToken } = useContext(AuthContext);
  const jwtClaims = useJwtClaims();
  const { subscription, refresh: refreshSubscription, resourceAddon } = useContext(BookitupSubscriptionContext);
  const { refresh: refreshAddon } = useContext(ResourceAddonContext);
  const { name, internalName, free, price, topFeaturesText, features, licence, popular, topFeaturesComponent } =
    billingPlan;

  const { licence: subscriptionLicence, interval: subscriptionInterval } = subscription ?? {};
  const planIncompatible = resourceAddon && resourceAddon.interval !== billingInterval;
  const subscriptionExists = subscriptionLicence !== undefined;
  const isPlanActive = licence === subscriptionLicence && billingInterval === subscriptionInterval;

  const discountAvailable = useMemo(
    () => billingInterval === 'Yearly' && LicenceS.welcomeDealRedeemeble(jwtClaims),
    [billingInterval, jwtClaims],
  );

  const createOrUpdateSubscription = (coupon?: string) => {
    const planId = `${internalName}-EUR-${billingInterval}`;
    const onSuccess = () => {
      refreshToken();
      refreshAddon();
      refreshSubscription();
    };
    ChargebeeS.openHostedCheckout(planId, onSuccess, billingPlan, coupon);
  };

  return (
    <PanePaper
      sx={{
        maxWidth: 250,
        border: isPlanActive ? '3px solid #1D5191' : '1px solid #E2E2E2',
      }}
    >
      <Space h="md" />
      <Text align="center" weight="bold" size={28}>
        {popular && (
          <ThemeIcon variant="gradient">
            <IconFlame />
          </ThemeIcon>
        )}
        <Text span ml={popular ? 'sm' : 0}>
          {name}
        </Text>
      </Text>
      <Space h="xl" />
      {free ? (
        <Text align="center" size="lg" mb="xl">
          Kostenlos
        </Text>
      ) : (
        <>
          {!discountAvailable && <Text align="center" size="xl">{`${price[billingInterval]} € / Monat`}</Text>}
          {discountAvailable && <DiscountPrice price={price[billingInterval]} />}
          <Text align="center" size="xs" color="dimmed">
            zzgl. gesetzl. MwSt.
          </Text>
        </>
      )}
      {!planIncompatible && (
        <Center mt="xl" mb="xl">
          {isPlanActive && (
            <Button
              sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0], pointerEvents: 'none' })}
              variant="light"
              rightIcon={<IconCircleCheck size={20} />}
            >
              Plan aktiv
            </Button>
          )}
          {!isPlanActive && licence !== 'BASIC' && (
            <Button onClick={() => createOrUpdateSubscription(discountAvailable ? 'WELCOME20' : undefined)}>
              {subscriptionExists ? 'Plan ändern' : 'Plan Buchen'}
            </Button>
          )}
        </Center>
      )}
      <Text size="sm" weight="bolder" mt={planIncompatible ? 'xl' : 0}>
        {topFeaturesText}
      </Text>
      <Space h="sm" />
      <List
        spacing={5}
        size="xs"
        icon={
          <ThemeIcon radius="xl" size="xs">
            <IconCheck size={10} />
          </ThemeIcon>
        }
      >
        {topFeaturesComponent && topFeaturesComponent}
        {features.map((feature) => (
          <List.Item key={feature}>{feature}</List.Item>
        ))}
      </List>
    </PanePaper>
  );
};

const DiscountPrice: FC<{ price: number }> = ({ price }) => (
  <Center sx={{ borderRadius: 10 }}>
    <Box sx={{ width: 175 }}>
      <Text
        py={2}
        align="center"
        size="xs"
        weight="bolder"
        sx={{ backgroundColor: '#FEE956', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
      >
        WELCOME DEAL - 20 %
      </Text>
      <Box py={5} sx={{ backgroundColor: 'black', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
        <Text my={5} color="white" align="center" size="lg">
          {`${price * 0.8} € / Monat`}
        </Text>
        <Text align="center" size="sm" color="white" td="line-through">{`${price} € / Monat`}</Text>
      </Box>
    </Box>
  </Center>
);

export default Plan;
