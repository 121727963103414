import { Menu, Box, ActionIcon } from '@mantine/core';
import { IconBallpen, IconDotsVertical, IconPlus, IconTrash } from '@tabler/icons';
import { FC, useContext } from 'react';
import { i18n } from '@lingui/core';
import { PlanningGroup } from '../../Types/ResourceT';
import { EventResourcePlanningContext } from '../../Service/Context/EventResourcePlanningContext';
import ResourcePlanningS from '../../Service/restapi/resourcePlanningS';
import { openAddResourcePlanningModal } from './AddEventResourcePlanningModal';
import { openDeletePlanningGroupModal, openPlanningGroupModal } from './EventPlanningsModals';

interface Props {
  refresh: () => void;
  group: PlanningGroup;
  closeAccordionItem: () => void;
}

const PlanningGroupActions: FC<Props> = ({ refresh, group, closeAccordionItem }) => {
  const { id: groupId } = group;
  const { editGroup, deleteGroup } = useContext(EventResourcePlanningContext);

  const onDelete = async () => {
    const deleted = await ResourcePlanningS.deletePlanningGroup(groupId);
    if (deleted) {
      deleteGroup(groupId);
    }
  };

  return (
    <ActionIcon mt="sm" size="lg">
      <Menu shadow="md" width={215} position="bottom-end" transition="scale-y">
        <Menu.Target>
          <Box sx={{ cursor: 'pointer' }}>
            <IconDotsVertical color="gray" onClick={closeAccordionItem} />
          </Box>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<IconPlus size={16} />} onClick={() => openAddResourcePlanningModal({ group, refresh })}>
            Ressourcen hinzufügen
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            icon={<IconBallpen size={16} />}
            onClick={() => openPlanningGroupModal({ initial: group, onFinish: editGroup, dates: [] })}
          >
            {i18n._('actions.edit')}
          </Menu.Item>
          <Menu.Item
            color="red"
            icon={<IconTrash size={16} />}
            onClick={() => openDeletePlanningGroupModal({ group, onDelete })}
          >
            Löschen
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </ActionIcon>
  );
};

export default PlanningGroupActions;
