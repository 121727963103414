import { Group, Tooltip } from '@mantine/core';
import { IconDownload, IconX } from '@tabler/icons';
import { useEffect, useState } from 'react';
import PdfPreview from '../../Molecules/Document/PdfPreviewNew';
import { FileS } from '../../Service/FileS';

const EmailFileAttachment = ({ downloadable = false, attachment, eventId, index, deleteAtIndex }) => {
  const [fileExists, setFileExists] = useState(true);
  const { entityId, entityType, fileName } = attachment;

  useEffect(() => {
    if (entityType === 'profiles') {
      FileS.isFileExists(entityType, entityId).then(setFileExists);
    }
  }, [entityType, entityId]);

  return (
    <Tooltip
      withArrow
      color="red"
      disabled={fileExists}
      label="Datei nicht gefunden"
      position="right"
      transition="slide-right"
    >
      <Group
        p="xs"
        sx={(theme) => ({
          borderRadius: theme.radius.sm,
          backgroundColor: fileExists ? theme.colors.gray[0] : '#FFEFEF',
        })}
      >
        <PdfPreview eventId={eventId} file={attachment} fileExists={fileExists} />
        {downloadable && (
          <div>
            <IconDownload
              className="hooverable-icon-blue"
              size={16}
              style={{ cursor: 'pointer' }}
              onClick={() => FileS.downloadFile(entityType, entityId, fileName)}
            />
          </div>
        )}
        {deleteAtIndex && (
          <div>
            <IconX
              size={16}
              onClick={() => deleteAtIndex(index)}
              style={{ cursor: 'pointer' }}
              className="hooverable-icon"
            />
          </div>
        )}
      </Group>
    </Tooltip>
  );
};

export default EmailFileAttachment;
