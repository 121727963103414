/* eslint-disable no-new */
import { useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { BookitupNotification } from '../../Types/LogT';
import { LogS } from '../LogS';
import { BrowserS } from '../BrowserS';

// eslint-disable-next-line import/prefer-default-export
export const useBrowserNotifications = (generalNotifications: BookitupNotification[]) => {
  const { supported, granted } = BrowserS.getNotificationStatus();
  const [reportedNotifications, setReportedNotifications] = useState<string[]>([]);

  const notify = async (notifications: BookitupNotification[]) => {
    notifications.forEach((notification) => {
      const { occurrenceType } = notification;
      const title = i18n._(`events.${occurrenceType}`);
      const body = LogS.getBrowserNotificationMessage(notification);
      BrowserS.showNotification(title, { body, icon: `${process.env.REACT_APP_FRONTEND_URL}/img/favicon-32.png` });
    });
    const ids = notifications.map((notification) => notification.id);
    const success = await LogS.markAsBrowserReported(ids);
    if (success) {
      setReportedNotifications([...reportedNotifications, ...ids]);
    }
  };

  useEffect(() => {
    if (supported && granted) {
      const shouldBePushed = (notification: BookitupNotification) => {
        const { id, seen, publishedInBrowser } = notification;
        return !seen && !publishedInBrowser && !reportedNotifications.includes(id);
      };
      const notifications = generalNotifications.filter(shouldBePushed);
      if (notifications.length > 0) {
        notify(notifications);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalNotifications]);
};
