import React, { useContext, useEffect, useState } from 'react';
import { fetchDocumentById } from '../../Service/restapi/documentService';
import DraftStateChanger from '../../Atoms/documents/draftStateChanger/DraftStateChanger';
import { fetchContractById } from '../../Service/restapi/contractService';
import { fetchQuestionnaireById } from '../../Service/restapi/questionnaireService';

export default function ({ children, entityType, entityId }) {
  const [document, setDocument] = useState(undefined);

  useEffect(() => {
    loadDocument();
  }, [entityType, entityId]);

  function loadDocument() {
    let fctCall = fetchDocumentById;
    if (entityType === 'contracts') {
      fctCall = fetchContractById;
    } else if (entityType === 'questionnaires') {
      fctCall = fetchQuestionnaireById;
    }
    fctCall(entityId).then((resp) => {
      if (resp.ok) {
        resp.json().then(setDocument);
      }
    });
  }


  if (document && document.draft) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 10,
        }}
      >
        <DraftStateChanger
          disabled={false}
          draft={document.draft}
          refreshEvent={() => {}}
          entityType={entityType}
          entityId={entityId}
          handleChange={loadDocument}
          style={{ marginRight: 10, alignSelf: 'center' }}
        />
      </div>
    );
  }

  if (children == null) {
    return null;
  }
  return children;
}
