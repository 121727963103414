/* eslint-disable react/jsx-props-no-spreading */
import { TextInput } from '@mantine/core';
import { usePlacesWidget } from 'react-google-autocomplete';
import { GoogleS } from '../../Service/GoogleS';

// eslint-disable-next-line react/prop-types
const GoogleAutocomplete = ({ label, onPlaceSelected, deps = [], ...rest }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const { ref } = usePlacesWidget(
    {
      apiKey,
      onPlaceSelected: (place) => onPlaceSelected(GoogleS.parseAndTransformPlaceResult(place)),
      language: 'German',
      options: GoogleS.OPTIONS,
    },
    [deps],
  );
  return <TextInput label={label} ref={ref} placeholder="" {...rest} />;
};

export default GoogleAutocomplete;
