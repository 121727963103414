import { FC, useContext } from 'react';
import { BookingEventDateItem } from '../../Types/Event';
import { EventsContext } from '../../Service/Context/EventsContext';
import EventAppointmentItem from './EventAppointmentItem';
import EventDateItem from './EventDateItem';

const EventItem: FC<{ bookingEventDate: BookingEventDateItem }> = ({ bookingEventDate }) => {
  const { filter } = useContext(EventsContext);
  const { agencyModeActive } = filter;
  const { type } = bookingEventDate;

  if (type === 'APPOINTMENT') {
    if (agencyModeActive) return null;
    return <EventAppointmentItem bookingEventDate={bookingEventDate} />;
  }
  return <EventDateItem bookingEventDate={bookingEventDate} />;
};

export default EventItem;
