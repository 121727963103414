// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Checkbox, Group, Skeleton, Text, Tooltip } from '@mantine/core';
import { useContext } from 'react';
import { IconRefresh, IconSettings } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { ICalContext } from '../../../Service/Context/ICalContext';
import { ExternalSubscription } from '../../../Types/CalDavT';
import BookingStateItem from '../BookingStateItem';
import EmptyText from '../../../Atoms/EmptyText';
import { GoogleCalendarDto } from '../../../Types/GoogleT';

const ExternalCalendarsSelection = () => {
  const { loading, subscriptions, googleCalendars, refreshExternalICal, showTasks, setShowTasks } =
    useContext(ICalContext);
  const navigate = useNavigate();
  const isEmpty = !loading && subscriptions.length === 0 && googleCalendars.length === 0;

  const googleCalendarItemUI = (cal: GoogleCalendarDto) => {
    const { calendarId: id, calendarName: name, backgroundColor: color } = cal;
    return <BookingStateItem key={id} active label={name} color={color} textColor="inherit" />;
  };

  const calendarItemUI = (cal: ExternalSubscription) => {
    const { id, name, color, healthy } = cal;
    return (
      <BookingStateItem
        key={id}
        active
        label={name}
        color={color}
        textColor="inherit"
        textStyle={{ textDecoration: healthy ? 'none' : 'line-through' }}
        icon={
          !healthy ? (
            <Tooltip
              multiline
              position="bottom-end"
              transition="slide-down"
              label="Der Kalender konnte nicht abgerufen werden. Bitte prüfe deine Kalender-Einstellungen."
            >
              <div>⚠️</div>
            </Tooltip>
          ) : (
            <IconRefresh
              className="hooverable-icon"
              style={{ cursor: 'pointer' }}
              size={16}
              onClick={() => refreshExternalICal(cal)}
            />
          )
        }
      />
    );
  };

  return (
    <>
      <Group mt="xl" position="apart" mb="xs" pr={10}>
        <Text pl={10}>Private Kalender</Text>
        <div>
          <IconSettings size={16} style={{ cursor: 'pointer' }} onClick={() => navigate('/settings/calendar')} />
        </div>
      </Group>
      {loading && <Skeleton width="100%" height={200} />}
      {!loading && isEmpty && <EmptyText mt="xs" ml="md" />}
      {!loading && subscriptions.map(calendarItemUI)}
      {!loading && googleCalendars.map(googleCalendarItemUI)}
      <Text mt="xl" pl={10}>
        Optionen
      </Text>
      <Checkbox
        mt="sm"
        ml="md"
        label="Aufgaben anzeigen"
        checked={showTasks}
        onChange={() => setShowTasks(!showTasks)}
      />
    </>
  );
};

export default ExternalCalendarsSelection;
