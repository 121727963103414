import { useEffect, useState } from 'react';
import { MailOutBoundConfigDTO } from '../../Types/MailT';
import { MailS } from '../MailS';
import { DataS } from '../DataS';

// eslint-disable-next-line import/prefer-default-export
export const useMailOutBoundConfig = (id: number) => {
  const [config, setConfig] = useState<MailOutBoundConfigDTO | null>();
  const [loading, setLoading] = useState(false);
  const [sendingEmail, setSending] = useState(false);
  const [sentFailed, setSentFailed] = useState(false);

  useEffect(() => {
    setLoading(true);
    MailS.fetchMailOutboundConfig(id)
      .then(setConfig)
      .finally(() => setLoading(false));
  }, [id]);

  const handleChange = async (patch: Partial<MailOutBoundConfigDTO>) => {
    const resJSON = await MailS.patchMailOutboundConfig(id, patch);
    if (resJSON) {
      setConfig(resJSON);
    }
  };

  const sendMail = async () => {
    setSending(true);
    const sent = await MailS.sendTestMail(id);
    setSending(false);
    if (sent && sentFailed) setSentFailed(false);
    if (!sent) setSentFailed(true);
  };

  const providerHint =
    DataS.mailProviderHints.filter((h) => h.provider === config?.provider).length === 1
      ? DataS.mailProviderHints.filter((h) => h.provider === config?.provider)[0]
      : undefined;

  return { config, loading, handleChange, sendMail, sendingEmail, sentFailed, providerHint };
};
