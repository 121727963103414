// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Anchor, Box, Breadcrumbs, Group, Skeleton, Text, TextInput } from '@mantine/core';
import { useContext, useEffect } from 'react';
import { IconSearch } from '@tabler/icons';
import { i18n } from '@lingui/core';
import { useDebouncedState } from '@mantine/hooks';
import { WindowContext } from '../../Service/Context/WindowContext';
import { ContactsContext } from '../../Service/Context/ContactsContext';
import BookingStateItem from '../EventList/BookingStateItem';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import ContactsHeadActions from './ContactsHeadActions';
import { openContactGroupModal } from './ContactGroupsModal';
import ContactGroupsList from './ContactGroupsList';

const ContactsPageNavBar = () => {
  const { sm } = useContext(WindowContext);
  const { filter, updateFilter, contactGroups, loadingGroups, updateGroups, toggleFilterGroup } =
    useContext(ContactsContext);
  const { query, groupsOfInterest } = filter;
  const [localQuery, setLocalQuery] = useDebouncedState(query, 500);

  useEffect(() => {
    if (localQuery !== query) {
      updateFilter({ query: localQuery });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localQuery]);

  return (
    <>
      {!sm && (
        <Group sx={{ position: 'relative' }} mt={5} mb={10}>
          <Breadcrumbs>
            <Anchor p="md" size="xl" variant="text" weight="bolder">
              {i18n._('contacts')}
            </Anchor>
          </Breadcrumbs>
          <Box sx={{ position: 'absolute', right: 15, top: 15 }}>
            <ContactsHeadActions />
          </Box>
        </Group>
      )}

      <Box p="xs" mt={5} mb="sm">
        <TextInput
          defaultValue={localQuery}
          onChange={(e) => setLocalQuery(e.currentTarget.value)}
          icon={<IconSearch size={16} />}
          placeholder="Kontakt durchsuchen"
          variant="filled"
        />
      </Box>
      <BookingStateItem
        label="Alle Kontakte"
        onClick={() => updateFilter({ groupsOfInterest: [] })}
        active={groupsOfInterest.length === 0}
      />
      {loadingGroups && <Skeleton height={100} />}
      {!loadingGroups &&
        contactGroups.map((group) => (
          // eslint-disable-next-line react/jsx-key
          <BookingStateItem
            label={group.name}
            onClick={() => toggleFilterGroup(group.name)}
            active={groupsOfInterest.includes(group.name)}
          />
        ))}
      <Group sx={{ position: 'relative' }} mt="xl">
        <Text p="md" size="xl" variant="text" weight="bolder">
          Gruppen
        </Text>
        <Box sx={{ position: 'absolute', right: 15, top: 15 }}>
          <BookitupAddIcon
            onClick={() =>
              openContactGroupModal({
                onFinish: (newGroup) => updateGroups(newGroup, 'ADD'),
                existingNames: contactGroups.map((g) => g.name),
              })
            }
          />
        </Box>
      </Group>
      {loadingGroups && <Skeleton height={100} />}
      {contactGroups.length === 0 && (
        <Text ml="sm" italic color="dimmed" size="xs" weight="lighter">
          Keine Gruppen angelegt.
        </Text>
      )}
      {contactGroups.length > 0 && (
        <>
          <ContactGroupsList label="Normale Gruppen" groups={contactGroups.filter((g) => !g.recommendable)} />
          <ContactGroupsList label="Dienstleister-Gruppen" groups={contactGroups.filter((g) => g.recommendable)} />
        </>
      )}
    </>
  );
};

export default ContactsPageNavBar;
