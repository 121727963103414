import { Center, LoadingOverlay } from '@mantine/core';
import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { AzureS } from '../../../../Service/AzureS';
import { ToastS } from '../../../../Service/ToastS';

const AzureOAuthDispatcher = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get('code');
  const error = searchParams.get('error');

  useEffect(() => {
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verify = async () => {
    try {
      if (code) {
        await AzureS.completeOAuthProcess(code);
      } else if (error) {
        ToastS.error('azure-setup', 'office365 konnte nicht verbunden werden, da der Zugriff verweigert wurde.');
      }
    } finally {
      navigate('/settings/mail-account');
    }
  };

  return (
    <Center sx={{ position: 'relative', height: '100vh', width: '100vw' }}>
      <LoadingOverlay visible overlayBlur={2} loaderProps={{ size: 'xl' }} />
    </Center>
  );
};

export default AzureOAuthDispatcher;
