// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Stack, Button, Center, Text, Skeleton } from '@mantine/core';
import { useContext } from 'react';
import { closeAllModals } from '@mantine/modals';
import { i18n } from '@lingui/core';
import PanePaper from '../../../Molecules/PanePaper';
import { ChargebeeS } from '../../../Service/ChargebeeS';
import { AuthContext } from '../../../Service/Context/AuthContext';
import FieldValueRow from '../../../Atoms/FieldValueRow';
import { ResourceAddonContext } from '../../../Service/Context/ResourceAddonsContext';
import { BookitupSubscriptionContext } from '../../../Service/Context/BookitupSubscriptionContext';
import MigrateSubscriptionAlert from './MigrateSubscriptonAlert';
import FutureSubscriptionAlert from './FutureSubscriptionAlert';
import CancelSubscriptionButton from './CancelSubscriptionButton';
import SubscriptionStatusBadge from './SubscriptionStatusBadge';

const SubscriptionPane = () => {
  const { refreshToken } = useContext(AuthContext);
  const { loading, subscription, refresh: refreshSubscription } = useContext(BookitupSubscriptionContext);
  const { refresh: refreshAddon } = useContext(ResourceAddonContext);

  if (loading) {
    return <Skeleton radius="sm" height={200} width="100%" />;
  }

  if (!subscription) return null;

  const { billingInterval, licence, activatedAt, nextBillingDate, cancelledAt, status, withChargebee, startDate } =
    subscription;

  const openPortal = () => {
    const onClose = () => {
      refreshToken();
      refreshSubscription();
      refreshAddon();
    };
    ChargebeeS.openPortal(onClose);
  };

  return (
    <PanePaper withShadow withoutBorder title="Abonnement" rightSection={<SubscriptionStatusBadge status={status} />}>
      {!withChargebee && <MigrateSubscriptionAlert refreshSubscription={refreshSubscription} />}
      {status === 'FUTURE' && <FutureSubscriptionAlert startDate={startDate} />}
      <Stack spacing="xs" mt="sm">
        <FieldValueRow name="Derzeitiger Plan" value={i18n._(`subscription.licence.${licence}`)} />
        <FieldValueRow name="Frequenz" value={billingInterval} />
        {activatedAt && <FieldValueRow name="Fing an am" value={activatedAt} />}
        {nextBillingDate && <FieldValueRow name="Nächste Zahlung" value={nextBillingDate} />}
        {cancelledAt && <FieldValueRow name="Endet am" value={cancelledAt} />}
      </Stack>
      {withChargebee && (
        <>
          <Center mt="xl" mb={5}>
            <Button onClick={openPortal}>Abrechnung verwalten</Button>
          </Center>
          <Text align="center" color="dimmed" size="xs">
            Verwalte hier verfügbare Pläne und Add-ons
          </Text>
        </>
      )}
      {status === 'ACTIVE' && !withChargebee && (
        <CancelSubscriptionButton
          onCancel={() => {
            refreshSubscription();
            closeAllModals();
          }}
        />
      )}
    </PanePaper>
  );
};

export default SubscriptionPane;
