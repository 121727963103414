import { UseListStateHandlers, useListState } from '@mantine/hooks';
import { useState, useEffect } from 'react';
import { ExternalSubscription } from '../../Types/CalDavT';
import { CalDavS } from '../CalDavS';

export interface IcalState {
  loading: boolean;
  subscriptions: ExternalSubscription[];
  handlers: UseListStateHandlers<ExternalSubscription>;
}

// eslint-disable-next-line import/prefer-default-export
export const useExternalSubscriptions = () => {
  const [loading, setLoading] = useState(false);
  const [subscriptions, handlers] = useListState<ExternalSubscription>([]);
  const { setState: setSubscriptions } = handlers;

  useEffect(() => {
    setLoading(true);
    CalDavS.getExternalSubscriptions()
      .then(setSubscriptions)
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, subscriptions, handlers };
};
