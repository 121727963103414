import { Group, Skeleton, Text } from '@mantine/core';
import { IconMapPin } from '@tabler/icons';
import React, { useContext, useEffect, useState } from 'react';
import { WindowContext } from '../../Service/Context/WindowContext';
import { LocationS } from '../../Service/LocationS';

export const EventLocationDescription: React.FC<{ eventId: number }> = ({ eventId }) => {
  const { xs, windowWidth } = useContext(WindowContext);
  const [description, setDescription] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    LocationS.fetchByEventId(eventId)
      .then((location) => {
        if (location) {
          setDescription(LocationS.getDescription(location));
        }
      })
      .finally(() => setLoading(false));
  }, [eventId]);

  if (loading) {
    return <Skeleton height={20} width={100} mt={5} />;
  }

  if (!description) {
    return null;
  }

  return (
    <Group spacing={5}>
      <IconMapPin size={16} color="grey" />
      <Text
        color="dimmed"
        size="xs"
        weight="lighter"
        className="overflow-ellipsis"
        sx={{ width: xs ? windowWidth - 200 : 'initial' }}
      >
        {description}
      </Text>
    </Group>
  );
};

const LocationDescription: React.FC<{ locationId: number }> = ({ locationId }) => {
  const { xs, windowWidth } = useContext(WindowContext);
  const [description, setDescription] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    LocationS.fetchById(locationId)
      .then((location) => {
        if (location) {
          setDescription(LocationS.getDescription(location));
        }
      })
      .finally(() => setLoading(false));
  }, [locationId]);

  if (loading) {
    return <Skeleton height={20} width={100} mt={5} />;
  }

  if (!description) {
    return null;
  }

  return (
    <Text
      color="dimmed"
      size="xs"
      weight="lighter"
      className="overflow-ellipsis"
      sx={{ width: xs ? windowWidth - 0 : 'initial' }}
    >
      <IconMapPin size={16} color="grey" style={{ marginRight: 5, marginTop: -3 }} />
      {description}
    </Text>
  );
};

export default LocationDescription;
