/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Autocomplete, AutocompleteProps, Loader } from '@mantine/core';
import { IconX } from '@tabler/icons';
import { fetchContacts } from '../../Service/restapi/contactService';
import AutoCompleteContactItem from '../../Atoms/Autocomplete/AutoCompleteContactItem';
import { Contact, ContactPersonDescription } from '../../Service/ContactS';

interface Props extends Partial<AutocompleteProps> {
  setContact: (contact: Contact | ContactPersonDescription | undefined) => void;
  clearable?: boolean;
  testId?: string;
  omittedContacts?: number[];
  label?: string;
  placeholder?: string;
}

const ContactAutocomplete: React.FC<Props> = ({ setContact, label, clearable, testId, placeholder, omittedContacts = [], ...rest }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, selectContact] = useState<string>();

  const clear = () => {
    selectContact('');
    setContact(undefined);
  };

  useEffect(() => {
    fetchContacts()
      .then((resp: any) => {
        if (resp.ok) {
          resp.json().then((data: Contact[]) => {
            const tmpContacts = data.filter((_contact) => !omittedContacts.includes(_contact.id));
            const mappedContacts: any = tmpContacts.map((contact) => ({
              ...contact,
              value: contact.personDescription || contact.email,
            }));
            setContacts(mappedContacts);
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Match selected contact based on the person description or email
  useEffect(() => {
    if (selectedContact && contacts && contacts.length > 0) {
      const filteredContact = contacts.find((contact) => {
        const { personDescription, email } = contact;
        return personDescription === selectedContact || email === selectedContact;
      });
      if (filteredContact) {
        setContact(filteredContact);
      } else {
        // Contact not found. New contact will be created within an event
        setContact({ personDescription: selectedContact });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts, selectedContact]);

  return (
    <Autocomplete
      rightSection={
        loading ? (
          <Loader size="sm" />
        ) : (
          selectedContact && clearable && <IconX size={16} onClick={clear} style={{ cursor: 'pointer' }} />
        )
      }
      itemComponent={AutoCompleteContactItem}
      limit={8}
      data={contacts}
      value={selectedContact}
      label={label}
      onChange={selectContact}
      filter={(value, item) =>
        item.value?.toLowerCase().includes(value?.toLowerCase().trim()) ||
        item.email?.toLowerCase().includes(value?.toLowerCase().trim())
      }
      zIndex={9999}
      data-testid={testId}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default ContactAutocomplete;
