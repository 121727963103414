import moment from 'moment';
import _ from 'lodash';
import { DistanceDto, LocationDto } from '../Types/LocationT';
import { ajaxActions } from './AjaxActions';
import { Contact } from './ContactS';
import { isEmpty } from '../Utils/utils';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_LOCATION_SERVICE_URL;

const fetchByEventId = async (eventId: number): Promise<LocationDto | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/locations?eventId=${eventId}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchById = async (locationId: number): Promise<LocationDto | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/locations/${locationId}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchAllByEventId = async (eventId: number): Promise<LocationDto[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/locations/event/${eventId}`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const calculateDistance = async (contactId: number, locationId: number): Promise<DistanceDto | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/distances/calc?contactId=${contactId}&locationId=${locationId}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const formatDuration = (distance: DistanceDto | null | undefined) => {
  if (!distance) {
    return null;
  }
  const { travelTimeInSeconds } = distance;
  return `${moment.duration({ seconds: travelTimeInSeconds }).humanize()}`;
};

const getDistanceMeters = (distance: DistanceDto | null | undefined) => {
  if (!distance || !distance.distanceInMeters) {
    return null;
  }
  return metersToKm(distance.distanceInMeters);
};

const metersToKm = (meters: number) => Math.round((meters / 1000) * 100) / 100;

const sortContactsByDistance = (contacts: Contact[]): Contact[] =>
  _.sortBy(contacts, (contact: Contact) => {
    const { requestAnswer, distance } = contact;
    if (requestAnswer === 'UNAVAILABLE') {
      return Number.MAX_VALUE; // Return max number if unavailable to place contact at the bottom of the list
    }
    return distance?.distanceInMeters;
  });

const isFilled = (location: LocationDto) => location.name !== '';

const isEmptyLocation = (location: LocationDto) => {
  const { city, zipCode, streetAndNo } = location;
  return isEmpty(city) && isEmpty(zipCode) && isEmpty(streetAndNo);
};

const createLocation = async (location: Partial<LocationDto>): Promise<LocationDto | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/locations`, location);
  if (res.ok) {
    ToastS.success('loc-created', `Standort ${getDescription(location as LocationDto)} erstellt`);
    return res.json();
  }
  return null;
};

const editLocation = async (id: number, patch: Partial<LocationDto>): Promise<LocationDto | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/locations/${id}`, patch);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const deleteLocation = async (id: number): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/locations/${id}`);
  if (res.status === 204) {
    ToastS.info('loc-deleted', 'Standort gelöscht.');
    return true;
  }
  ToastS.generalError();
  return false;
};

const getDescription = (location: LocationDto) => {
  const { name } = location;
  if (name && name !== '') {
    return name;
  }
  return getAddressDescription(location);
};

const getAddressDescription = (location: LocationDto) => {
  const { streetAndNo, zipCode, city } = location;

  if (streetAndNo) {
    if (city) {
      return `${streetAndNo}, ${zipCode ? `${zipCode} ` : ''}${city}`;
    }
    return streetAndNo;
  }
  if (city) {
    return `${zipCode ? `${zipCode} ` : ''}${city}`;
  }
  return null;
};

const getDistanceInKms = (distance: DistanceDto) => Math.round(distance.distanceInMeters / 1000);

// eslint-disable-next-line import/prefer-default-export
export const LocationS = {
  fetchById,
  fetchByEventId,
  fetchAllByEventId,
  calculateDistance,
  formatDuration,
  metersToKm,
  getDistanceMeters,
  sortContactsByDistance,
  isFilled,
  isEmptyLocation,
  createLocation,
  editLocation,
  deleteLocation,
  getDescription,
  getAddressDescription,
  getDistanceInKms,
};
