/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Button, Center, FileInput, LoadingOverlay, Text, TextInput } from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { IconEye, IconFileImport, IconFileX, IconTemplate } from '@tabler/icons';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from '@lingui/core';
import { ContractTemplateS } from '../../../../Service/ContractTemplateS';
import { ValidationS } from '../../../../Service/ValidationS';
import { MixpanelS } from '../../../../Service/MixpanelS';
import { ConstantS } from '../../../../Service/ConstantS';

interface CreateTemplateProps {
  existingNames?: string[];
}

// eslint-disable-next-line import/prefer-default-export
export const openCreateTemplateModal = (props: CreateTemplateProps) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl" align="center">
        Vorlage erstellen
      </Text>
    ),
    children: <CreateTemplateModal {...props} />,
  });
};

const CreateTemplateModal: FC<CreateTemplateProps> = ({ existingNames = [] }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showFileInput, setShowFileInput] = useState(false);
  const [name, setName] = useState('');
  const [contractFile, setContractFile] = useState<File | null>();
  const [failed, setFailed] = useState(false);
  const nameTaken = existingNames.includes(name);
  const validName = ValidationS.validateTemplateName(name);

  const createBlankTemplate = async () => {
    setLoading(true);
    const resJSON = await ContractTemplateS.createTemplate(name, contractFile ?? undefined);
    setLoading(false);
    if (resJSON) {
      closeAllModals();
      navigate(`/settings/template/contract/${name}`);
    } else {
      setContractFile(undefined);
      setFailed(true);
    }
  };

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Alert
        title={failed ? 'Vertrag konnte nicht erstellt werden' : 'Vertrag mit KI importieren'}
        sx={(theme) => ({
          backgroundColor: failed ? theme.colors.red[4] : theme.colors.lightBlue[0],
          color: failed ? 'white' : '#1D5191',
        })}
        // eslint-disable-next-line no-nested-ternary
        icon={failed ? <IconFileX size={20} /> : showFileInput ? <IconEye size={20} /> : <IconFileImport size={20} />}
      >
        {failed && (
          <Text color="white">
            Dein Vertrag konnte leider nicht automatisiert importiert werden. Lege einen leeren Vertrag an und kopiere
            die Textbausteine manuell.
          </Text>
        )}
        {!failed && !showFileInput && (
          <>
            <Text>
              Nutze die KI, um deinen Vertrag schneller in bookitup zu hinterlegen. Lade ihn einfach als PDF (empfohlen) oder Word-Datei hoch und überlass der KI die harte Arbeit, deinen Vertrag in bookitup zu hinterlegen.
            </Text>
            <Button size="xs" mt="md" onClick={() => setShowFileInput(true)}>
              Jetzt ausprobieren
            </Button>
          </>
        )}
        {!failed && showFileInput && (
          <Text>
            Bitte beachte: Die Formattierung wird dabei nicht übernommen. Auch kann es vorkommen, dass ganze Textbausteine nicht übernommen werden. Prüfe daher unbedingt sorgfältig den importierten Vertrag.
          </Text>
        )}
      </Alert>
      <TextInput
        mt="md"
        label="Name der Vorlage"
        icon={<IconTemplate size={20} />}
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
        error={
          // eslint-disable-next-line no-nested-ternary
          !validName
            ? 'Der Name enthält unerlaubte Sonderzeichen: %/?#:&+"'
            : nameTaken
            ? 'Dieser Name ist bereits vergeben.'
            : null
        }
      />
      {showFileInput && (
        <>
          <FileInput
            value={contractFile}
            onChange={setContractFile}
            mt="sm"
            icon={<IconFileImport />}
            label="PDF (empfohlen) oder Word-Datei"
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          <Text color="dimmed" size="xs" mt={5}>
            Der Vertragsimport kann eine Weile dauern.
          </Text>
        </>
      )}
      <Center mt={40}>
        <Button disabled={nameTaken || name === '' || loading} onClick={createBlankTemplate} sx={{ minWidth: 200 }}>
          {i18n._('common.apply')}
        </Button>
      </Center>
    </>
  );
};
