// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Accordion, Box, Progress, Stack, Text, Timeline } from '@mantine/core';
import { FC, useContext, useState } from 'react';
import ProcedureStatusIcon from '../../../Atoms/ProcedureStatusIcon';
import { WorkflowS } from '../../../Service/WorkflowS';
import { WorkflowDTO } from '../../../Types/LogT';
import { EventContext } from '../../../Service/Context/EventContext';
import WorkflowStep from './WorkflowStep';
import WorkflowActions from './WorkflowActions';

interface Props {
  workflow: WorkflowDTO;
  onDelete: () => void;
  previewOpened: boolean;
  setShowPreview: (show: boolean) => unknown;
}

const Workflow: FC<Props> = ({ workflow: initial, previewOpened, setShowPreview, onDelete }) => {
  const { dispatchProcedures } = useContext<any>(EventContext);
  const [workflow, setWorkflow] = useState(initial);
  const { id, name, steps, currentStep, startedAt, executionStatus, totalSteps, completedSteps } = workflow;

  const failed =
    executionStatus === 'FAILED' || executionStatus === 'PRECONDITION_FAILED' || executionStatus === 'ABORTED';

  const refresh = async () => {
    const resJSON = await WorkflowS.fetchWorkflow(id);
    if (resJSON) {
      const executedProcedures = WorkflowS.getExecutedProcedures(workflow, resJSON);
      if (executedProcedures.length > 0 || executionStatus !== resJSON.executionStatus) {
        dispatchProcedures(executedProcedures);
        setWorkflow(resJSON);
      }
    }
  };

  return (
    <Accordion.Item value={id}>
      <Box sx={{ display: 'flex' }}>
        <Accordion.Control onClick={() => setShowPreview(!previewOpened)}>
          <Stack spacing={0}>
            <Text>{name}</Text>
            <Text color={failed ? 'red' : 'blue'} size="xs">
              {WorkflowS.getWorkflowDescription(workflow)}
            </Text>
          </Stack>
          <Progress
            animate={!executionStatus}
            mt={5}
            value={executionStatus === 'ABORTED' || steps.length === 0 ? 100 : (100 * completedSteps) / totalSteps}
            color={WorkflowS.getProgressBarColor(workflow)}
          />
        </Accordion.Control>
        <WorkflowActions
          workflow={workflow}
          setWorkflow={setWorkflow}
          onDelete={onDelete}
          closeAccordionItem={() => setShowPreview(false)}
        />
      </Box>
      <Accordion.Panel>
        <Timeline active={steps.length} mt="md" bulletSize={32} lineWidth={3}>
          {steps.map((step, index) => (
            <Timeline.Item
              sx={{ marginLeft: step.alternativeStep ? 25 : 0, position: 'relative' }}
              key={step.id}
              lineVariant={step.alternativeStep ? 'dashed' : 'solid'}
              bullet={<ProcedureStatusIcon workflowStep={step} />}
              color={WorkflowS.getTimelineItemColor(step)}
            >
              <WorkflowStep
                index={index}
                workflowStep={step}
                currentStep={index === currentStep}
                workflow={workflow}
                reload={refresh}
              />
            </Timeline.Item>
          ))}
        </Timeline>
      </Accordion.Panel>
    </Accordion.Item>
  );
};

export default Workflow;
