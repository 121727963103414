import { createContext, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { AddonDTO, SubscriptionDTO } from '../../Types/Billing';
import { useBookitupSubscription } from '../hooks/useBookitupSubscription';

interface BookitupSubscriptionContextInterface {
  subscription?: SubscriptionDTO;
  resourceAddon: AddonDTO | null;
  refresh: () => void;
  loading: boolean;
}

export const BookitupSubscriptionContext = createContext<BookitupSubscriptionContextInterface>(
  {} as BookitupSubscriptionContextInterface,
);

export const BookitupSubscriptionProvider = () => {
  const { loading, refresh, subscription, resourceAddon } = useBookitupSubscription();

  const value = useMemo(
    () => ({
      loading,
      resourceAddon,
      refresh,
      subscription,
    }),
    [loading, refresh, subscription, resourceAddon],
  );

  return (
    <BookitupSubscriptionContext.Provider value={value}>
      <Outlet />
    </BookitupSubscriptionContext.Provider>
  );
};
