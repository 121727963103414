/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Button, Center, Checkbox, Loader, Stack, Text, Textarea } from '@mantine/core';
import { i18n } from '@lingui/core';
import { FC, useState } from 'react';
import { closeAllModals } from '@mantine/modals';
import { IconMessage, IconSend, IconShieldHalfFilled } from '@tabler/icons';
import { EventS } from '../../../../Service/EventS';
import { MessageS } from '../../../../Service/MessageS';
import { RecommendationS } from '../../../../Service/RecommendationS';
import { RecommendationDto } from '../../../../Types/RecommendationT';
import { deepCopy } from '../../../../Utils/utils';
import { useForm } from '@mantine/form';

interface Props {
  recommendation: RecommendationDto;
  patchRecommendation: (recommendation: RecommendationDto) => void;
}

const ExportEventInfoModal: FC<Props> = ({ recommendation, patchRecommendation }) => {
  const { id, eventId, recommendedContactId } = recommendation;
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      message: '',
      includeCustomerName: true,
      includeCustomerPhone: true,
      includeCustomerEmail: true,
      includeLocation: true,
    },
  });

  const onSubmit = () => {
    setLoading(true);
    const { values } = form;
    Promise.all([
      MessageS.exportEvent(eventId, recommendedContactId, id, values),
      EventS.shareEvent(eventId, recommendedContactId, id, values),
    ])
      .then((values) => {
        if (values[0] && values[1]) {
          RecommendationS.edit(id, { message: form.values.message, shareContact: true }).then(
            (_recommendation) =>
              _recommendation && patchRecommendation({ ...deepCopy(recommendation), lastContactShare: new Date() }),
          );
        }
      })
      .finally(() => {
        setLoading(false);
        closeAllModals();
      });
  };

  return (
    <>
      <Alert
        title="Datenschutzrechtlichen"
        icon={<IconShieldHalfFilled />}
        sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })}
      >
        <Text>
          Beachte bitte, dass Du aus datenschutzrechtlichen Gründen die Genehmigung Deines Kunden brauchst, seine
          Kontaktdaten an Dritte weiterzuleiten.
        </Text>
      </Alert>
      <Text mt="md" mb="md">
        Mit &quot;Senden&quot; übermittelst Du dem Kontakt folgende Infos:
      </Text>
      <Stack spacing={5} ml="xs">
        <Checkbox
          disabled
          label="Name des Kunden"
          {...form.getInputProps('includeCustomerName', { type: 'checkbox' })}
        />
        <Checkbox
          label="Telefonnummer des Kunden"
          {...form.getInputProps('includeCustomerPhone', { type: 'checkbox' })}
        />
        <Checkbox label="E-Mail des Kunden" {...form.getInputProps('includeCustomerEmail', { type: 'checkbox' })} />
      </Stack>
      <Textarea
        label="Nachricht"
        icon={<IconMessage size={16} />}
        disabled={loading}
        maxRows={10}
        variant="filled"
        mt="xl"
        placeholder="Optionale Nachricht an den Dienstleister"
        {...form.getInputProps('message')}
      />
      <Center mt="md">
        <Button
          mt="xl"
          mb="sm"
          disabled={loading}
          onClick={onSubmit}
          leftIcon={loading ? <Loader size="xs" /> : <IconSend size={16} />}
          sx={{ minWidth: 250 }}
        >
          {i18n._('actions.send')}
        </Button>
      </Center>
    </>
  );
};

export default ExportEventInfoModal;
