/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, LoadingOverlay, Paper, Space, Text, TextInput } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconAt } from '@tabler/icons';
import { FC, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import validator from 'validator';
import { useForm } from '@mantine/form';
import { AlertS } from '../../Service/AlertS';
import { AuthS } from '../../Service/restapi/authService';
import { RoutingS } from '../../Service/RoutingS';
import AlertBox from './AlertBox';
import {MixpanelS} from "../../Service/MixpanelS";
import {ConstantS} from "../../Service/ConstantS";

const EmailVerificationPage: FC = () => {
  const isPhoneDevice = useMediaQuery('(max-width: 480px)');
  const navigate: NavigateFunction = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>();

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (validator.isEmail(value) ? null : 'Bitte gib eine valide E-Mail-Adresse ein'),
    },
  });

  useEffect(() => {
    document.title = 'E-Mail Verifizierung';
    const token = searchParams.get('token') || searchParams.get('submissionGuid');
    if (token) {
      setLoading(true);
      AuthS.confirmRegistrationAPI(token)
        .then((resp) => {
          if (resp.ok) {
            setTimeout(() => {
              MixpanelS.track(ConstantS.TrackingEvents.AccountActivated)
              navigate(`${RoutingS.ROUTES.LOGIN}?alert=${AlertS.AlertType.REGISTRATION_COMPLETED}`);
            }, 2000);
          } else if (resp.status === 412) {
            resp.text().then((resetToken) => {
              setTimeout(() => {
                MixpanelS.track(ConstantS.TrackingEvents.AccountActivated)
                MixpanelS.track(ConstantS.TrackingEvents.AccountPasswordMissing)
                navigate(`${RoutingS.ROUTES.RESET_PASSWORD}?token=${resetToken}`);
              }, 2000);
            });
          } else {
            setTimeout(() => {
              MixpanelS.track(ConstantS.TrackingEvents.VerificationMailExpired)
            }, 2000);
          }
        })
        .catch(() => setAlert(AlertS.AlertType.SERVER_UNAVAILABLE))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestNewEmail = () => {
    const { email } = form.values;
    setLoading(true);
    MixpanelS.track(ConstantS.TrackingEvents.ResendVerificationMail, {email});
    AuthS.resendVerificationEmailAPI(email)
      .then((resp) => {
        if (resp.ok) {
          navigate(RoutingS.ROUTES.REGISTRATION_COMPLETED);
        } else if (resp.status === 409) {
          navigate(`${RoutingS.ROUTES.LOGIN}?alert=${AlertS.AlertType.REGISTRATION_COMPLETED}`);
        } else {
          setAlert(AlertS.AlertType.UNEXPECTED_ERROR);
        }
      })
      .catch(() => setAlert(AlertS.AlertType.SERVER_UNAVAILABLE))
      .finally(() => setLoading(false));
  };

  return (
    <Paper
      shadow="xs"
      p={40}
      sx={{
        maxWidth: 480,
        borderRadius: 10,
        position: 'relative',
        backgroundClip: 'padding-box, border-box',
        backgroundImage: 'linear-gradient(#fff,#fff),linear-gradient(88deg,#ffba5e,#ff6b69)',
        borderTop: '10px double transparent',
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={5} loaderProps={{ size: 'xl' }} />
      <Space h="xl" />
      <Text size={isPhoneDevice ? 22 : 28}>Dein Link ist abgelaufen.</Text>
      <Space h="xs" />
      <Text color="dimmed" size={isPhoneDevice ? 'sm' : 'md'}>
        Bitte trage die E-Mail ein, mit der Du Dich bei bookitup registriert hast. Wir schicken Dir einen neuen
        Bestätigungslink zu.
      </Text>
      <Space h="xl" />
      {alert && <AlertBox alert={alert} />}
      <form onSubmit={form.onSubmit(requestNewEmail)}>
        <TextInput label="E-Mail" icon={<IconAt size={14} />} {...form.getInputProps('email')} />
        <Space h="xl" />
        <Button fullWidth type="submit">
          Bestätigungslink erhalten
        </Button>
      </form>
      <Space h="md" />
      <Text color="blue" align="center" sx={{ cursor: 'pointer' }} onClick={() => navigate(RoutingS.ROUTES.LOGIN)}>
        Zurück zur Anmeldung
      </Text>
    </Paper>
  );
};

export default EmailVerificationPage;
