/* eslint-disable react/jsx-no-useless-fragment */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Text } from '@mantine/core';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext, useJwtClaims } from '../Service/Context/AuthContext';
import { WindowContext } from '../Service/Context/WindowContext';
import { RoutingS } from '../Service/RoutingS';
import { LicenceS } from '../Service/LicenceS';
import { AuthS } from '../Service/restapi/authService';
import DemoLink from './DemoLink';

const LicenceHeader = () => {
  const { sm } = useContext(WindowContext);
  const { licence, trialEndsAt, registeredOn } = useJwtClaims();
  const remainingTrialDays = LicenceS.getRemainingTrialDays(new Date(trialEndsAt));
  const userDays = registeredOn ? LicenceS.getUserDays(new Date(registeredOn)) : 100; // registeredOn was just added to decodedJwt
  const welcomeDealAvailable = userDays >= 0 && userDays <= 14; // Check for first two weeks
  const remainingDealDays = 14 - userDays;

  return (
    <Box p="xs" sx={(theme) => ({ backgroundColor: theme.colors.blue[4], height: 40 })}>
      <Text align="center" color="white">
        {licence === 'BASIC' && sm && <BasicSmallText />}
        {licence === 'BASIC' && !sm && <BasicBiggerText />}
        {!welcomeDealAvailable && licence !== 'BASIC' && sm && (
          <TrialSmallText remainingTrialDays={remainingTrialDays} />
        )}
        {!welcomeDealAvailable && licence !== 'BASIC' && !sm && (
          <TrialBiggerText remainingTrialDays={remainingTrialDays} />
        )}
        {welcomeDealAvailable && sm && <WelcomeDealTextSmall days={remainingDealDays} />}
        {welcomeDealAvailable && !sm && <WelcomeDealTextBigger days={remainingDealDays} />}
      </Text>
    </Box>
  );
};

const TrialSmallText: FC<{ remainingTrialDays: number }> = ({ remainingTrialDays }) => {
  const navigate = useNavigate();
  return (
    <>
      {remainingTrialDays > 0 ? (
        <>
          {`${remainingTrialDays} Tage verbleibend. `}
          <DemoLink />
        </>
      ) : (
        <>
          Testphase endet heute.{'  '}
          <u style={{ cursor: 'pointer' }} onClick={() => navigate(RoutingS.ROUTES.PLANS)}>
            Paket wählen
          </u>
        </>
      )}
    </>
  );
};

const TrialBiggerText: FC<{ remainingTrialDays: number }> = ({ remainingTrialDays }) => {
  const navigate = useNavigate();
  return (
    <>
      {remainingTrialDays > 0 ? (
        <>
          {`Du testest das Standard Paket. ${remainingTrialDays} Tage verbleibend. `}
          <DemoLink />
        </>
      ) : (
        <>
          Deine Testphase endet heute.{'  '}
          <u style={{ cursor: 'pointer' }} onClick={() => navigate(RoutingS.ROUTES.PLANS)}>
            Paket wählen
          </u>
        </>
      )}
    </>
  );
};

const BasicSmallText = () => {
  const { jwtClaims } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <>
      Starter-Plan aktiv.{'  '}
      <>
        {LicenceS.trialRedeemable(jwtClaims) ? (
          <>
            {'  '}
            <u style={{ cursor: 'pointer' }} onClick={() => AuthS.redeemTrialLicence()}>
              Testphase erneut starten
            </u>
          </>
        ) : (
          <>
            <b>
              <u style={{ cursor: 'pointer' }} onClick={() => navigate(RoutingS.ROUTES.PLANS)}>
                Paket wählen
              </u>
            </b>
          </>
        )}
      </>
    </>
  );
};

const BasicBiggerText = () => {
  const { jwtClaims } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <>
      <b>Kostenfreier Starter-Plan aktiv.</b>
      <>
        {LicenceS.trialRedeemable(jwtClaims) ? (
          <>
            {'  '}
            <u style={{ cursor: 'pointer' }} onClick={() => AuthS.redeemTrialLicence()}>
              Testphase erneut starten
            </u>
          </>
        ) : (
          <>
            {'  '}
            Upgrade Deine Verwaltung zum passenden Pro-Paket und hole Dir Deine Zeit zurück.{'  '}
            <b>
              <u style={{ cursor: 'pointer' }} onClick={() => navigate(RoutingS.ROUTES.PLANS)}>
                Paket wählen
              </u>
            </b>
          </>
        )}
      </>
    </>
  );
};

const WelcomeDealTextBigger: FC<{ days: number }> = ({ days }) => {
  const navigate = useNavigate();

  return (
    <>
      Verpasse nicht unseren{' '}
      <b>
        <u style={{ cursor: 'pointer' }} onClick={() => navigate(RoutingS.ROUTES.PLANS)}>
          WELCOME DEAL
        </u>
      </b>{' '}
      von <b>20%</b>! Der Deal ist{' '}
      {days === 0 ? (
        <b>NUR NOCH HEUTE</b>
      ) : (
        <>
          noch <b>{days}</b> Tage
        </>
      )}{' '}
      gültig.
    </>
  );
};

const WelcomeDealTextSmall: FC<{ days: number }> = ({ days }) => {
  const navigate = useNavigate();

  return (
    <>
      Dein{' '}
      <b>
        <u style={{ cursor: 'pointer' }} onClick={() => navigate(RoutingS.ROUTES.PLANS)}>
          WELCOME DEAL
        </u>
      </b>{' '}
      läuft{' '}
      {days === 0 ? (
        <b>HEUTE{'  '}</b>
      ) : (
        <>
          in{'  '}
          <b>{days}</b> Tagen{' '}
        </>
      )}
      ab.
    </>
  );
};

export default LicenceHeader;
