import { i18n } from '@lingui/core';
import { ExternalSubscription, ResourceCalendar } from '../Types/CalDavT';
import { ajaxActions } from './AjaxActions';
import { ConstantS } from './ConstantS';
import { MixpanelS } from './MixpanelS';
import { ToastS } from './ToastS';
import { showNotification, updateNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons';

const BASE_URL = process.env.REACT_APP_CALDAV_SERVICE_URL;

const getCalendars = async (resouceId: string): Promise<ResourceCalendar[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/calendars?resourceId=${resouceId}`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const createCalendar = async (
  resourceId: string,
  label: string,
  calendarUrl: string,
): Promise<ResourceCalendar | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/calendars`, { resourceId, label, calendarUrl });
  if (res.ok) {
    return res.json();
  }
  if (res.status === 409) {
    ToastS.warn('cal-confict', 'Dieser Kalender wurde bereits hinzugefügt.');
  } else if (res.status === 412) {
    ToastS.error('cal-invalid', 'Der Kalender existiert wahrscheinlich nicht oder die angegebene URL ist falsch.');
  } else {
    ToastS.generalError();
  }
  return null;
};

const editCalendar = async (calendarId: string, patch: Partial<ResourceCalendar>): Promise<ResourceCalendar | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/calendars/${calendarId}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const removeCalendar = async (calendarId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/calendars/${calendarId}`);
  if (res.status === 204) {
    ToastS.info('cal-deleted', 'Kalender wurde gelöscht');
    return true;
  }
  ToastS.generalError();
  return false;
};

const getExternalSubscriptions = async (): Promise<ExternalSubscription[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/externalSubscriptions`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const getSubscriptionUrl = (subscriptionId: string) => `${BASE_URL}/externalSubscriptions/${subscriptionId}.ics`;

const createExternalCal = async (subscriptionUrl: string): Promise<ExternalSubscription | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/externalSubscriptions?url=${subscriptionUrl}`);
  if (res.status === 201) {
    ToastS.success('add.external.subscription', i18n._('external-subscription.add-success'));
    MixpanelS.track(ConstantS.TrackingEvents.ExternalCalendarAdded);
    return res.json();
  }
  ToastS.error('add.external.subscription', i18n._('external-subscription.add-error'));
  return null;
};

const editExternalCal = async (
  id: string,
  patch: Partial<ExternalSubscription>,
): Promise<ExternalSubscription | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/externalSubscriptions/${id}`, patch);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const deleteExternalCal = async (id: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/externalSubscriptions/${id}`);
  if (res.ok) {
    ToastS.success('delete.external.subscription', i18n._('external-subscription.delete-success'));
    return true;
  }
  ToastS.error('delete.external.subscription', i18n._('external-subscription.delete-error'));
  return false;
};

const retryConnection = async (id: string): Promise<boolean> => {
  showNotification({
    id: 'retry-ical',
    loading: true,
    title: 'Kalender erneut verbinden',
    message: 'Auf den Remote-Kalender zugreifen ...',
    autoClose: false,
    disallowClose: true,
  });
  const res = await ajaxActions.get(`${BASE_URL}/externalSubscriptions/retry-connection/${id}`);
  if (res.ok) {
    updateNotification({
      id: 'retry-ical',
      loading: false,
      message: 'Kalender wieder verbunden.',
      color: 'teal',
      icon: <IconCheck size={16} />,
      autoClose: 2000,
    });
    return true;
  }
  updateNotification({
    id: 'retry-ical',
    loading: false,
    message: 'Kalender kann nicht erreicht werden.',
    color: 'red',
    icon: <IconX size={16} />,
    autoClose: 2000,
  });
  return false;
};

// eslint-disable-next-line import/prefer-default-export
export const CalDavS = {
  getCalendars,
  createCalendar,
  editCalendar,
  removeCalendar,
  getExternalSubscriptions,
  getSubscriptionUrl,
  createExternalCal,
  editExternalCal,
  deleteExternalCal,
  retryConnection,
};
