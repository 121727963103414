import { useState, useEffect } from 'react';
import { useModals, ContextModalProps } from '@mantine/modals';
import { ModalFooter } from 'reactstrap';
import { Center, Checkbox, SegmentedControl, Textarea, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { TransButtonLink, TransButtonPrimary } from '../../../Atoms/Buttons';
import { deleteTask, patchTask } from '../../../Service/restapi/taskService';
import { AgendaItem } from '../../../Types/AgendaItem';
import AgendaGroup from '../../../Types/AgendaGroup';
import EventSearchAutocomplete from '../../../Organismns/Event/EventSearchAutocomplete';
import { parseDate } from '../../../Utils/dateUtils';
import { ToastS } from '../../../Service/ToastS';
import TaskGroupSelection from './TaskGroupSelection';
import useTaskConfirmDeleteModal from './TaskConfirmDelete';

type Props = {
  initialTask: AgendaItem;
  group?: AgendaGroup;
  showEntityTypeSelection?: boolean;
  finish: (task?: AgendaItem) => void;
  profile: any;
};

const TaskEditModal = ({ context, id, innerProps }: ContextModalProps<Props>) => {
  const { initialTask, group, finish, showEntityTypeSelection, profile } = innerProps;

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(initialTask.name);
  const [dueDate, setDueDate] = useState<Date | null>(
    initialTask.dueDate !== undefined && initialTask.dueDate !== null ? parseDate(initialTask.dueDate) : null,
  );
  const [priority, setPriority] = useState(initialTask.priority);
  const [notes, setNotes] = useState(initialTask.notes);
  const [groupName, setGroupName] = useState(group?.name ?? 'Standard');
  const [groupId, setGroupId] = useState(group?.id);


  const [selectedEventId, setSelectedEventId] = useState(
    initialTask.entityType === 'events' ? initialTask.entityId : undefined,
  );

  const [selectedEntityType, setSelectedEntityType] = useState(initialTask.entityType);

  const { closeAll } = useModals();

  const { openConfirmDeleteModal } = useTaskConfirmDeleteModal();

  const onDeleteClick = () => openConfirmDeleteModal(removeTask, closeAll);

  useEffect(() => {
    setName(initialTask.name);
    setDueDate(
      initialTask.dueDate !== undefined && initialTask.dueDate !== null ? parseDate(initialTask.dueDate) : null,
    );
    setPriority(initialTask.priority);
    setNotes(initialTask.notes);
  }, [initialTask]);

  const update = () => {
    if (selectedEntityType === 'events' && (!name || !groupName || !selectedEventId)) {
      ToastS.error(
        'update.task',
        'Die Aufgabe konnte nicht aktualisiert werden. Bitte Name, Gruppe und Veranstaltung auswählen.',
      );
    } else if (selectedEntityType === 'profiles' && (!name || !groupName)) {
      ToastS.error('update.task', 'Die Aufgabe konnte nicht aktualisiert werden. Bitte Name und Gruppe auswählen.');
    } else {
      updateTask();
    }
  };

  const updateTask = () => {
    setLoading(true);
    patchTask(initialTask.id, {
      name,
      dueDate: dueDate !== null ? dueDate.toISOString() : undefined,
      notes,
      priority,
      groupId,
      groupName,
      entityId: selectedEventId || profile.id,
      entityType: selectedEntityType,
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((updatedTask) => {
            finish(updatedTask);
            closeAll();
          });
          ToastS.success('update.task', 'Die Aufgabe wurde erfolgreich aktualisiert');
        } else {
          console.error(`could not patch task ${initialTask.id}`);
          ToastS.error('update.task', 'Die Aufgabe konnte nicht aktualisiert werden');
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not patch task ${initialTask.id}`);
        ToastS.error('update.task', 'Die Aufgabe konnte nicht aktualisiert werden');
      })
      .finally(() => setLoading(false));
  };

  const removeTask = () => {
    deleteTask(initialTask.id).then((resp) => {
      if (resp.ok) {
        closeAll();
        finish();
        ToastS.success('remove.task', 'Die Aufgabe wurde erfolgreich gelöscht');
      } else {
        console.error(`could not delete task ${name}`);
        ToastS.error('remove.task', 'Die Aufgabe konnte nicht gelöscht werden');
      }
    });
  };

  return (
    <>
      {showEntityTypeSelection && (
        <Center>
          <SegmentedControl
            value={selectedEntityType}
            onChange={setSelectedEntityType}
            data={[
              { label: 'Event', value: 'events' },
              { label: 'Allgemein', value: 'profiles' },
            ]}
            sx={{ marginBottom: 20 }}
          />
        </Center>
      )}

      <div>
        {selectedEntityType === 'events' && (
          <div style={{ marginBottom: 10 }}>
            <EventSearchAutocomplete
              required
              clearable
              selectEvent={(e) => {
                setSelectedEventId(e.id.toString());
              }}
              initialEventId={selectedEventId}
            />
          </div>
        )}

        <TextInput
          onChange={(e: any) => {
            setName(e.target.value);
          }}
          value={name}
          description="Titel der Aufgabe"
          sx={{ marginBottom: 10 }}
        />

        <DatePicker onChange={setDueDate} value={dueDate} description="Erledigen bis" sx={{ marginBottom: 10 }} />
        <Textarea
          value={notes ?? undefined}
          onChange={(e: any) => {
            setNotes(e.target.value);
          }}
          description="Beschreibung der Aufgabe"
          sx={{ marginBottom: 10 }}
          autosize
          minRows={5}
          maxRows={10}
        />
        <div style={{ marginBottom: 20 }}>
          <TaskGroupSelection
            required
            clearable
            entityType={selectedEntityType}
            initialGroupName={group?.name}
            onGroupNameChanged={setGroupName}
            onGroupIdChanged={setGroupId}
          />
        </div>
        <Checkbox
          label="Als wichtige Aufgabe markieren"
          checked={priority}
          onChange={(e: any) => setPriority(e.currentTarget.checked)}
        />
      </div>

      <ModalFooter>
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{ flex: 1 }}>
            <TransButtonLink style={{ color: 'red' }} text="Aufgabe löschen" onClick={onDeleteClick} />
          </div>
          <div>
            <TransButtonPrimary
              isLoading={loading}
              disabled={loading}
              onClickHandler={update}
              text="Aufgabe aktualisieren"
            />
          </div>
        </div>
      </ModalFooter>
    </>
  );
};

export default TaskEditModal;
