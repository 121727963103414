import { NodeViewRendererProps } from '@tiptap/core';

interface NodeAttributes {
  id: string;
  label: string;
}

const SettingsPlaceholder = (props: NodeViewRendererProps) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { label } = props.node.attrs as NodeAttributes;
  return (
    // Set data-node-view-wrapper as empty string to prevent usage of default block Wrapper element
    <span data-node-view-wrapper="" className="mention">
      {label}
    </span>
  );
};

export default SettingsPlaceholder;
