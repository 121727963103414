import { forwardRef } from 'react';
import { Text, SelectItemProps } from '@mantine/core';
import Moment from 'react-moment';
import EventDate from '../../Types/EventDate';
import Contact from '../../Types/Contact';
import BookingState from '../../Types/Enums/BookingState';
import { EventS } from '../../Service/EventS';
import EventItemBox from '../EventItemStateBox';

interface EventItemProps extends SelectItemProps {
  customer: Contact;
  bookingState: BookingState;
  kind?: string;
  dates: EventDate[];
}

const AutoCompleteEventItem = forwardRef<HTMLDivElement, EventItemProps>(
  ({ customer, bookingState, kind, dates, ...rest }: EventItemProps, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <EventItemBox ref={ref} bookingState={bookingState} {...rest}>
      <Text sx={{ fontSize: 8 }} color="dimmed">
        {EventS.translateBookingState(bookingState)}
      </Text>
      <Text inline size="xs" sx={{ paddingBottom: 3, paddingTop: 3 }}>
        {customer.personDescription || customer.email}
      </Text>
      {dates.length > 0 && (
        <Text sx={{ fontSize: 10 }} color="dimmed">
          {EventS.getKindDescription(kind)} am <Moment format="DD.MM.YYYY">{dates[0].date}</Moment>
        </Text>
      )}
    </EventItemBox>
  ),
);

export default AutoCompleteEventItem;
