import { Contact } from './ContactS';

const AWS_SPECIAL_CHARS_REGEX = /["/\\]/;
const TEMPLATE_NAME_SPECIAL_CHARS = /[/?#:%&+]/;

const validAwsObjectKeyName = (str: string) => !AWS_SPECIAL_CHARS_REGEX.test(str);

const validateAwsContactProps = (contact: Partial<Contact>) => {
  const { firstName, lastName } = contact;
  if (firstName && !validAwsObjectKeyName(firstName)) {
    return false;
  }
  if (lastName && !validAwsObjectKeyName(lastName)) {
    return false;
  }
  return true;
};

const validatePortalPassword = (str: string) => !/[#&]/g.test(str);

const validateTemplateName = (str: string) => !TEMPLATE_NAME_SPECIAL_CHARS.test(str);

// eslint-disable-next-line import/prefer-default-export
export const ValidationS = {
  validAwsObjectKeyName,
  validateAwsContactProps,
  validatePortalPassword,
  validateTemplateName,
};
