/* eslint-disable react/jsx-props-no-spreading */
import { FC, useState } from 'react';
import { useForm } from '@mantine/form';
import { DatePicker } from '@mantine/dates';
import { Button, Group, Loader, Select, Text, TextInput } from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { IconCalendar } from '@tabler/icons';
import { i18n } from '@lingui/core';
import { EventDateS } from '../../Service/EventDateS';
import ModalPrimaryButton from '../../Atoms/buttons/ModalPrimaryButton';
import { BaseBookitupTimeInput } from '../../Atoms/inputs/BookitupTimeInput';
import { EventS } from '../../Service/EventS';
import { EventDate, EventDateType } from '../../Types/Event';
import { toUTC } from '../../Utils/dateUtils';
import { DataS } from '../../Service/DataS';
import AutocompleteResourceTypeItem from "../../Organismns/Resources/table/AutocompleteResourceTypeItem";
import BookitupSelect from "../../Atoms/BookitupSelect";

interface CreateModalProps {
  eventId: string;
  onCreate: (eventDate: EventDate) => void;
}

export const openCreateEventDateModal = (props: CreateModalProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Termin hinzufügen
      </Text>
    ),
    children: <CreateModal {...props} />,
  });

type FormValues = {
  date?: Date;
  timeFrom?: string;
  timeTo?: string;
  description?: string;
  subject?: string;
  type: EventDateType;
};

const CreateModal: FC<CreateModalProps> = ({ eventId, onCreate }) => {
  const [loading, setLoading] = useState(false);

  const form = useForm<FormValues>({
    initialValues: {
      date: undefined,
      timeFrom: undefined,
      timeTo: undefined,
      description: undefined,
      subject: undefined,
      type: 'REGULAR_DATE',
    },
    validate: {
      date: (value) => (value ? null : 'Bitte Datum eingeben'),
      // subject: (value) => (type === 'APPOINTMENT' && (!value || value === '') ? 'Bitte Betreff eingeben' : null),
    },
  });

  const { type } = form.values;

  const onSubmit = async () => {
    setLoading(true);
    const eventDate = await EventDateS.create(eventId, { ...form.values, date: toUTC(form.values.date as Date) });
    if (eventDate) {
      setLoading(false);
      onCreate(eventDate);
      closeAllModals();
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <BookitupSelect
        mb="xs"
        label="Termin-Typ"
        sx={{marginTop: "0px !important"}}
        variant={"default"}
        data={DataS.eventDateTypeOpts}
        {...form.getInputProps('type')}
        itemComponent={AutocompleteResourceTypeItem}
      />
      <DatePicker
        minDate={new Date()}
        label="Datum"
        icon={<IconCalendar size={20} />}
        {...form.getInputProps('date')}
      />
      <Group grow spacing="xs" mt={5} mb="sm">
        <BaseBookitupTimeInput
          label="von"
          value={form.values.timeFrom ?? undefined}
          onUpdate={(val) => form.setValues({ timeFrom: val })}
        />
        <BaseBookitupTimeInput
          label="bis"
          value={form.values.timeTo ?? undefined}
          onUpdate={(val) => form.setValues({ timeTo: val })}
        />

      </Group>
      {type === 'APPOINTMENT' && <TextInput label="Beschreibung" mb="sm" {...form.getInputProps('subject')} />}
      {type === 'REGULAR_DATE' && <TextInput mb="sm" label="Beschreibung" {...form.getInputProps('description')} />}
      <ModalPrimaryButton
        text="actions.save"
        type="submit"
        disabled={loading}
        rightIcon={loading && <Loader size="xs" />}
      />
    </form>
  );
};

interface DeleteModalProps {
  eventDate: EventDate;
  onDelete: () => void;
  interceptViolation: (httpResponse: Response) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  type?: EventDateType;
}

export const openDeleteEventDateModal = (props: DeleteModalProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        {props.type ? 'Termin löschen' : 'Veranstaltungsdatum löschen'}
      </Text>
    ),
    children: <DeleteModal {...props} />,
  });

const DeleteModal: FC<DeleteModalProps> = ({ eventDate, onDelete, interceptViolation }) => {
  const [loading, setLoading] = useState(false);
  const { id, date } = eventDate;

  const onConfirm = async () => {
    setLoading(true);
    const deleted = await EventDateS.remove(id, interceptViolation);
    setLoading(false);
    if (deleted) {
      onDelete();
      closeAllModals();
    }
  };

  return (
    <>
      <Text>
        Möchten Sie das folgende Datum am{' '}
        <Text span weight="bolder">
          {EventS.formatEventDate(new Date(date))}
        </Text>{' '}
        wirklich entfernen?
      </Text>
      <Group position="right" spacing="xs" mt="xl">
        <Button variant="default" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button rightIcon={loading && <Loader size="xs" />} color="red" disabled={loading} onClick={onConfirm}>
          {i18n._('actions.delete')}
        </Button>
      </Group>
    </>
  );
};
