import { ContractTemplateDto, TextBlockDto } from '../Types/DocumentT';
import { ajaxActions } from './AjaxActions';
import { ConstantS } from './ConstantS';
import { MixpanelS } from './MixpanelS';
import { ToastS } from './ToastS';

const BASE_URL = `${process.env.REACT_APP_DOCUMENT_SERVICE_URL}`;

const fetchTemplate = async (templateName: string): Promise<ContractTemplateDto | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/contracts/templates/${templateName}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const editTemplate = async (
  templateId: number,
  patch: Partial<ContractTemplateDto>,
): Promise<ContractTemplateDto | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/contracts/templates/${templateId}`, patch);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const createTemplate = async (templateName: string, existingContract?: File): Promise<ContractTemplateDto | null> => {
  if (existingContract) {
    MixpanelS.track(ConstantS.TrackingEvents.RequestedContractTemplateCreationWithOpenAI);
  }
  const res = await ajaxActions.uploadPutFile(`${BASE_URL}/contracts/templates/${templateName}`, existingContract);
  if (res.ok) {
    ToastS.info('contract-template-created', 'Vorlage endgültig erstellt');
    if (existingContract) {
      MixpanelS.track(ConstantS.TrackingEvents.RequestedContractTemplateCreationWithOpenAI);
    } else {
      MixpanelS.track(ConstantS.TrackingEvents.ContractTemplateCreated, { default: false });
    }
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const removeTextBlock = async (templateName: string, blockId: number): Promise<ContractTemplateDto | null> => {
  const res = await ajaxActions.del(`${BASE_URL}/textBlocks/${blockId}/contractTemplate/${templateName}`);
  if (res.ok) {
    ToastS.info('p-removed', 'Absatz entfernt');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const addBlockAbove = async (
  templateName: string,
  blockId?: number,
  blockDto?: Partial<TextBlockDto>,
): Promise<ContractTemplateDto | null> => {
  const res = await ajaxActions.put(
    `${BASE_URL}/contracts/templates/${templateName}/aboveTextBlocks${blockId ? `?afterTextBlock=${blockId}` : ''}`,
    blockDto,
  );
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const addBlockBelow = async (
  templateName: string,
  blockId?: number,
  blockDto?: Partial<TextBlockDto>,
): Promise<ContractTemplateDto | null> => {
  const res = await ajaxActions.put(
    `${BASE_URL}/contracts/templates/${templateName}/belowTextBlocks${blockId ? `?afterTextBlock=${blockId}` : ''}`,
    blockDto,
  );
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const insertPositions = async (templateName: string, blockId: number): Promise<ContractTemplateDto | null> => {
  const res = await ajaxActions.put(
    `${BASE_URL}/textBlocks/${blockId}/contractTemplate/${templateName}/insertPositions`,
  );
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const moveBlock = async (
  templateName: string,
  fromIndex: number,
  toIndex: number,
  fromBelow: boolean,
  toBelow: boolean,
): Promise<ContractTemplateDto | null> => {
  const res = await ajaxActions.patch(
    `${BASE_URL}/contracts/templates/${templateName}/move?fromIndex=${fromIndex}&toIndex=${toIndex}&fromBelow=${fromBelow}&toBelow=${toBelow}`,
    null,
  );
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const fetchTemplates = async (): Promise<ContractTemplateDto[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/contracts/templates/names`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const copyTemplate = async (templateName: string): Promise<ContractTemplateDto | null> => {
  const res = await ajaxActions.put(`${BASE_URL}/contracts/templates/${templateName}/copy`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const deleteTemplate = async (templateName: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/contracts/templates/${templateName}`);
  if (res.ok) {
    ToastS.info('contract-template-deleted', 'Vorlage entfernt');
    return true;
  }
  ToastS.generalError();
  return false;
};

// eslint-disable-next-line import/prefer-default-export
export const ContractTemplateS = {
  fetchTemplate,
  createTemplate,
  editTemplate,
  removeTextBlock,
  addBlockAbove,
  addBlockBelow,
  insertPositions,
  moveBlock,
  fetchTemplates,
  copyTemplate,
  deleteTemplate,
};
