import { useEffect, useState } from 'react';
import { Skeleton, Text } from '@mantine/core';
import { createMailOutboundConfig, fetchMailOutboundConfigs } from '../../../Service/restapi/mailService';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import PanePaper from '../../../Molecules/PanePaper';
import { RoutingS } from '../../../Service/RoutingS';
import MailOutBoundConfig from './MailOutBoundConfig';

const MailAccount = () => {
  const [mailOutboundConfigs, setMailInboundConfigs] = useState(undefined);

  useEffect(() => {
    RoutingS.changeTitle('Ausgangsserver');
    fetchConfigs();
  }, []);

  function fetchConfigs() {
    fetchMailOutboundConfigs().then((resp) => {
      if (resp.ok) {
        resp.json().then((mailOutBoundConfigs) => {
          setMailInboundConfigs(mailOutBoundConfigs);
        });
      }
    });
  }

  function createNewOutBoundConfig() {
    // fetching config without id, creates a new config
    createMailOutboundConfig().then((resp) => {
      if (resp.ok) {
        // ether get the returned config, or just reload configs
        fetchConfigs();
      }
    });
  }
  return (
    <PanePaper
      sx={{ maxWidth: 650 }}
      title="settings.mail.account.outbound"
      tooltip="help.settings.mail.account.outbound.pane"
      rightSection={
        mailOutboundConfigs &&
        mailOutboundConfigs.length === 0 && <BookitupAddIcon onClick={() => createNewOutBoundConfig()} />
      }
    >
      {!mailOutboundConfigs ? (
        <Skeleton height={50} width={500} />
      ) : (
        <>
          <Text mb="md">
            Hier kannst Du Deinen E-Mail-Account hinzufügen, damit E-Mails über Deinen E-Mail-Anbieter verschickt
            werden. Dadurch verringerst Du das Risiko, dass Deine E-Mails im Spam-Ordner Deiner Kunden landen.
          </Text>
          {mailOutboundConfigs.map((config) => (
            <MailOutBoundConfig key={config.id} id={config.id} />
          ))}
        </>
      )}
    </PanePaper>
  );
};

export default MailAccount;
