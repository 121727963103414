// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Paper, Space, Sx, Text, ThemeIcon, Title, Tooltip } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { IconQuestionMark } from '@tabler/icons';
import { FC, ReactNode } from 'react';

const PanePaper: FC<{
  title?: string;
  children: ReactNode;
  rightSection?: ReactNode;
  tooltip?: string;
  withShadow?: boolean;
  withoutBorder?: boolean;
  sx?: Sx;
}> = ({ title, children, rightSection, tooltip, withShadow = false, withoutBorder = false, sx }) => {
  const { i18n } = useLingui();
  return (
    <Paper
      radius={10}
      p="md"
      sx={(theme) => ({
        boxShadow: withShadow ? '0px 0px 8px #00000026' : 'none',
        border: withoutBorder ? 'none' : `1px solid ${theme.colors.gray[1]}`,
        ...sx,
      })}
    >
      <div
        style={{ position: 'relative', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 5 }}
      >
        {title && (
          <Text size="lg" weight="bolder">
            {i18n._(title)}
          </Text>
        )}
        {tooltip && (
          <Tooltip
            multiline
            withArrow
            color="black"
            position="bottom"
            label={i18n._(tooltip)}
            width={200}
            transition="scale-y"
          >
            <ThemeIcon size={14} sx={{ borderRadius: '50%', backgroundColor: '#D9D9D9' }}>
              <IconQuestionMark size={12} />
            </ThemeIcon>
          </Tooltip>
        )}
        {rightSection && <Box sx={{ position: 'absolute', right: 0, top: 0 }}>{rightSection}</Box>}
      </div>
      {title && <Space h="md" />}
      {children}
    </Paper>
  );
};

export default PanePaper;
