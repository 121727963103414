import React, { useEffect, useState } from 'react';
import { fetchEventById, patchEvent } from '../restapi/eventService';
import { ToastS } from '../ToastS';
import { LogS } from '../LogS';
import { EventDateS } from '../EventDateS';
import { DateUtils } from '../../Utils/dateUtils';

export const EventContext = React.createContext(undefined);

export const EventContextProvider = ({ eventFromProvider, children }) => {
  const [event, setEvent] = useState(eventFromProvider);
  const [dates, setDates] = useState(eventFromProvider.dates ?? []);
  const [loadingNotifications, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const { id: eventId } = event;

  const [taskReloadTrigger, setTrigger] = useState(new Date());

  useEffect(() => {
    setEvent((prev) => ({ ...prev, dates }));
  }, [dates]);

  const datesHandlers = {
    append: (eventDate) => setDates(DateUtils.sortEventDates([...dates, eventDate])),
    edit: (eventDate) => setDates(DateUtils.sortEventDates(dates.map((d) => (d.id === eventDate.id ? eventDate : d)))),
    remove: (id) => setDates(dates.filter((d) => d.id !== id)),
  };
  const [portalReloadTrigger, setPortalTrigger] = useState(new Date());

  const loadNotifications = () => {
    if (eventId) {
      setLoading(true);
      LogS.fetchLogbookNotifications(eventId)
        .then(setNotifications)
        .finally(() => setLoading(false));
    }
  };

  const assignPrimaryLocation = (locationId) => {
    EventDateS.assignPrimaryLocation(locationId, eventId).then((success) => {
      if (success) {
        setDates(dates.map((d) => ({ ...d, locationId })));
      }
    });
  };

  const assignPrimaryDate = (eventDateId) =>
    setDates(dates.map((ed) => ({ ...ed, useAsPrimary: ed.id === eventDateId })));

  const detachLocation = (locationId) =>
    setDates(dates.map((d) => (d.locationId === locationId ? { ...d, locationId: null } : d)));

  const removeNotification = (notification) => setNotifications(notifications.filter((n) => n.id !== notification.id));

  // Procedures executed recently by an automation
  const dispatchProcedures = (procedures) => {
    loadNotifications();
    if (procedures.includes('EVENT_STATUS_CHANGED')) {
      refreshEvent();
    }
    if (
      procedures.includes('CREATE_TASK') ||
      procedures.includes('DELETE_TASK') ||
      procedures.includes('DELETE_TASKS') ||
      procedures.includes('COMPLETE_TASK')
    ) {
      setTrigger(new Date());
    }
    if (procedures.includes('SHARE_ENTITY')) {
      setPortalTrigger(new Date());
    }
  };

  const refreshEvent = () => {
    fetchEventById(event.id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((event) => {
            setEvent(event);
          });
        } else {
          console.info(`could not load event ${event.id}`);
        }
      })
      .catch(() => {
        console.info(`could not load event ${event.id}`);
      });
  };

  useEffect(() => {
    loadNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  return (
    <EventContext.Provider
      value={{
        assignPrimaryDate,
        assignPrimaryLocation,
        detachLocation,
        portalReloadTrigger,
        datesHandlers,
        dates,
        taskReloadTrigger,
        dispatchProcedures,
        loadingNotifications,
        notifications,
        removeNotification,
        dispatchProcedures,
        event,
        handleChange: (patch) => {
          setEvent((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
        saveEvent: (patch) => {
          patchEvent(event.id, patch)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then(setEvent);
              } else {
                ToastS.error('patch.event', 'Fehler beim Speichern');
                console.error('Event could not be saved.');
              }
            })
            .catch(() => {
              ToastS.error('patch.event', 'Fehler beim Speichern');
            });
        },
        refreshEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};
