import { UseListStateHandlers } from '@mantine/hooks';

export interface DistanceDto {
  id: string;
  contactId: number;
  placeId: string;
  locationId: number;
  distanceInMeters: number;
  travelTimeInSeconds: number;
  lastModified: Date;
}

export interface LocationDto {
  id: number;
  lastModified: Date;
  profileId: number;
  name: string;
  streetAndNo: string;
  zipCode: string;
  city: string;
  contactId: number;
  phoneNumber: string;
  placeId: string;
  eventId: number;
  website: string | null;
  address: string;
  distance?: number | null;
  useAsPrimary: boolean;
}

export const EmptyLocation = {
  name: '',
  streetAndNo: '',
  zipCode: '',
  city: '',
  website: '',
  phoneNumber: '',
};

export interface EventLocationContextProps {
  locations: LocationDto[];
  primaryLocation?: LocationDto;
  loading: boolean;
  handlers: UseListStateHandlers<LocationDto>;
  markAsPrimary: (locationId: number) => void;
}
