import { Stack } from '@mantine/core';
import { useEffect } from 'react';
import { Icon } from '../../../Atoms/Icons';
import PanePaper from '../../../Molecules/PanePaper';
import { RoutingS } from '../../../Service/RoutingS';
import { SubscriptionCalendar } from './SubscriptionCalendar/SubscriptionCalendar';
import ExternalSubscriptions from './ExternalSubscriptions/ExternalSubscriptions';
import GoogleCalendarsPane from './google/GoogleCalendarsPane';
import BookitupTutorialIcon from "../../../Atoms/BookitupTutorialIcon";

const CalendarConfig = () => {
  const styles = {
    description: {
      color: '#666666',
      marginBottom: 20,
      fontSize: 12,
    },
  };

  useEffect(() => {
    RoutingS.changeTitle('Kalendar-Sync');
  }, []);

  return (
    <PanePaper title="calendar-sync" sx={{ maxWidth: 1000 }}>
      <div>
        <div style={styles.description}>
          Du möchtest Deine bookitup Events in Deinem Smartphone-Kalender sehen?
          <br />
          Füge hier Deinen Kalender hinzu, damit bookitup ihn für Dich automatisch aktualisiert.
          <br />
          Diese kannst Du auf Deinem Smartphone einfach hinzufügen - sei es ein iPhone, Android oder Windows Phone.
          <br />
          <br />
          Es werden alle Events (inkl. Location-Adresse und Notizen) eingetragen, welche im Status{' '}
          <Icon style={{ marginLeft: 2, marginRight: 2 }} value="OPEN" /> Anfrage{' '}
          <Icon style={{ marginLeft: 2, marginRight: 2 }} value="OFFERED" /> Option oder{' '}
          <Icon style={{ marginLeft: 2, marginRight: 2 }} value="BOOKED" /> Gebucht sind.
          <br />
          Wird ein Event z.B. <Icon style={{ marginLeft: 2, marginRight: 2 }} value="CANCELED" /> Annulliert, wird
          dieses auch wieder aus Deinem Kalender entfernt.
          <br />
        </div>

        <Stack>
          <PanePaper
            title="settings.calendar.subscription" tooltip="help.settings.calendar.subscription.pane"
            rightSection={
             <>
               <BookitupTutorialIcon videoUrl={"https://app.bookitup.de/academy/channels/perfect-overview?episodeId=mlqjdnri0n"} name={"AddCalendarAbo"} />
             </>
           }
          >
            <SubscriptionCalendar />
          </PanePaper>
          <ExternalSubscriptions />
          <GoogleCalendarsPane />
        </Stack>
      </div>
    </PanePaper>
  );
};

export default CalendarConfig;
