// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { AppShell, Aside, Box, Group, Navbar, Stack, Text } from '@mantine/core';
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { IconChevronsLeft, IconChevronsRight } from '@tabler/icons';
import { WindowContext } from '../../Service/Context/WindowContext';
import LocationPane from '../../Molecules/Event/Location/LocationPane';
import CustomerPane from '../../Organismns/Event/CustomerPane';
import EventTasksUpgrader from '../../Molecules/Event/Tasks/EventTasksUpgrader';
import EventCustomFieldsUpgrader from '../../Molecules/Event/CustomAttributes/EventCustomFieldsUpgrader';
import NotesList from '../../Molecules/Logs/NotesList';
import Recommendations from '../../Molecules/Event/Recommendations/RecommendationsPane';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import PageHeader from '../Navigation/PageHeader';
import { EventContext } from '../../Service/Context/EventContext';
import EventFormNavbar from './EventFormNavBar';

interface AsideBarContextInterface {
  isOpen: boolean;
  toggle: () => void;
}

export const AsideBarContext = createContext<AsideBarContextInterface>({} as AsideBarContextInterface);

const EventForm: FC = () => {
  const { sm, md } = useContext(WindowContext);
  const { dates } = useContext<any>(EventContext);
  const [navBarOpen, setNavBarOpen] = useState<boolean>(false);
  const [asideBarOpen, setAsideBarOpen] = useState<boolean>(false);
  const [asideBarCollapsed, setAsideBarCollapsed] = useState<boolean>(md);
  const { pathname } = useLocation();

  const value = useMemo(
    () => ({
      isOpen: !asideBarCollapsed,
      toggle: () => setAsideBarCollapsed(!asideBarCollapsed),
    }),
    [asideBarCollapsed],
  );

  useEffect(() => {
    if (navBarOpen && asideBarOpen) {
      setAsideBarOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navBarOpen]);

  return (
    <AppShell
      header={
        <PageHeader
          title={
            dates.length > 0 && (
              <Stack spacing={3}>
                <Text>Event am</Text>
                <Text size="xl" weight="bolder">{`${dates[0].weekday}, ${new Intl.DateTimeFormat('de').format(
                  new Date(dates[0].date),
                )}`}</Text>
              </Stack>
            )
          }
          height={120}
          navBarOpen={navBarOpen}
          setNavBarOpen={setNavBarOpen}
          rightSection={
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <Box mt={5}>
              {asideBarOpen ? (
                <IconChevronsRight onClick={() => setAsideBarOpen(false)} />
              ) : (
                <IconChevronsLeft onClick={() => setAsideBarOpen(true)} />
              )}
            </Box>
          }
        />
      }
      navbar={
        <div>
          {sm ? (
            <Navbar hiddenBreakpoint="sm" hidden={!navBarOpen} width={{ sm: 220, md: 250 }}>
              <EventFormNavbar closeNavBar={() => setNavBarOpen(false)} />
            </Navbar>
          ) : (
            <DoubleNavbar>
              <EventFormNavbar closeNavBar={() => setNavBarOpen(false)} />
            </DoubleNavbar>
          )}
        </div>
      }
      aside={
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {(!sm || asideBarOpen) && (
            <Aside
              p="md"
              hiddenBreakpoint="md"
              width={{ sm: asideBarCollapsed ? 60 : 275, lg: asideBarCollapsed ? 60 : 350 }}
              sx={{ overflow: 'auto' }}
            >
              {!sm && (
                <div style={{ cursor: 'pointer' }}>
                  {asideBarCollapsed ? (
                    <IconChevronsLeft onClick={() => setAsideBarCollapsed(false)} style={{ cursor: 'pointer' }} />
                  ) : (
                    <Group position="right" mb={10}>
                      <IconChevronsRight onClick={() => setAsideBarCollapsed(true)} style={{ cursor: 'pointer' }} />
                    </Group>
                  )}
                </div>
              )}
              {(!asideBarCollapsed || asideBarOpen) && (
                <Stack mb={70}>
                  <CustomerPane />
                  <Recommendations />
                  <LocationPane />
                  <EventTasksUpgrader />
                  {!pathname.endsWith('/details') && (
                    <>
                      <NotesList asideBar />
                      <EventCustomFieldsUpgrader asideBar />
                    </>
                  )}
                  {/* // TODO: Add later */}
                  {/* <EventResourcePane /> */}
                </Stack>
              )}
            </Aside>
          )}
        </>
      }
    >
      <AsideBarContext.Provider value={value}>
        <Outlet />
      </AsideBarContext.Provider>
    </AppShell>
  );
};

export default EventForm;
