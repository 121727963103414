import { addDays as addD, subDays } from 'date-fns/fp';
import today from 'date-fns/isToday';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import startOfToday from 'date-fns/startOfToday';
import parseISO from 'date-fns/parseISO';
import startOWeek from 'date-fns/startOfWeek';
import endOfMonth from 'date-fns/endOfMonth';
import endOfYear from 'date-fns/endOfYear';
import subMilliseconds from 'date-fns/subMilliseconds';

import { addYears, differenceInDays } from 'date-fns';
import BookingEvent, { BookingEventDateItem, EventDate } from '../Types/Event';
import moment from 'moment';

export const subtractDays = (numOfDays: number, date = new Date()) => subDays(numOfDays, date);

export const addDays = (numOfDays: number, date = new Date()) => addD(numOfDays, date);

export const startOfWeek = (date = new Date()) => startOWeek(date);

export const lastMidnight = (): Date => startOfToday();

export const lastDateBeforeNextMidnight = (): Date => subMilliseconds(startOfTomorrow(), 1);

export const nextMidnight = (): Date => startOfTomorrow();

export const isToday = (date: Date): boolean => today(date);

export const isBeforeToday = (checked: Date) => checked < lastMidnight();

export const isAfterToday = (checked: Date) => nextMidnight() < checked;

export const parseDate = (date: string) => parseISO(date);

export const parseISOLocal = (s: string) => {
  const b = s.split(/\D/);
  return new Date(
    parseInt(b[0], 10),
    parseInt(b[1], 10) - 1,
    parseInt(b[2], 10),
    parseInt(b[3], 10),
    parseInt(b[4], 10),
    parseInt(b[5], 10),
  );
};

export const isNDaysOrLessAfterToday = (n: number, date: Date) => {
  const d = differenceInDays(date, startOfToday());
  return d > 0 && d <= n;
};

export const endOfThisMonth = (date = new Date()) => endOfMonth(date);

export const endOfThisYear = (date = new Date()) => endOfYear(date);

export const toUTC = (date: Date): Date => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

export const isSameDay = (date1: Date, date2: Date): boolean => {
  // Extract day, month, and year from both dates
  const day1 = date1.getDate();
  const month1 = date1.getMonth();
  const year1 = date1.getFullYear();
  const day2 = date2.getDate();
  const month2 = date2.getMonth();
  const year2 = date2.getFullYear();

  // Compare day, month, and year values
  return day1 === day2 && month1 === month2 && year1 === year2;
};

type Interval = [Date, Date];

export const getEventInterval = (event: BookingEvent): Interval => {
  const { dates } = event;
  if (dates.length === 0) {
    return [undefined, undefined] as any;
  }
  const { start, end } = dates[0];
  return [new Date(start), new Date(end)];
};

export const getEventDateInterval = (dateItem: BookingEventDateItem): Interval => {
  const { start, end } = dateItem;
  return [new Date(start), new Date(end)];
};

function removeUTCOffsetFromDate(date: Date) {
  const dateStringWithoutOffset = date.toISOString().split('T')[0];
  return dateStringWithoutOffset;
}

type TimeInterval = [Date | undefined, Date | undefined];

const mergeInterval = (interval: TimeInterval): Date => {
  const [d1, d2] = interval;
  if (!d1 || !d2) {
    return new Date();
  }
  return new Date(Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate(), d2.getHours(), d2.getMinutes()));
};

const BookitupStartOfResources = new Date(2023, 11, 1);
const MaxFilterDate = addYears(new Date(), 3);
const TimelineDefaultFilterStart = addDays(-2, new Date());
const TimelineDefaultFilterEnd = addYears(new Date(), 3);

const getDurationInDays = (startDate: Date, endDate: Date) => {
  // Calculate the time difference in milliseconds
  const timeDifferenceMs = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to days
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const durationInDays = Math.floor(timeDifferenceMs / millisecondsPerDay);

  return durationInDays;
};

const sortDates = (dates: Date[]) =>
  dates.sort((a, b) => {
    return a.getTime() - b.getTime();
  });

const sortEventDates = (eventDates: EventDate[]) => {
  const eventDateToDate = (eventDate: EventDate) => {
    const { date, timeFrom } = eventDate;
    const d = new Date(date);
    if (timeFrom) {
      const [hours, minutes] = timeFrom.split(':').map(Number);
      d.setHours(hours);
      d.setMinutes(minutes);
    }
    return d;
  };
  return eventDates.sort((a, b) => {
    const dateA = eventDateToDate(a);
    const dateB = eventDateToDate(b);
    return dateA.getTime() - dateB.getTime();
  });
};

export const daysBetween = (d1: Date, d2: Date) => Math.floor((d2.getTime() - d1.getTime()) / 86400000);

export const DateUtils = {
  mergeInterval,
  BookitupStartOfResources,
  MaxFilterDate,
  TimelineDefaultFilterStart,
  TimelineDefaultFilterEnd,
  getDurationInDays,
  sortDates,
  sortEventDates,
};
