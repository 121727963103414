import { i18n } from '@lingui/core';
import { useEventData } from '../Context/CustomerContext';
import { getPlaceholderValue } from '../Placeholder/PlaceholderResover';

// eslint-disable-next-line import/prefer-default-export
export const usePlaceholderValue = (placeholderId: string) => {
  const { event, customer, location: eventLocation, customAttributeValues } = useEventData();
  return getPlaceholderValue(placeholderId, i18n, {
    event,
    customer,
    eventLocation,
    customAttributeValues,
  });
};
