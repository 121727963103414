import { i18n } from '@lingui/core';
import { Button, ButtonProps, Center } from '@mantine/core';
import { FC } from 'react';

interface Props extends ButtonProps {
  text: string;
}

const ModalPrimaryButton: FC<Props> = ({ text, ...rest }) => (
  <Center mt={30}>
    <Button {...rest} sx={{ width: 250 }}>
      {i18n._(text)}
    </Button>
  </Center>
);

export default ModalPrimaryButton;
