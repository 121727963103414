import { useState } from 'react';
import { Checkbox, Divider, Group, NumberInput, Paper, Select, Space, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';
import { EditableDocumentPositionProperty } from '../../../Atoms/EditableProperties/EditableDocumentPositionProperty';
import { EditableAutocomplete } from '../../../Atoms/inputs/EditableAutocomplete';
import { HtmlPlaceholderFormatter } from '../../../Atoms/Formatter/HtmlPlaceholderFormatter';
import { HtmlFormatter } from '../../../Atoms/Formatter/HtmlFormatter';
import { EditableDivInput } from '../../../Atoms/inputs/EditableDivInput';
import { translate } from '../../../Service/PlaceholderTranslationService';
import { EditableFavoritePositionProperty } from '../../../Atoms/EditableProperties/EditableFavoritePositionProperty';
import { AutocompleteListTopComponent } from '../../../Atoms/AutocompleteListTopComponent';
import { GrossNetEditableText } from '../../../Atoms/inputs/GrossNetEditableText';
import { NetGrossFormatter } from '../../../Atoms/Formatter/NetGrossFormatter';
import { EditableAutocompleteWithPlaceholderCollection } from '../../../Atoms/inputs/EditableAutocompleteWithPlaceholderCollection';
import { DataS } from '../../../Service/DataS';
import { patchFavoritePosition, patchPosition } from '../../../Service/restapi/positionService';
import { FormatS } from '../../../Service/FormatS';
import PositionSearchListItem from './PositionSearchListItem';
import BookitupSegmentedControl from '../../../Atoms/BookitupSegmentedControl';

const PositionModal = ({
  i18n,
  index,
  position: initialPosition,
  changePosition,
  _document,
  isFavoritePosition,
  favorites,
  customer,
  event,
  eventLocation,
  customAttributeValues,
  settings,
  onPlaceholderClicked,
  profile,
  executeWithLicenceCheck,
  distance,
}) => {
  const [position, setPosition] = useState(initialPosition);
  const isPartialInvoice = _document && _document.documentType === 'PARTIAL_INVOICE';
  const taxType = _document ? _document.taxType : 'gross';
  const { amount, priceUnit, discount, price, id: positionId, optional, autoSelect } = position;
  const sumWithoutDiscount = parseInt(amount, 10) * priceUnit;

  let EditablePositionProperty = EditableDocumentPositionProperty;
  if (isFavoritePosition) {
    EditablePositionProperty = EditableFavoritePositionProperty;
  }

  const patchPositionField = (_index, patch) => {
    if (isFavoritePosition) {
      patchFavoritePosition(positionId, patch).then((resp) => {
        if (resp.ok) {
          resp.json().then((patchedPosition) => {
            if (patchedPosition) {
              change(_index, patchedPosition);
            }
          });
        }
      });
    } else {
      patchPosition(positionId, patch).then((resp) => {
        if (resp.ok) {
          resp.json().then((patchedPosition) => {
            if (patchedPosition) {
              change(_index, patchedPosition);
            }
          });
        }
      });
    }
  };

  function change(index, position) {
    setPosition(position);
    if (typeof changePosition === 'function') {
      changePosition(index, position);
    }
  }
  const patchDiscountField = (_index, patch) => {
    if (isFavoritePosition) {
      patchFavoritePosition(positionId, patch).then((resp) => {
        if (resp.ok) {
          resp.json().then((patchedPosition) => {
            if (patchedPosition) {
              change(_index, patchedPosition);
            }
          });
        }
      });
    } else {
      patchPosition(positionId, patch).then((resp) => {
        if (resp.ok) {
          resp.json().then((patchedPosition) => {
            if (patchedPosition) {
              change(_index, patchedPosition);
            }
          });
        }
      });
    }
  };

  function preChange(patch) {
    console.log('preChange', patch);
    if (patch.amount) {
      patch.amount = patch.amount.replace(/(<([^>]+)>)/gi, '');
    }
    if (patch.amount === '{location_distance}') {
      if (!settings) {
        if (distance && distance.distanceInMeters) {
          patch.amount = `${Math.round(distance.distanceInMeters / 1000)}`;
          patch.unit = 'km';
        } else {
          patch.amount = null;
        }
      }
    } else if (patch.amount === '{event_hours}') {
      if (!settings) {
        if (event && event.dates.length > 0 && event.dates[0].hours) {
          patch.amount = `${event.dates[0].hours}`;
          patch.unit = 'Std.';
        } else {
          patch.amount = null;
        }
      }
    }
    for (const obj in patch) {
      patch[obj] = translate.toEnglish(patch[obj], i18n);
    }
    return patch;
  }

  return (
    <>
      <EditablePositionProperty
        index={index}
        preChange={preChange}
        data-testid="0-label"
        name="label"
        patchField="label"
        changeHandler={(value) => {
          change(index, value);
        }}
        entity={position}
        suggestions={favorites}
        editFormatter={EditableAutocompleteWithPlaceholderCollection}
        labelFormatter={HtmlPlaceholderFormatter}
        listValueComponent={PositionSearchListItem}
        listTopComponent={<AutocompleteListTopComponent text="Position aus Favoriten auswählen" />}
        customer={customer}
        _document={_document}
        event={event}
        eventLocation={eventLocation}
        customAttributeValues={customAttributeValues}
        settings={settings}
        placeholder="Beschreibung"
        executeWithLicenceCheck={executeWithLicenceCheck}
      />
      <EditablePositionProperty
        index={index}
        onPlaceholderClicked={onPlaceholderClicked}
        preChange={preChange}
        name="subtext"
        patchField="subtext"
        changeHandler={(value) => {
          change(index, value);
        }}
        entity={position}
        editFormatter={EditableDivInput}
        labelFormatter={HtmlPlaceholderFormatter}
        customer={customer}
        _document={_document}
        event={event}
        eventLocation={eventLocation}
        customAttributeValues={customAttributeValues}
        settings={settings}
        placeholder="Unterbeschreibung"
        overflowHidden
      />
      <Group spacing="xs">
        <EditablePositionProperty
          index={index}
          patchField="amount"
          preChange={preChange}
          changeHandler={(value) => {
            change(index, value);
          }}
          entity={position}
          settings
          suggestions={[{ amount: '{location_distance}' }, { amount: '{event_hours}' }]}
          editFormatter={EditableAutocomplete}
          labelFormatter={HtmlPlaceholderFormatter}
          disabled={isPartialInvoice}
          placeholder="Menge"
        />
        <EditablePositionProperty
          index={index}
          patchField="unit"
          changeHandler={(value) => {
            change(index, value);
          }}
          entity={position}
          suggestions={[{ unit: 'Stk.' }, { unit: 'km' }, { unit: 'Std.' }, { unit: 'h' }]}
          editFormatter={EditableAutocomplete}
          labelFormatter={HtmlFormatter}
          disabled={isPartialInvoice}
          placeholder="Einheit"
        />
        <EditablePositionProperty
          index={index}
          patchField="priceUnit"
          changeHandler={(value) => {
            change(index, value);
          }}
          entity={position}
          editFormatter={GrossNetEditableText}
          labelFormatter={NetGrossFormatter}
          taxType={taxType}
          tax={position.tax}
          disabled={isPartialInvoice}
          placeholder="Preis"
        />
        {!profile.smallScaleBusiness && (
          <Select
            label={
              <Text size="xs" color="dimmed">
                MwSt
              </Text>
            }
            value={position.tax}
            data={DataS.taxRateOptions}
            sx={{ width: 60 }}
            pt={5}
            onChange={(val) => patchPositionField(index, { tax: val })}
            styles={{ rightSection: { pointerEvents: 'none' } }}
            rightSection={<IconChevronDown size={14} />}
            rightSectionWidth={25}
          />
        )}
      </Group>
      {_document && _document.documentType === 'OFFER' && (
        <Checkbox
          mt="md"
          size="xs"
          label={
            <Text color="dimmed" size="xs">
              optional
            </Text>
          }
          checked={optional}
          onChange={() => patchPositionField(index, { optional: !optional })}
        />
      )}
      {optional && (
        <Checkbox
          mt="xs"
          size="xs"
          label={
            <Text color="dimmed" size="xs">
              Option vorausgewählt
            </Text>
          }
          checked={autoSelect}
          onChange={() => patchPositionField(index, { autoSelect: !autoSelect })}
        />
      )}
      <Space h="xl" />
      <Paper shadow="md" p="md">
        {!isPartialInvoice && (
          <>
            <Group position="apart">
              <Text color="dimmed" size="xs">
                Summe
              </Text>
              <Text>{FormatS.formatCurrencyWithUnitSign(sumWithoutDiscount.toString())}</Text>
            </Group>
            <Group position="apart" mt="xs">
              <Group spacing="xs">
                <Text color="dimmed" size="xs">
                  Rabatt:
                </Text>
                <NumberInput
                  hideControls
                  decimalSeparator=","
                  precision={2}
                  value={discount && discount.value}
                  sx={{ width: 60 }}
                  onBlur={(e) => {
                    const intVal = parseFloat(e.target.value.replace(',', '.'));
                    const finalVal = isNaN(intVal) || intVal < 0 ? 0 : intVal;
                    patchDiscountField(index, { discountValue: finalVal });
                  }}
                />
                <BookitupSegmentedControl
                  value={discount.type}
                  data={DataS.discountUnitOptions}
                  onChange={(val) => val && patchDiscountField(index, { discountType: val })}
                  sx={{ marginLeft: -10 }}
                />
              </Group>
              <Text>{`- ${FormatS.formatCurrencyWithUnitSign(discount.amount.toString())}`}</Text>
            </Group>
            <Divider mb="xs" mt="xs" />
          </>
        )}
        <Group position="apart">
          <Text>Preis:</Text>
          <Text weight="bolder">{FormatS.formatCurrencyWithUnitSign(price ? price.toString() : '0')}</Text>
        </Group>
      </Paper>
    </>
  );
};

export default PositionModal;
