import { MailOutBoundConfigDTO } from '../Types/MailT';
import { ajaxActions } from './AjaxActions';
import { ConstantS } from './ConstantS';
import { MixpanelS } from './MixpanelS';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_MAIL_SERVICE_URL;

export const fetchMailOutboundConfig = async (id: number): Promise<MailOutBoundConfigDTO | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/mailOutboundConfigs/${id}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

export const patchMailOutboundConfig = async (id: number, patch: Partial<MailOutBoundConfigDTO>) => {
  const res = await ajaxActions.patch(`${BASE_URL}/mailOutboundConfigs/${id}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

export const sendTestMail = async (id: number): Promise<boolean> => {
  const res = await ajaxActions.get(`${BASE_URL}/mailOutboundConfigs/${id}/testMail`);
  if (res.ok) {
    ToastS.success('send.mail', 'Test-E-Mail erfolgreich versendet');
    MixpanelS.track(ConstantS.TrackingEvents.MailAccountConnected);
    return true;
  }
  ToastS.error('send.mail', 'Überprüfe Deine E-Mail-Konfiguration');
  return false;
};

export const MailS = {
  fetchMailOutboundConfig,
  patchMailOutboundConfig,
  sendTestMail,
};
