import React, { useContext, useEffect, useState } from 'react';
import { withI18n } from '@lingui/react';
import { InputGroup } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TransButtonDefault,
  TransButtonDefaultWithIcon,
  TransButtonPrimary,
  TransButtonPrimaryWithIcon,
} from '../../Atoms/Buttons';
import { downloadDocumentContractQuestionnaire } from '../../Service/DocumentService';
import { downloadFile } from '../../Service/FileActions';
import LexofficeImportWrapper from '../Lexoffice/LexofficeImportWrapper';
import { fetchResourceByEntityTypeAndEntityId, fetchShares, newShare, share } from '../../Service/restapi/shareService';
import { ShareFlow1 } from '../../Organismns/Share/ShareFlow/ShareFlow-1';
import { ModalDialog, useConfirmModalState } from '../Dialogs';
import ClipboardButton from '../../Atoms/buttons/ClipboardButton';
import { WindowContext } from '../../Service/Context/WindowContext';
import DocumentFinishedWrapper from '../Document/DocumentFinishedWrapper';
import { CheckLicence } from '../../Organismns/Upgrade/CheckLicence';
import { ValidationS } from '../../Service/ValidationS';

export default withI18n()(
  ({ i18n, reloadFile, eventId, entityType, entityId, fileName, allowDownload, allowEmailAttachment }) => {
    const [resources, setResources] = useState();
    const [sharedUntil, setSharedUntil] = useState();
    const [loading, setLoading] = useState();
    const [name, setName] = useState(undefined);
    const [password, setPassword] = useState();
    const { showConfirm, toggleConfirm } = useConfirmModalState(false);
    const [shareId, setShareId] = useState(undefined);
    const { windowWidth } = useContext(WindowContext);
    const navigate = useNavigate();

    useEffect(() => {
      fetchResources();
      fetchShare();
    }, [entityType, entityId]);

    function fetchResources() {
      fetchResourceByEntityTypeAndEntityId(entityType, entityId).then((resp) => {
        if (resp.ok) {
          resp.json().then((res) => {
            console.log({ res });
            setResources(res);
          });
        }
      });
    }

    function fetchShare() {
      if (eventId) {
        fetchShares('events', eventId).then((resp) => {
          if (resp.ok) {
            resp.json().then((shares) => {
              if (shares.length > 0) {
                setShareId(shares[0].id);
              }
            });
          }
        });
      }
    }

    function downloadDocument() {
      if (entityType === 'contracts' || entityType === 'documents' || entityType === 'questionnaires') {
        downloadDocumentContractQuestionnaire(entityType, entityId, i18n);
      } else {
        downloadFile(entityType, entityId, fileName);
      }
    }

    const styles = {
      buttons: {
        marginBottom: 5,
      },
      emailButton: {
        marginRight: 5,
        width: windowWidth > 800 ? 200 : 50,
      },
      downloadButton: {
        marginRight: 5,
        width: windowWidth > 800 ? 200 : 50,
      },
      portalButton: {
        width: 200,
      },
    };

    function handleShare() {
      const shares = [
        {
          entityType,
          entityId,
          label: i18n._(`resource.entityType.${entityType.toLowerCase()}`),
        },
      ];

      if (shareId) {
        share(shareId, { entityTypeAndId: shares })
          .then((resp) => {
            if (resp.ok) {
            } else {
              console.error('could not share some document/questionnaire');
            }
          })
          .catch((error) => {
            console.error('could not share some document/questionnaire');
            console.error({ error });
          })
          .finally(() => {
            fetchResources();
            setLoading(false);
          });
      } else {
        newShare(name, password, sharedUntil, eventId).then((resp) => {
          if (resp.ok) {
            resp.json().then((s) => {
              share(s.id, { entityTypeAndId: shares })
                .then((resp) => {
                  if (resp.ok) {
                  } else {
                    console.error('could not share some document/questionnaire');
                  }
                })
                .catch((error) => {
                  console.error('could not share some document/questionnaire');
                  console.error({ error });
                })
                .finally(() => {
                  toggleConfirm();
                  fetchResources();
                  setLoading(false);
                });
            });
          }
        });
      }
    }

    return (
      <DocumentFinishedWrapper entityId={entityId} entityType={entityType}>
        <LexofficeImportWrapper
          entityId={entityId}
          entityType={entityType}
          onSuccess={() => {
            reloadFile();
            if (window.location.pathname.includes(`/events/${eventId}/documents/${entityId}`)) {
              if (eventId) {
                navigate(`/events/${eventId}/documents`);
              } else {
                navigate('/documents');
              }
            }
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {allowDownload && entityType && entityId && (
              <TransButtonDefaultWithIcon
                style={styles.downloadButton}
                onClickHandler={() => {
                  downloadDocument();
                }}
                icon="DOWNLOAD"
                text={windowWidth > 800 ? 'actions.download' : ''}
              />
            )}

            {eventId && resources && resources.length > 0 && (
              <InputGroup>
                <ClipboardButton buttonText="Portal-Link kopieren" text={resources[0].digitalSignLink} />
              </InputGroup>
            )}

            {eventId &&
              shareId &&
              (entityType === 'documents' || entityType === 'contracts' || entityType === 'questionnaires') &&
              (!resources || resources.length === 0) && (
                <CheckLicence
                  buttonComponent={TransButtonDefaultWithIcon}
                  buttonText="Online freigeben"
                  icon="USER_EDIT"
                  style={styles.portalButton}
                  onClick={handleShare}
                  licence="INTERACTIVE"
                  upgradeTitle="Lass den Papierkram hinter Dir"
                  upgradeText={
                    'Mit dem Interactive-Modul kannst Du Deinem Kunden Fragebögen erstellen und freigeben, \nVerträge mit digitaler Unterschrift bereitstellen und Dein Angebot zur Bestätigung bereitstellen.\n'
                  }
                />
              )}

            {eventId &&
              !shareId &&
              (entityType === 'documents' || entityType === 'contracts' || entityType === 'questionnaires') &&
              (!resources || resources.length === 0) && (
                <CheckLicence
                  buttonComponent={TransButtonDefaultWithIcon}
                  buttonText="Online freigeben"
                  icon="USER_EDIT"
                  style={styles.portalButton}
                  onClick={toggleConfirm}
                  licence="INTERACTIVE"
                  upgradeTitle="Lass den Papierkram hinter Dir"
                  upgradeText={
                    'Mit dem Interactive-Modul kannst Du Deinem Kunden Fragebögen erstellen und freigeben, \nVerträge mit digitaler Unterschrift bereitstellen und Dein Angebot zur Bestätigung bereitstellen.\n'
                  }
                />
              )}

            <ModalDialog
              header="Portal-Zugang erstellen"
              body={
                <ShareFlow1
                  name={name}
                  setName={setName}
                  password={password}
                  setPassword={setPassword}
                  sharedUntil={sharedUntil}
                  setSharedUntil={setSharedUntil}
                />
              }
              footer={
                <>
                  <TransButtonDefault onClickHandler={toggleConfirm} text="actions.cancel" />
                  <TransButtonPrimary disabled={!ValidationS.validatePortalPassword(password)}  onClickHandler={handleShare} text="actions.save" />
                </>
              }
              show={showConfirm}
              toggle={toggleConfirm}
            />
          </div>
        </LexofficeImportWrapper>
      </DocumentFinishedWrapper>
    );
  },
);
