import React, { useContext, useEffect, useState } from 'react';
import { useListState } from '@mantine/hooks';
import { patchLocation } from '../restapi/locationService';
import { ToastS } from '../ToastS';
import { LocationS } from '../LocationS';
import { ContactContext } from './ContactContext';
import EventParticipationsContextProviderProxy from './proxy/EventParticipationContextProviderProxy';

export const EventLocationContext = React.createContext(undefined);

export const EventLocationContextProvider = ({ event }) => {
  const [locations, handlers] = useListState([]);
  const { setState: setLocations, setItem } = handlers;
  const [primaryLocation, setPrimaryLocation] = useState();
  const { contact } = useContext(ContactContext);
  const { id: eventId } = event;
  const { id: contactId } = contact;

  const [loading, setLoading] = useState(false);
  const [distance, setDistance] = useState();

  useEffect(() => {
    if (locations.length > 0) {
      const primary = locations.find((l) => l.useAsPrimary);
      if (primary) {
        if (LocationS.isEmptyLocation(primary)) {
          setPrimaryLocation(primary);
        } else {
          LocationS.calculateDistance(contactId, primary.id).then((_distance) => {
            setPrimaryLocation({ ...primary, distance: LocationS.getDistanceInKms(_distance) });
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  useEffect(() => {
    if (eventId) {
      setLoading(true);
      LocationS.fetchAllByEventId(Number(eventId))
        .then(setLocations)
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  useEffect(() => {
    if (primaryLocation && distance && LocationS.isEmptyLocation(primaryLocation)) {
      setDistance(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryLocation, contactId]);

  const refreshDistance = (_location) => {
    const { id: locationId } = _location;
    LocationS.calculateDistance(contactId, locationId).then((_distance) => {
      if (_distance) {
        setDistance(_distance);
        // Update location property for placeholder values
        setPrimaryLocation({ ..._location, distance: LocationS.getDistanceInKms(_distance) });
      }
    });
  };

  const handleChange = (idx, location) => {
    setItem(idx, location);
    if (location.useAsPrimary) {
      refreshDistance();
    }
  };

  const markAsPrimary = async (locationId) => {
    const edited = await LocationS.editLocation(locationId, { useAsPrimary: true });
    if (edited) {
      const newLocations = locations.map((loc) => {
        loc.useAsPrimary = locationId === loc.id;
        return loc;
      });
      const newPrimary = newLocations.find((loc) => loc.useAsPrimary);
      setPrimaryLocation(newPrimary);
      setLocations(newLocations);
    }
  };

  return (
    <EventLocationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        locations,
        handlers,
        location: primaryLocation,
        distance,
        loading,
        handleChange,
        markAsPrimary,
        handleLocationChange: (patch) => {
          setPrimaryLocation((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
        saveLocation: (patch) => {
          setPrimaryLocation((prevState) => ({
            ...prevState,
            ...patch,
          }));
          patchLocation(eventId, primaryLocation.id, patch)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((savedLocation) => {
                  setPrimaryLocation(savedLocation);
                });
              } else {
                ToastS.error('patch.location', 'Fehler beim Speichern');
                console.error('Location could not be saved.');
              }
            })
            .catch(() => {
              ToastS.error('patch.location', 'Fehler beim Speichern');
            });
        },
      }}
    >
      <EventParticipationsContextProviderProxy />
    </EventLocationContext.Provider>
  );
};
