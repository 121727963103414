// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import moment from 'moment';
import { FC } from 'react';
import { RoutingS } from '../../../../Service/RoutingS';
import { BookitupNotification, OccurrenceType } from '../../../../Types/LogT';

const ConditionNotFulfilled: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { value, personDescription, eventDate, eventId } = notification;

  const eventNode = () => (
    <Text
      span
      weight="bolder"
      variant="link"
      color="blue"
      onClick={() => RoutingS.openInNewTab(`/events/${eventId}/details`)}
      sx={{ cursor: 'pointer' }}
    >
      Event
    </Text>
  );

  const eventDateNode = () => (
    <Text span weight="bolder">
      {` ${moment(eventDate).format('LL')} `}
    </Text>
  );

  const getEndOfTheMessage = (type: OccurrenceType) => {
    if (type === 'CONTRACT_SIGNED') {
      if (eventDate) {
        return <Text span>den Vertrag für das am {eventDateNode()} nicht unterzeichnet.</Text>;
      }
      return <Text>den Vertrag zum {eventNode()} nicht unterzeichnet.</Text>;
    }
    if (type === 'OFFER_CONFIRMED') {
      if (eventDate) {
        return (
          <Text span>
            das Angebot für das {eventNode()} am {eventDateNode()} nicht angenommen.
          </Text>
        );
      }
      return <Text>das Angebot zum {eventNode()} nicht angenommen.</Text>;
    }
    if (type === 'QUESTIONNAIRE_FILLED') {
      if (eventDate) {
        return (
          <Text span>
            den Fragebogen für das {eventNode()} am {eventDateNode()} nicht ausgefüllt.
          </Text>
        );
      }
      return <Text>den Fragebogen zum {eventNode()} nicht ausgefüllt.</Text>;
    }
    return null;
  };

  return (
    <Text>
      Workflow abgebrochen: Kunde{'  '}
      <Text span weight="bolder">
        {personDescription}
      </Text>
      {'  '}hat{'  '}
      {getEndOfTheMessage(value as OccurrenceType)}
    </Text>
  );
};

export default ConditionNotFulfilled;
