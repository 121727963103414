// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Avatar, Group, LoadingOverlay, PasswordInput, Text } from '@mantine/core';
import { IconCircleKey, IconExternalLink } from '@tabler/icons';
import PanePaper from '../../../Molecules/PanePaper';
import { useSevdeskConfig } from '../../../Service/hooks/useSevdeskConfig';

const SevdeskConfigPane = () => {
  const URL = 'https://my.sevdesk.de/admin/userManagement';
  const { loading, config, updateConfig } = useSevdeskConfig();
  const { apiKey, email, setupAt } = config ?? {};
  const connected = apiKey && email && setupAt;

  return (
    <PanePaper title="Verbindung zu sevDesk" sx={{ position: 'relative', minHeight: 150, maxWidth: 650 }}>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      {connected && (
        <Group mb="sm" mt="md">
          <Avatar color="blue" radius="xl" size="md">
            <Text span color="white">
              {email[0].toUpperCase()}
            </Text>
          </Avatar>
          <div>
            <Text size="xs" color="dimmed">
              E-Mail
            </Text>
            <Text weight="bolder">{email}</Text>
          </div>
        </Group>
      )}
      <PasswordInput
        label="API-Schlüssel"
        placeholder={connected ? '' : 'Geben Sie den API-Schlüssel ein'}
        mt="md"
        icon={<IconCircleKey size={16} color={apiKey ? '#1D5191' : 'gray'} />}
        defaultValue={apiKey ?? undefined}
        onBlur={(e) => e.currentTarget.value !== apiKey && updateConfig({ apiKey: e.currentTarget.value })}
        sx={{ maxWidth: 400 }}
      />
      {!connected && (
        <Text mt="xs">
          Finden Sie Ihren Schlüssel unter der folgenden URL:
          <Text variant="link" color="blue" mt={5} sx={{}}>
            <IconExternalLink style={{ marginBottom: 4, marginRight: 4 }} size={16} />
            <a target="_blank" href={URL} style={{ color: '#1D5191' }}>
              {URL}
            </a>
          </Text>
        </Text>
      )}
    </PanePaper>
  );
};

export default SevdeskConfigPane;
