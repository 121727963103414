import React, { CSSProperties, FC, ReactNode } from 'react';
import { Spinner } from 'reactstrap';
import { useId } from '@mantine/hooks';
import { Icon } from '../Icons';
import { TransToolTip } from './ToolTip';

type Props = {
  altComponent?: ReactNode;
  className?: string;
  id?: string;
  onClick?: () => void;
  icon?: string;
  style?: CSSProperties;
  text: string;
  placement?: any;
  isLoading?: boolean;
};

const TooltipButton: FC<Props> = ({
  altComponent,
  className,
  id,
  onClick,
  icon,
  style,
  text,
  placement,
  isLoading,
}) => {
  const styles = {
    button: {
      cursor: 'pointer',
      padding: '5px !important',
      alignSelf: 'center',
    },
  };

  const uuid = useId();

  return (
    <span style={styles.button}>
      {!altComponent ? (
        <span
          id={`Tooltip-${uuid}`}
          className={`${className} btn btn-icon`}
          color="icon"
          onClick={onClick ? () => onClick() : () => {}}
          style={style}
        >
          {isLoading && <Spinner size="sm" color="dark" />}
          {!isLoading && icon && <Icon value={icon} />}
        </span>
      ) : (
        <div id={`Tooltip-${uuid}`}>{altComponent}</div>
      )}
      <TransToolTip text={text} placement={placement} target={`Tooltip-${uuid}`} />
    </span>
  );
};

export default TooltipButton;
