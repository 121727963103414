import { Alert, Stack, Text } from '@mantine/core';
import { useContext } from 'react';
import { IconPlugConnectedX } from '@tabler/icons';
import EventsSortedMonthly from '../../Organismns/Eventlist/EvensSortedMonthly';
import ListEvents from '../../Molecules/Eventlist/ListEvents';
import EventsNotFound from '../../Organismns/Eventlist/EventsNotFound';
import { EventsContext } from '../../Service/Context/EventsContext';
import { EventItemSkeleton } from '../../Atoms/SkeletonItems';

const EventListContent = () => {
  const { bookingEvents, loading, totalCount, filter, networkError } = useContext(EventsContext);
  const { sorting } = filter;

  if (loading || totalCount === undefined) {
    return (
      <Stack mt="md">
        {Array.from(Array(4)).map(() => (
          <EventItemSkeleton key={Math.random()} />
        ))}
      </Stack>
    );
  }

  if (networkError) {
    return (
      <Alert
        sx={(theme) => ({
          backgroundColor: theme.colors.red[3],
        })}
        icon={<IconPlugConnectedX color="white" size={16} />}
        title={
          <Text color="white" weight="bolder" size="md">
            Es ist ein Problem aufgetreten
          </Text>
        }
      >
        {' '}
        <Text color="white">Überprüfe Deine Internetverbindung</Text>
      </Alert>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {totalCount === 0 || bookingEvents.length === 0 ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <EventsNotFound totalCount={totalCount} />
        </div>
      ) : (
        <>
          {sorting === 'chronological' && <EventsSortedMonthly eventDates={bookingEvents} />}
          {sorting !== 'chronological' && <ListEvents bookingEvents={bookingEvents} />}
        </>
      )}
    </>
  );
};

export default EventListContent;
