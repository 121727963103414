/* eslint-disable react/jsx-no-useless-fragment */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Skeleton, Space, Text, Tooltip } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import Moment from 'react-moment';
import AvailabilityIcon from '../../../Atoms/AvailabilityIcon';
import { EventS } from '../../../Service/EventS';
import { fetchContactById } from '../../../Service/restapi/contactService';
import { fetchEventById } from '../../../Service/restapi/eventService';
import Contact from '../../../Types/Contact';
import BookingEvent from '../../../Types/Event';

const DocumentEventStatus: FC<{ eventId: number; shortVersion?: boolean; hideDate?: boolean }> = ({
  eventId,
  shortVersion,
  hideDate,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [customer, setCustomer] = useState<Contact>();
  const [bookingEvent, setBookingEvent] = useState<BookingEvent>();

  useEffect(() => {
    fetchEventById(eventId.toString())
      .then((res) => {
        if (res.ok) {
          res.json().then(setBookingEvent);
        }
      })
      .finally(() => setLoading(false));
  }, [eventId]);

  useEffect(() => {
    if (bookingEvent) {
      const { customerId } = bookingEvent;
      fetchContactById(customerId.toString())
        .then((contact: any) => setCustomer(contact))
        .finally(() => setLoading(false));
    }
  }, [bookingEvent]);

  return (
    <>
      {loading ? (
        <>{shortVersion ? <Skeleton circle height={10} /> : <Skeleton width={100} height={20} radius="md" />}</>
      ) : (
        <div>
          {bookingEvent && (
            <>
              {shortVersion ? (
                <div>
                  <AvailabilityIcon center bookingState={bookingEvent.bookingState} />
                </div>
              ) : (
                <Tooltip
                  withArrow
                  position="left-start"
                  transition="pop"
                  label={EventS.getBookingStateTooltipLabel(bookingEvent.bookingState)}
                >
                  <Group
                    spacing={0}
                    sx={(theme) => ({
                      minHeight: 18,
                      width: shortVersion ? 20 : 'inherit',
                      color: 'black',
                      display: 'flex',
                      fontWeight: 'lighter',
                      backgroundColor: theme.colors.gray[1],
                      borderRadius: 10,
                    })}
                  >
                    <Space w="xs" />
                    <AvailabilityIcon bookingState={bookingEvent.bookingState} />
                    <Space w="xs" />
                    {!shortVersion && (
                      <>
                        {customer && (
                          <Text
                            className="overflow-ellipsis"
                            size="xs"
                            weight="bolder"
                            sx={{ width: hideDate || bookingEvent.dates.length === 0 ? '85%' : 75 }}
                          >
                            {customer.personDescription}
                          </Text>
                        )}
                        {!hideDate && bookingEvent.dates.length > 0 && (
                          <Text size="xs" ml={5}>
                            am{'  '}
                            <Moment format="DD.MM.YYYY" date={new Date(bookingEvent.dates[0].date)} />
                          </Text>
                        )}
                      </>
                    )}
                  </Group>
                </Tooltip>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DocumentEventStatus;
