import React, { useContext, useState } from 'react';
import { ModalDialog } from '../Dialogs';
import { TransTextInputField } from '../../Atoms/InputFields';
import { TransButtonDefault, TransButtonPrimaryWithSpinner } from '../../Atoms/Buttons';
import { patchQuestionnaire } from '../../Service/restapi/questionnaireService';
import { patchDocument } from '../../Service/restapi/documentService';
import { patchContract } from '../../Service/restapi/contractService';
import {ValidationS} from "../../Service/ValidationS";
import {i18n} from "@lingui/core";
import {ToastS} from "../../Service/ToastS";

export default function ({ entityType, entityId, handleChange, initialLabel, toggle }) {
  const [label, setLabel] = useState(initialLabel);
  const [isLoading, setLoading] = useState(false);

  function patch() {
    setLoading(true);
    let patchFct = patchQuestionnaire;
    if (entityType === 'documents') {
      patchFct = patchDocument;
    } else if (entityType === 'contracts') {
      patchFct = patchContract;

      if (!ValidationS.validAwsObjectKeyName(label)) {
        ToastS.error('invalid-aws-char', i18n._('error.input-field.invalid-aws-char'));
        setLoading(false);
        return;
      }
    }
    patchFct(entityId, { label })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then(handleChange);
          toggle();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <ModalDialog
      header="Label setzen"
      body={
        <TransTextInputField
          value={label}
          onChange={(e) => {
            setLabel(e.target.value);
          }}
          placeholder="Label"
        />
      }
      footer={
        <>
          <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />
          <TransButtonPrimaryWithSpinner isLoading={isLoading} onClickHandler={patch} text="actions.save" />
        </>
      }
      show
      toggle={toggle}
    />
  );
}
