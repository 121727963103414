// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Sx, Text } from '@mantine/core';
import { FC, Key, ReactNode } from 'react';

const BookingStateItem: FC<{
  label: string;
  active: boolean;
  color?: string;
  textColor?: string;
  bgColor?: string;
  onClick?: () => void;
  count?: number;
  icon?: ReactNode;
  key?: Key;
  textStyle?: Sx;
  sx?: Sx;
}> = ({
  label,
  active,
  onClick,
  bgColor = 'transparent',
  color = '#616161',
  textColor,
  count,
  icon,
  key,
  sx,
  textStyle,
}) => (
  <Box
    key={key}
    onClick={onClick}
    sx={(theme) => ({
      position: 'relative',
      backgroundColor: active ? bgColor : 'transparent',
      height: 32,
      display: 'flex',
      alignItems: 'center',
      cursor: onClick ? 'pointer' : 'default',
      '&:hover': {
        backgroundColor: theme.colors.gray[0],
      },
      ...sx,
    })}
  >
    <div
      style={{
        width: 5,
        height: 20,
        backgroundColor: active ? color : 'transparent',
        borderRadius: 25,
        marginLeft: 25,
        marginRight: 20,
      }}
    />
    <Text color={active ? textColor ?? color : 'black'} weight={active ? 'bolder' : 'normal'} sx={textStyle}>
      {label}
    </Text>
    {icon && <Box sx={{ position: 'absolute', right: 15 }}>{icon}</Box>}
    {count && (
      <Box sx={{ position: 'absolute', right: 15, top: 7 }}>
        <Text size="xs">{count}</Text>
      </Box>
    )}
  </Box>
);

export default BookingStateItem;
