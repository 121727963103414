// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Center, Skeleton, Text, ThemeIcon, Title } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { IconCheck, IconExternalLink, IconPlug } from '@tabler/icons';
import { openModal } from '@mantine/modals';
import { AzureAccountDTO } from '../../../../Types/MailT';
import { AzureS } from '../../../../Service/AzureS';

interface Props {
  setOauthConnected: (val: boolean) => unknown;
}

const AzureAuthentication: FC<Props> = ({ setOauthConnected }) => {
  const [accounts, setAccounts] = useState<AzureAccountDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const { length } = accounts;

  useEffect(() => {
    if (length > 0) {
      setOauthConnected(true);
    } else {
      setOauthConnected(false);
    }
  }, [length, setOauthConnected]);

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    setLoading(true);
    AzureS.fetchConnectedAccounts()
      .then(setAccounts)
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <Skeleton width="100%" height={200} />;
  }
  if (!loading && accounts.length === 0) {
    return (
      <Center mt={40}>
        <Button onClick={() => AzureS.startOAuthProcess()} leftIcon={<IconPlug size={16} />}>
          Konto verbinden
        </Button>
      </Center>
    );
  }
  const { id, email } = accounts[0];

  const revokeGrant = async () => {
    const revoked = await AzureS.removeAccount(id);
    if (revoked) {
      fetchAccounts();
      openModal({
        title: <Title order={4}>App-Zugriff widerrufen</Title>,
        children: (
          <Text>
            Leider können wir uns nicht selbst den kompletten Zugriff entziehen, daher gehe auf
            <Text span color="blue" variant="link" sx={{ cursor: 'pointer' }}>
              {' '}
              <IconExternalLink size={16} style={{ marginBottom: 3 }} />
              https://account.microsoft.com/privacy/app-access
            </Text>
            {'  '}und entferne bookitup manuell aus der Liste.
          </Text>
        ),
      });
    }
  };

  return (
    <Text mb="xl">
      <ThemeIcon color="blue" radius="xl" size="sm" mr={5}>
        <IconCheck size={14} />
      </ThemeIcon>
      {'  '}Verbunden als{' '}
      <Text span color="blue" weight="bolder">
        {email}
      </Text>
      <Text ml="xs" color="red" variant="link" onClick={revokeGrant} sx={{ cursor: 'pointer' }}>
        - Konto widerrufen
      </Text>
    </Text>
  );
};

export default AzureAuthentication;
