/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Center, Group, Loader, Select, Text, TextInput } from '@mantine/core';
import { i18n } from '@lingui/core';
import { DatePicker, TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { closeAllModals, openConfirmModal, openModal } from '@mantine/modals';
import React, { FC, useState } from 'react';
import { IconCalendarEvent } from '@tabler/icons';
import ResourcePlanningS from '../../Service/restapi/resourcePlanningS';
import { PlanningGroup } from '../../Types/ResourceT';
import { DateUtils } from '../../Utils/dateUtils';
import { EventDate } from '../../Types/Event';
import { DataS } from '../../Service/DataS';

interface PlanningGroupProps {
  interceptViolation?: (httpResponse: Response) => void;
  initial?: PlanningGroup;
  order?: number;
  eventStart?: Date;
  eventEnd?: Date;
  eventId?: string;
  onFinish: (group: PlanningGroup) => void;
  dates: EventDate[];
}

export const openPlanningGroupModal = (props: PlanningGroupProps) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        {props.initial ? 'Planungsgruppe bearbeiten' : 'Planungsgruppe hinzufügen'}
      </Text>
    ),
    children: <CreatePlanningModal {...props} />,
  });
};

const CreatePlanningModal: FC<PlanningGroupProps> = ({
  initial,
  order,
  eventStart,
  eventEnd,
  eventId,
  onFinish,
  interceptViolation,
  dates,
}) => {
  const EDIT_MODE = initial !== undefined;

  const form = useForm({
    initialValues: {
      description: EDIT_MODE ? initial.description : `Planungsgruppe ${order}`,
      begin: EDIT_MODE ? new Date(initial.begin) : eventStart,
      beginTime: EDIT_MODE ? new Date(initial.begin) : eventStart,
      end: EDIT_MODE ? new Date(initial.end) : eventEnd,
      endTime: EDIT_MODE ? new Date(initial.end) : eventEnd,
    },
    validate: {
      description: (value) => (value.length > 0 ? null : 'Bitte geben Sie den Namen ein'),
    },
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const { description, begin, beginTime, end, endTime } = form.values;
    let newGroup;

    const from = DateUtils.mergeInterval([begin, beginTime]);
    const to = DateUtils.mergeInterval([end, endTime]);

    if (EDIT_MODE) {
      newGroup = await ResourcePlanningS.updatePlanningGroup(initial.id, {
        description,
        begin: from,
        end: to,
        usageBegin: from,
        usageEnd: to,
      });
    } else if (begin && end && eventId) {
      newGroup = await ResourcePlanningS.createPlanningGroup(
        {
          description,
          begin: from,
          end: to,
          usageBegin: from,
          usageEnd: to,
          entityId: eventId,
          entityType: 'events',
        },
        interceptViolation,
      );
    }
    setLoading(false);
    if (newGroup) {
      onFinish(newGroup);
      closeAllModals();
    }
  };

  const onEventDateSelect = (eventDateId: number) => {
    const selectedEventDate = dates.find((d) => d.id === eventDateId);
    if (selectedEventDate) {
      const { start, end } = selectedEventDate;
      const from = new Date(start);
      const to = new Date(end);
      form.setValues({ begin: from, beginTime: from, end: to, endTime: to });
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Text sx={{ marginBottom: 10 }}>
        Mit Plannungsgruppen kannst du Ressourcen zu unterschiedlichen Zeiträumen einplanen.
      </Text>
      <TextInput label="Name" {...form.getInputProps('description')} />
      {dates.length > 0 && (
        <Select
          icon={<IconCalendarEvent size={20} />}
          mt="xs"
          mb="md"
          label="Veranstaltungsdatum"
          data={DataS.getEventDatesOptions(dates)}
          defaultValue={dates[0].id.toString()}
          onChange={(val) => val && onEventDateSelect(Number(val))}
        />
      )}
      <Group position="apart" mt="xs">
        <Text>Beginn:</Text>
        <Group spacing="xs">
          <DatePicker {...form.getInputProps('begin')} sx={{ width: 120 }} />
          <TimeInput {...form.getInputProps('beginTime')} />
        </Group>
      </Group>
      <Group position="apart" mt="xs">
        <Text>Ende:</Text>
        <Group spacing="xs">
          <DatePicker {...form.getInputProps('end')} sx={{ width: 120 }} />
          <TimeInput {...form.getInputProps('endTime')} />
        </Group>
      </Group>
      <Center mt={40}>
        <Button sx={{ width: 200 }} disabled={loading} type="submit" leftIcon={loading && <Loader size="xs" />}>
          {i18n._('actions.save')}
        </Button>
      </Center>
    </form>
  );
};

export const openDeleteResourcePlanning = (name: string, onConfirm: () => void) => {
  openConfirmModal({
    title: (
      <Text weight="bolder" size="xl">
        {i18n._('event.resource.planning.delete.confirm.head')}
      </Text>
    ),
    labels: { confirm: i18n._('actions.delete'), cancel: i18n._('actions.cancel') },
    children: (
      <Text>
        Möchtest Du die Ressource{' '}
        <Text span weight="bolder">
          {name}
        </Text>{' '}
        wirklich entfernen?
      </Text>
    ),
    onConfirm,
    confirmProps: { color: 'red' },
    groupProps: { spacing: 'xs' },
  });
};

interface DeleteGroupProps {
  group: PlanningGroup;
  onDelete: () => void;
}

export const openDeletePlanningGroupModal = (props: DeleteGroupProps) => {
  openConfirmModal({
    title: (
      <Text weight="bolder" size="xl">
        Planungsgruppe löschen
      </Text>
    ),
    labels: { confirm: i18n._('actions.delete'), cancel: i18n._('actions.cancel') },
    children: (
      <Text>
        Möchten Sie die Planungsgruppe{' '}
        <Text span weight="bolder">
          {props.group.description}
        </Text>{' '}
        wirklich entfernen? Alle Gruppenplanungen werden ebenfalls gelöscht.
      </Text>
    ),
    onConfirm: props.onDelete,
    confirmProps: { color: 'red' },
    groupProps: { spacing: 'xs' },
  });
};
