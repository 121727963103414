// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Button, Loader, Text } from '@mantine/core';
import { useState } from 'react';
import { IconRocket } from '@tabler/icons';
import { AuthS } from '../../../Service/restapi/authService';

const TrialRedeemAlert = () => {
  const [loading, setLoading] = useState(false);

  const redeem = async () => {
    setLoading(true);
    await AuthS.redeemTrialLicence();
    setLoading(false);
  };

  return (
    <Alert
      title="Erneut 14 Tage kostenlos testen"
      icon={<IconRocket />}
      sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0], maxWidth: 850 })}
    >
      <Text>
        Du hast beim letzten mal nicht die Zeit gefunden, dich mit bookitup intensiv zu beschäftigen? Kein Problem!
        Klicke auf &quot;Kostenlose Testphase freischalten&quot;, um bookitup noch einmal für 14 Tage kostenlos
        auszuprobieren.
      </Text>
      <Button mt="xl" rightIcon={loading && <Loader size="xs" />} disabled={loading} onClick={redeem}>
        Kostenlose Testphase freischalten
      </Button>
    </Alert>
  );
};

export default TrialRedeemAlert;
