import { Skeleton, Stack } from '@mantine/core';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import PanePaper from '../../PanePaper';
import EmptyText from '../../../Atoms/EmptyText';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { openLocationModal } from '../Workflows/LocationModal';
import { EmptyLocation, EventLocationContextProps, LocationDto } from '../../../Types/LocationT';
import { EventLocationContext } from '../../../Service/Context/EventLocationContext';
import { EventContext } from '../../../Service/Context/EventContext';
import LocationItem from './LocationItem';

const LocationPane = () => {
  const { id } = useParams();
  const { assignPrimaryLocation } = useContext<any>(EventContext);
  const { locations, handlers, loading } = useContext<any>(EventLocationContext) as EventLocationContextProps;
  const { append } = handlers;
  const isEmpty = locations.length === 0;

  const appendHandler = (loc: LocationDto) => {
    if (locations.length === 0) {
      assignPrimaryLocation(loc.id);
    }
    append(loc);
  };

  const addLocation = () =>
    openLocationModal({
      eventId: Number(id),
      location: EmptyLocation,
      onFinish: appendHandler,
    });

  return (
    <PanePaper
      withShadow
      withoutBorder
      title="common.location"
      tooltip="help.event.location.pane"
      rightSection={<BookitupAddIcon onClick={addLocation} />}
    >
      {loading && <Skeleton width="100%" height={100} />}
      {!loading && isEmpty && <EmptyText />}
      {!loading && !isEmpty && (
        <Stack spacing="xs">
          {locations.map((location, index) => (
            <LocationItem key={location.id} index={index} location={location} />
          ))}
        </Stack>
      )}
    </PanePaper>
  );
};

export default LocationPane;
