import React, { useEffect, useState } from 'react';
import { useLingui } from '@lingui/react';
import { Tooltip } from 'react-tippy';
import { Icon } from '../../Atoms/Icons';
import { EditablePlaceholderDispatcher } from './EditablePlaceholderDispatcher';

const Placeholder = ({
  id,
  value,
  index,
  settings,
  editable,
  removable,
  finish,
  getCustomAttributePlaceholderValue,
  getPlaceholderValue,
  ...rest
}) => {
  const [ref] = useState(React.createRef());
  const [display, setDisplay] = useState(true);
  const [replacedValue, setReplacedValue] = useState(undefined);
  // why [0]?
  const { event, customer, eventLocation, _document, contract, customAttributeValues } = { ...rest[0] };
  const partner = customer ? customer.partner : null;
  const { i18n } = useLingui();

  useEffect(() => {
    // replace event data
    if (event) {
      const dates = event.dates.filter((date) => date.useAsPrimary);
      const flatEvent =
        dates.length > 0
          ? {
              ...event,
              ...dates[0], // TODO
            }
          : event;
      if (id.includes('event_')) {
        replaceData('event', flatEvent);
      }
    }
  }, [event, id]);

  useEffect(() => {
    if (partner && id.includes('customer_partner_')) {
      replaceData('customer_partner', partner);
    }
  }, [partner, id]);

  useEffect(() => {
    if (customer && id.includes('customer_') && !id.includes('partner')) {
      replaceData('customer', customer);
    }
    if (customer && partner && (id === '{customer_and_partner_names}' || id === '{customer_and_partner_first_names}')) {
      const { firstName, lastName } = customer;
      const { firstName: partnerFirstName, lastName: partnerLastName } = partner;

      if (id === '{customer_and_partner_names}') {
        if (firstName && lastName && partnerFirstName && partnerLastName) {
          setReplacedValue(`${firstName} ${lastName} und ${partnerFirstName} ${partnerLastName}`);
        } else if (firstName && lastName) {
          setReplacedValue(`${firstName} ${lastName}`);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (firstName && partnerFirstName) {
          setReplacedValue(`${firstName} und ${partnerFirstName}`);
        } else if (firstName) {
          setReplacedValue(firstName);
        }
      }
    }
  }, [customer, id]);

  useEffect(() => {
    if (id.includes('document_')) {
      replaceData('document', _document);
    }
  }, [_document, id]);

  useEffect(() => {
    if (id.includes('contract_')) {
      replaceData('contract', contract);
    }
  }, [contract, id]);

  useEffect(() => {
    if (id.includes('location_')) {
      replaceData('location', eventLocation);
    }
  }, [eventLocation, id]);

  useEffect(() => {
    if (customAttributeValues && id.includes(':')) {
      const value = getCustomAttributePlaceholderValue(id, customAttributeValues, i18n);
      if (value !== undefined) {
        setReplacedValue(value);
      } else {
        setReplacedValue(null);
      }
    }
  }, [customAttributeValues, id]);

  function replaceData(entityName, entity) {
    if (!entity) {
      setReplacedValue(null);
    } else {
      const value = getPlaceholderValue(id, entityName, entity, i18n);
      if (value !== undefined) {
        setReplacedValue(value);
      } else {
        setReplacedValue(null);
      }
    }
  }

  if (!display) {
    return null;
  }

  const styles = {
    container: {
      // display: "flex",
      marginLeft: 5,
      marginRight: 5,
      display: 'inline-block',
    },
    placeholder: {
      // border: '1px solid rgba(0,0,0,.2)',
      borderRadius: 4,
      backgroundColor: '#F1F1F1',
      userSelect: 'none',
      // lineHeight: '18px',
      padding: '1px 5px',
      marginRight: 2,
      fontSize: 12,
    },
    attentionIcon: {
      marginRight: 3,
      color: '#f07373',
    },
  };
  let icon;
  if (replacedValue === undefined) {
    icon = <Icon style={styles.attentionIcon} value="ATTENTION_TRIANGLE" />;
  } else if (replacedValue === null || replacedValue === '') {
    icon = <Icon style={styles.attentionIcon} value="ATTENTION" />;
  }

  const isFF = !!navigator.userAgent.match(/firefox/i);
  return (
    <>
      {/* &#8203; */}
      <placeholder
        index={index}
        ref={ref}
        contentEditable={false}
        style={{ ...styles.container, ...styles.placeholder }}
        data-placeholder-id={id}
        data-placeholder-i18n={value}
        data-placeholder-value={replacedValue}
      >
        <Tooltip
          title={value}
          position="top"
          trigger="click"
          theme="light"
          interactive
          arrow
          onRequestClose={finish}
          useContext
          html={
            settings || !editable ? null : (
              <EditablePlaceholderDispatcher placeholderId={id} refreshValue={replaceData} customer={customer} />
            )
          }
        >
          {!settings && icon}
          <span className="overflow-ellipsis">{replacedValue || value}</span>
        </Tooltip>
      </placeholder>
      {/* fixes a bug in Firefox, that the placeholder cannot be deleted by backspace */}
      {isFF && (
        <span contentEditable suppressContentEditableWarning>
          &#8203;
        </span>
      )}
      {!isFF && <>&#8203;</>}
    </>
  );
};

export default Placeholder;
