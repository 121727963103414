import { FC, useContext } from 'react';
import Attachments from '../../Organismns/Attachments';
import { Box, Divider, Group, Space, Text } from '@mantine/core';
import { EmailBoxContext } from '../../Service/Context/EmailBoxContext';
import MessageActions from './MessageActions';
import moment from 'moment';
import { MessageEventBadge } from '../../Atoms/EventBadge';
import MessagePlaceholderText from '../../Atoms/MessagePlaceholderText';
import Moment from 'react-moment';
import { MessageS } from '../../Service/MessageS';
import { useParams } from 'react-router-dom';
import ResourceAdapter from '../../Service/ResourceAdapter';
import { useElementSize } from '@mantine/hooks';

const ChatMessage: FC<{ phoneVersion: boolean }> = ({ phoneVersion }) => {
  const { id: eventId } = useParams();
  const { selectedMessage: message } = useContext(EmailBoxContext);
  const { box } = useParams();
  const { ref, width } = useElementSize();

  if (!message) {
    return null;
  }
  const customerContact = MessageS.getCustomerContact(message);

  return (
    <Box ref={ref} p="md">
      <Group position="apart" mb={5}>
        {message.subject && (
          <Text size="lg" weight="bolder" sx={{ maxWidth: width - 100 }}>
            <MessagePlaceholderText email={message} value={message.subject} />
          </Text>
        )}
        {!message.subject && (
          <Text italic color="dimmed" size="lg" weight="bolder">
            Kein Betreff
          </Text>
        )}
        <MessageActions phoneVersion={phoneVersion} />
      </Group>
      <Text mb="xs">
        {box == 'inbox' ? 'Von: ' : 'An: '}
        <Text span color="dimmed">
          {customerContact.personDescription || customerContact.email}
        </Text>
      </Text>
      <MessageEventBadge message={message} />
      <Text size="xs" align="right" mt={phoneVersion ? 'md' : 0}>
        {message.localDateTime && <Moment date={message.localDateTime} calendar />}
        {!message.localDateTime && `Entwurf, ${moment(message.lastModified).calendar()}`}
      </Text>
      <Divider my="xs" />

      {message.localDateTime ? (
        <ResourceAdapter
          serviceUrl={`${process.env.REACT_APP_FILE_SERVICE_URL}/files/messages/${message.id}`}
          component={({ data }: any) => {
            data.forEach((file: any) => {
              file.entityType = 'messages';
              file.entityId = message.id;
            });

            return (
              <Attachments
                attachments={data}
                eventId={undefined}
                documentBlueprints={undefined}
                deleteFileAttachment={undefined}
                deleteDocumentAttachment={undefined}
              />
            );
          }}
        />
      ) : (
        <Attachments
          attachments={message.attachments}
          eventId={eventId}
          documentBlueprints={undefined}
          deleteFileAttachment={undefined}
          deleteDocumentAttachment={undefined}
        />
      )}
      <Space h="md" />
      <MessagePlaceholderText email={message} value={message.message} />
    </Box>
  );
};

export default ChatMessage;
