/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text, TextProps } from '@mantine/core';
import { FC } from 'react';

const EmptyText: FC<TextProps> = ({ ...rest }) => (
  <Text italic color="dimmed" size="xs" weight="lighter" {...rest}>
    Noch nichts hinzugefügt.
  </Text>
);

export default EmptyText;
