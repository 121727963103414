/* eslint-disable react/jsx-props-no-spreading */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton, Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { fetchQuestionnairesByEvent, newQuestionnaire } from '../../../Service/restapi/questionnaireService';
import { EventContext } from '../../../Service/Context/EventContext';
import SelectQuestionnaireTemplateModal from '../../Settings/QuestionnaireTemplate/SelectQuestionnaireTemplateModal';
import { useModalState } from '../../Dialogs';
import PanePaper from '../../PanePaper';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import BookitupTable from '../../../Atoms/BookitupTable';
import QuestionnaireItem from './QuestionnaireItem';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import { ConstantS } from '../../../Service/ConstantS';
import { MixpanelS } from '../../../Service/MixpanelS';
import BookitupTutorialIcon from "../../../Atoms/BookitupTutorialIcon";

const QuestionnairePane = () => {
  const [questionnaires, setQuestionnaires] = useState(undefined);
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const { event } = useContext(EventContext);
  const { show, toggle } = useModalState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { ref, width } = useElementSize();

  useEffect(() => {
    load();
  }, []);

  function load() {
    setLoading(true);
    setQuestionnaires(undefined);
    fetchQuestionnairesByEvent(event.id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((questionnaires) => {
            setQuestionnaires(questionnaires);
          });
        } else {
          console.error('could not load questionnaires');
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not load questionnaires');
      })
      .finally(() => setLoading(false));
  }

  function addNewQuestionnaire(templateName) {
    setLoading(true);
    newQuestionnaire(event.id, event.customerId, templateName)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((questionnaire) => {
            MixpanelS.track(ConstantS.TrackingEvents.QuestionnaireCreated);
            navigate(`/events/${event.id}/questionnaires/${questionnaire.id}`);
          });
        } else {
          console.error('could not create new questionnaire');
        }
      })
      .catch((error) => {
        console.error('could not create new questionnaire');
        console.error({ error });
      })
      .finally(() => {
        setLoading(false);
      });
  }
  if (!questionnaires) {
    return (
      <div ref={ref}>
        <Skeleton width="100%" height={100} />
      </div>
    );
  }

  const showColumns = {
    showDatum: width > 350,
    showPortal: width >= 230,
    narrowPortalStatus: width < 280,
  };

  return (
    <PanePaper
      title="common.questionnaires"
      tooltip="licence.event.questionnaires-offline.pane"
      rightSection={<>
        <BookitupTutorialIcon videoUrl={"https://app.bookitup.de/academy/channels/event-details?episodeId=zgkqf33ydb"} name={"CreateQuestionnaire"} />
        <BookitupAddIcon onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.FRAGEBOGEN_USAGE, toggle)} />
      </>
      }
    >
      <div ref={ref}>
        {loading ? (
          <div>
            {Array.from(Array(3)).map(() => (
              <Skeleton key={Math.random()} radius="md" height={25} width="100%" mb={10} />
            ))}
          </div>
        ) : (
          <div>
            {questionnaires.length === 0 ? (
              <Text italic color="dimmed" size="xs" weight="lighter">
                Noch nichts hinzugefügt.
              </Text>
            ) : (
              <BookitupTable>
                <thead>
                  <tr>
                    <th>Bezeichnung</th>
                    {showColumns.showDatum && <th style={{ width: 50 }}>Datum</th>}
                    {showColumns.showPortal && (
                      <th style={{ width: showColumns.narrowPortalStatus ? 50 : 120 }}>Portal</th>
                    )}
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {questionnaires.map((q) => (
                    <QuestionnaireItem key={q.id} questionnaire={q} reload={() => load()} {...showColumns} />
                  ))}
                </tbody>
              </BookitupTable>
            )}
          </div>
        )}
      </div>
      <SelectQuestionnaireTemplateModal isLoading={loading} open={show} toggle={toggle} finish={addNewQuestionnaire} />
    </PanePaper>
  );
};

export default QuestionnairePane;
