/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Button, Center, Loader, LoadingOverlay, Stack, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { closeAllModals, openModal } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import { IconCalendarEvent, IconCheck } from '@tabler/icons';
import { AgendaS, patchTask } from '../../../Service/restapi/taskService';
import { AgendaItem } from '../../../Types/AgendaItem';
import { EventS } from '../../../Service/EventS';

interface Props {
  taskId: string;
  onMarkAsCompleted: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const openTaskPreviewModal = (props: Props) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Aufgabendetails
      </Text>
    ),
    children: <TaskPreviewModal {...props} />,
    onClose: () => setTimeout(() => (document.activeElement as HTMLElement).blur(), 200),
  });
};

const TaskPreviewModal: FC<Props> = ({ taskId, onMarkAsCompleted }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState<AgendaItem | null>(null);
  const [markingAsCompleted, setMarkingAsCompleted] = useState(false);

  useEffect(() => {
    setLoading(true);
    AgendaS.getById(taskId)
      .then(setTask)
      .finally(() => setLoading(false));
  }, [taskId]);

  const markAsCompleted = () => {
    setMarkingAsCompleted(true);
    patchTask(taskId, { completed: true })
      .then((res) => {
        if (res.ok) {
          setTask((prev) => {
            if (prev === null) {
              return null;
            }
            return { ...prev, completed: true };
          });
          onMarkAsCompleted();
        }
      })
      .finally(() => setMarkingAsCompleted(false));
  };

  if (loading) {
    return (
      <Box pos="relative" sx={{ height: 250 }}>
        <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />;
      </Box>
    );
  }

  if (task === null) {
    return <Text>Not found</Text>;
  }
  const { name, completed, priority, notes, entityType, entityId, dueDate } = task;

  return (
    <>
      <Text size="xs" color="dimmed">
        Titel der Aufgabe
      </Text>
      <Text weight="bolder" size="md" mb="md">
        {name}
      </Text>
      {dueDate && (
        <>
          <Text size="xs" color="dimmed">
            Erledigen bis
          </Text>

          <Text weight="bolder" size="md" mb="md">
            {EventS.formatEventDate(new Date(dueDate))}
          </Text>
        </>
      )}
      {notes && (
        <>
          <Text size="xs" color="dimmed">
            Beschreibung der Aufgabe
          </Text>
          <Text weight="bolder" size="md" mb="md">
            {notes}
          </Text>
        </>
      )}
      <Text size="xs" color="dimmed">
        Wichtig
      </Text>
      <Text weight="bolder" size="md" mb="md">
        {priority ? 'Ja' : 'Nein'}
      </Text>
      <Text size="xs" color="dimmed">
        Erledigt
      </Text>
      <Text weight="bolder" size="md" mb="md">
        {completed ? 'Ja' : 'Nein'}
      </Text>
      <Center>
        <Stack>
          {!completed && (
            <Button
              leftIcon={<IconCheck size={16} />}
              onClick={markAsCompleted}
              rightIcon={markingAsCompleted && <Loader size="xs" />}
            >
              als erledigt markieren
            </Button>
          )}
          {entityType === 'events' && (
            <Button leftIcon={<IconCalendarEvent size={16} />} onClick={() => navigate(`/events/${entityId}/details`)}>
              zugehöriges Event öffnen
            </Button>
          )}
          <Button variant="subtle" onClick={() => closeAllModals()}>
            Schließen
          </Button>
        </Stack>
      </Center>
    </>
  );
};
