import { NodeViewRendererProps } from '@tiptap/core';
import { i18n } from '@lingui/core';
import { usePlaceholderValue } from '../../../../Service/hooks/usePlaceholderValue';
import { getPlaceholderLabelById } from '../../../../Service/Placeholder/PlaceholderLabels';

interface NodeAttributes {
  id: string;
  label: string;
}

const EventPlaceholder = (props: NodeViewRendererProps) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { id: placeholderId } = props.node.attrs as NodeAttributes;
  const placeholderValue = usePlaceholderValue(placeholderId);
  // Use placeholder translation if value cannot be resolved
  const finalValue = placeholderValue ?? getPlaceholderLabelById(i18n, placeholderId);

  if (placeholderId === 'event_portalLink' && placeholderValue) {
    return (
      <a data-node-view-wrapper="" title="Kunden-Portal öffnen" href={finalValue}>
        Kunden-Portal öffnen
      </a>
    );
  }
  return (
    // Set data-node-view-wrapper as empty string to prevent usage of default block Wrapper element
    <span data-node-view-wrapper="" className={placeholderValue ? 'mention' : 'unresolved'}>
      {finalValue}
    </span>
  );
};

export default EventPlaceholder;
