// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Flex, Group, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { FC, useContext, useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { IconQuestionMark } from '@tabler/icons';
import { WorkflowStepTemplateDTO } from '../../../Types/LogT';
import { WorkflowS } from '../../../Service/WorkflowS';
import ProcedurePreview from './config/preview/ProcedurePreview';
import WorkflowStepOptions from './WorkflowStepOptions';
import ExecutionConfigPreview from './ExecutionConfigPreview';
import { WorkflowTemplateContext } from './WorkflowTemplate';

interface Props {
  index: number;
  step: WorkflowStepTemplateDTO;
  alternativeFlow?: boolean;
}

const WorkflowStepTemplate: FC<Props> = ({ index, step, alternativeFlow = false }) => {
  const { configId, procedureType, alternativeStep } = step;
  const { isAlternative, addStep } = useContext(WorkflowTemplateContext);
  const [config, setConfig] = useState<unknown>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (configId && procedureType) {
      setLoading(true);
      WorkflowS.fetchProcedureConfig(procedureType, configId)
        .then((resJSON) => {
          if (resJSON) {
            setConfig(resJSON);
          }
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procedureType, configId]);

  return (
    <Flex ml="sm">
      <Stack spacing={5} sx={{ flexGrow: 1 }}>
        <Group spacing={5}>
          <Text weight="bolder">{`${index + 1}. ${i18n._(`actionType.${procedureType}`)} `}</Text>
          {(procedureType === 'WAIT_UNTIL' || alternativeStep) && (
            <Tooltip
              multiline
              withArrow
              position="right"
              label={alternativeStep ? i18n._('step.tooltip.ALTERNATIVE') : i18n._('step.tooltip.WAIT_UNTIL')}
              width={400}
              transition="slide-right"
            >
              <ThemeIcon size={14} sx={{ borderRadius: '50%', backgroundColor: '#D9D9D9' }}>
                <IconQuestionMark size={12} />
              </ThemeIcon>
            </Tooltip>
          )}
        </Group>
        <ProcedurePreview procedureType={procedureType} loading={loading} config={config} />
        <ExecutionConfigPreview workflowStep={step} />
        {procedureType === 'WAIT_UNTIL' && !isAlternative(index + 1, !alternativeFlow) && (
          <Text
            mt="xs"
            color="blue"
            variant="link"
            sx={{ cursor: 'pointer' }}
            onClick={() => addStep(index + 1, alternativeFlow, true)}
          >
            + Alternativschritt hinzufügen
          </Text>
        )}
      </Stack>
      <WorkflowStepOptions
        alternativeFlow={alternativeFlow}
        index={index}
        workflowStep={step}
        handleConfigChange={setConfig}
      />
    </Flex>
  );
};

export default WorkflowStepTemplate;
