import React, { useContext, useEffect, useState } from 'react';
import { useLingui } from '@lingui/react';
import { Space } from '@mantine/core';
import { ContractContext } from '../../Service/Context/ContractContext';
import ContractHeadData from '../../Molecules/Contract/ContractHeadData';
import ContractSkeleton from '../../Organismns/Contract/ContractSkeleton';
import { EventLocationContext } from '../../Service/Context/EventLocationContext';
import { fetchFlattenedCustomAttributeValues } from '../../Service/restapi/customAttributeService';
import { addAboveTextBlockOnContract, addBelowTextBlockOnContract } from '../../Service/restapi/contractService';
import { EditableContractProperty } from '../../Atoms/EditableProperties/EditableContractProperty';
import { EditableSelectField } from '../../Atoms/inputs/EditableSelectField';
import { fetchDocumentById } from '../../Service/restapi/documentService';
import { EventContext } from '../../Service/Context/EventContext';
import PanePaper from '../../Molecules/PanePaper';
import DocumentHeadTemplate from '../DocumentHeadTemplate';
import EventContractHeaderActions from './EventContractHeadActions';
import {EditableDivInput} from "../../Atoms/inputs/EditableDivInput";
import {SimpleEditableText} from "../../Atoms/inputs/SimpleEditableText";
import {StringFormatter} from "../../Atoms/Formatter/StringFormatter";

const EventContractForm = () => {
  const { i18n } = useLingui();
  const { contract, handleChange } = useContext(ContractContext);
  const { event, refreshEvent } = useContext(EventContext);
  const { location } = useContext(EventLocationContext);
  const [customAttributeValues, setCustomAttributeValues] = useState(undefined);
  const [document, setDocument] = useState(undefined);
  const { id } = contract;

  useEffect(() => {
    if (contract.documentId) {
      fetchDocumentById(contract.documentId)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then(setDocument);
          } else {
            console.error(`could not fetch document ${contract.documentId}`);
          }
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not fetch document ${contract.documentId}`);
        });
    } else {
      console.log(`contract ${contract.id} has no document connected`);
    }
  }, [contract.documentId]);

  const styles = {
    container: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    contractDesign: {
      flex: 1,
      display: 'flex',
      flexWrap: 'wrap',
      paddingBottom: 0,
      marginBottom: 10,
    },
    contractHead: {
      flex: 1,
      maxWidth: 200,
      minWidth: 200,
      margin: 5,
    },
    contractFooter: {
      flex: 1,
      maxWidth: 200,
      minWidth: 200,
      margin: 5,
    },
    contractSignType: {
      flex: 1,
      maxWidth: 300,
      minWidth: 300,
      margin: 5,
    },
  };

  useEffect(() => {
    if (!contract.customerId && event) {
      handleChange({ customerId: event.customerId });
    }

    fetchFlattenedCustomAttributeValues('event', event.id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((flattenedValues) => {
            setCustomAttributeValues(flattenedValues);
          });
        } else {
          console.error(`could not load flattened custom attributes of event ${event.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not load flattened custom attributes of event ${event.id}`);
      });
  }, [contract.eventId]);

  function addAboveTextBlock(textBlockId) {
    addAboveTextBlockOnContract(contract.id, textBlockId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((newContact) => {
            handleChange(newContact);
          });
        } else {
          console.error(`could not add textBlock to contract${contract.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not add textBlock to contract${contract.id}`);
      });
  }

  function addBelowTextBlock(textBlockId) {
    addBelowTextBlockOnContract(contract.id, textBlockId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((newContact) => {
            handleChange(newContact);
          });
        } else {
          console.error(`could not add textBlock to contract${contract.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not add textBlock to contract${contract.id}`);
      });
  }

  const contractHeadOptions = [
    { label: i18n._('contract.head.parties'), value: 'PARTIES' },
    { label: i18n._('contract.head.letterhead'), value: 'LETTERHEAD' },
    { label: i18n._('contract.head.none'), value: 'NONE' },
  ];

  const contractFooterOptions = [
    { label: i18n._('contract.footer.centered'), value: 'CENTERED' },
    { label: i18n._('contract.footer.blocks'), value: 'BLOCKS' },
    { label: i18n._('contract.footer.none'), value: 'NONE' },
  ];

  const contractSignOptions = [
    { label: i18n._('contract.sign.both'), value: 'BOTH' },
    { label: i18n._('contract.sign.none'), value: 'NONE' },
  ];

  const customerSignTypeOptions = [
    { label: i18n._('contract.sign.type.base64'), value: 'BASE64' },
    { label: i18n._('contract.sign.type.textField'), value: 'TEXT_FIELD' },
  ];
  return (
    <PanePaper>
      <DocumentHeadTemplate
        entityType="contracts"
        entityId={id}
        title="Verträge erstellen"
        previewUrl={`/events/${event.id}/contracts/${id}/preview`}
        documentActions={<EventContractHeaderActions/>}
      />
      <Space h="xl"/>
      <PanePaper title="Einstellungen">
        <div style={styles.contractSignType}>
          <EditableContractProperty
            patchField="label"
            changeHandler={handleChange}
            entity={contract}
            placeholder="contract.name"
            editFormatter={SimpleEditableText}
            labelFormatter={StringFormatter}
          />
        </div>
        <div style={styles.contractDesign}>
          <div style={styles.contractHead}>
            <EditableContractProperty
              patchField="contractHead"
              changeHandler={handleChange}
              options={contractHeadOptions}
              entity={contract}
              placeholder="contract.head"
              editFormatter={EditableSelectField}
              editOnly
            />
          </div>
          <div style={styles.contractFooter}>
            <EditableContractProperty
              patchField="contractFooter"
              changeHandler={handleChange}
              options={contractFooterOptions}
              entity={contract}
              placeholder="contract.footer"
              editFormatter={EditableSelectField}
              editOnly
            />
          </div>
          <div style={styles.contractFooter}>
            <EditableContractProperty
              patchField="contractSign"
              changeHandler={handleChange}
              options={contractSignOptions}
              entity={contract}
              placeholder="contract.sign"
              editFormatter={EditableSelectField}
              editOnly
            />
          </div>
          <div style={styles.contractSignType}>
            <EditableContractProperty
              patchField="customerSignType"
              changeHandler={handleChange}
              options={customerSignTypeOptions}
              entity={contract}
              placeholder="contract.sign.type"
              editFormatter={EditableSelectField}
              editOnly
            />
          </div>
        </div>
      </PanePaper>
      <Space h="xl"/>
      <PanePaper title="Inhalt">
        <div style={styles.container}>
          <ContractHeadData contract={contract}/>
          <ContractSkeleton
            addAboveTextBlock={addAboveTextBlock}
            addBelowTextBlock={addBelowTextBlock}
            _document={document}
            contract={contract}
            handleChange={handleChange}
            event={event}
            eventLocation={location}
            customAttributeValues={customAttributeValues}
          />
        </div>
      </PanePaper>
    </PanePaper>
  );
};

export default EventContractForm;
