// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Box, Loader, Text, ThemeIcon } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useElementSize } from '@mantine/hooks';
import { IconCalendarOff, IconRefresh } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import PanePaper from '../../../../Molecules/PanePaper';
import { GoogleCalendarDto } from '../../../../Types/GoogleT';
import { GoogleS } from '../../../../Service/GoogleS';
import BookitupTable from '../../../../Atoms/BookitupTable';
import SkeletonItems from '../../../../Atoms/SkeletonItems';
import ConfigOption from '../../Google/ConfigOption';
import BookitupTutorialIcon from '../../../../Atoms/BookitupTutorialIcon';
import GoogleCalendarItem from './GoogleCalendarItem';

const GoogleCalendarsPane = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [publishingEvents, setPublishingEvents] = useState(false);
  const [calendars, setCalendars] = useState<GoogleCalendarDto[]>([]);
  const { ref, width } = useElementSize();
  const { length } = calendars;
  const showCalendarViewAlert = calendars.find((cal) => cal.read && cal.write) !== undefined;

  useEffect(() => {
    fetchCalendars();
  }, []);

  const fetchCalendars = () => {
    setLoading(true);
    GoogleS.fetchCalendars()
      .then(setCalendars)
      .finally(() => setLoading(false));
  };

  const showIndicators = width > 850;

  const removeCalendar = (id: number) => setCalendars(calendars.filter((calendar) => calendar.id !== id));
  const updateCalendar = (id: number, patch: Partial<GoogleCalendarDto>) =>
    setCalendars(calendars.map((cal) => (cal.id === id ? { ...cal, ...patch } : cal)));

  const mapCalendarUI = (calendar: GoogleCalendarDto) => {
    const { id } = calendar;
    return (
      <GoogleCalendarItem
        showIndicators={showIndicators}
        googleCalendar={calendar}
        updateHandler={(patch: Partial<GoogleCalendarDto>) => updateCalendar(id, patch)}
        removeHandler={() => removeCalendar(id)}
      />
    );
  };

  const refresh = () => {
    GoogleS.refreshCalendars().then((success) => {
      if (success) {
        fetchCalendars();
      }
    });
  };

  const publishEvents = () => {
    setPublishingEvents(true);
    GoogleS.publishEvents().finally(() => setPublishingEvents(false));
  };

  return (
    <PanePaper
      title="Google-Kalender"
      tooltip="help.settings.calendar.google.pane"
      rightSection={
        <>
          <BookitupTutorialIcon
            videoUrl="https://app.bookitup.de/academy/channels/perfect-overview?episodeId=241v3ogxbu"
            name="ConnectGoogleCalendar"
          />
          <ThemeIcon size={20} style={{ cursor: 'pointer' }} onClick={refresh}>
            <IconRefresh />
          </ThemeIcon>
        </>
      }
    >
      <div ref={ref}>
        {loading && <SkeletonItems />}
        {!loading && length === 0 && (
          <Text italic size="xs" color="dimmed">
            Es wurden keine Google-Kalender gefunden. Füge jetzt Deinen Google-Kalender in folgendem Abschnitt hinzu:
            <Text span variant="link" onClick={() => navigate('/settings/google')} sx={{ cursor: 'pointer' }}>
              {'  '}Einstellungen &gt; Integrationen &gt; Google-Sync
            </Text>
          </Text>
        )}
        {showCalendarViewAlert && (
          <Alert
            title={<Text color="white">Deine privaten Termine werden nicht angezeigt!</Text>}
            icon={<IconCalendarOff color="white" />}
            sx={(theme) => ({ backgroundColor: theme.colors.orange[3] })}
            mb="sm"
          >
            <Text color="white">
              Deine privaten Kalender-Termine werden nicht im Kalender angezeigt, da Du im selben Kalender auch bookitup
              Events oder Vermittlungen einträgst. Nutze für bookitup Events und Vermittlungen einen separaten Kalender.
            </Text>
          </Alert>
        )}
        {!loading && length > 0 && (
          <BookitupTable>
            <thead>
              <tr>
                <th style={{ width: 50 }}>Konto</th>
                <th style={{ width: 30 }}> </th>
                <th style={{ width: 200 }}>Name </th>
                {showIndicators && <th style={{ width: 120 }}>Event eintragen</th>}
                {showIndicators && <th style={{ width: 120 }}>Termine prüfen</th>}
                {showIndicators && <th style={{ width: 100 }}>Vermittlung</th>}
                {showIndicators && <th style={{ width: 130 }}>Ganztags-Events</th>}
                {showIndicators && <th style={{ width: 130 }}>Eigenen Status</th>}
                <th> </th>
              </tr>
            </thead>
            <tbody>{calendars.map(mapCalendarUI)}</tbody>
          </BookitupTable>
        )}
        {length > 0 && (
          <Box p="sm">
            <ConfigOption
              title="bookitup Event in Google-Kalender eintragen"
              description="Es werden nur bookitup Events eingetragen, deren Event-Status Anfrage, Option oder Gebucht ist."
              rightSection={
                <div>
                  {publishingEvents ? (
                    <Loader variant="dots" />
                  ) : (
                    <Text variant="link" color="blue" sx={{ cursor: 'pointer' }} onClick={publishEvents}>
                      Eintragen
                    </Text>
                  )}
                </div>
              }
            />
          </Box>
        )}
      </div>
    </PanePaper>
  );
};

export default GoogleCalendarsPane;
