// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import moment from 'moment';
import { BookitupNotification } from '../../../../Types/LogT';
import { RoutingS } from '../../../../Service/RoutingS';

const QuestionnaireFilled: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { eventId, personDescription, eventDate } = notification;

  if (!eventDate) {
    return (
      <Text>
        <Text span weight="bolder">
          {`${personDescription} `}
        </Text>
        hat einen Fragebogen zum
        <Text
          span
          weight="bolder"
          variant="link"
          color="blue"
          onClick={() => RoutingS.openInNewTab(`/events/${eventId}/details`)}
          sx={{ cursor: 'pointer' }}
        >
          {'  '}Event{'  '}
        </Text>
        ausgefüllt.
      </Text>
    );
  }

  return (
    <Text>
      <Text span weight="bolder">
        {` ${personDescription} `}
      </Text>
      hat den Fragebogen für das
      <Text
        span
        weight="bolder"
        variant="link"
        color="blue"
        onClick={() => RoutingS.openInNewTab(`/events/${eventId}/details`)}
        sx={{ cursor: 'pointer' }}
      >
        {'  '}Event{'  '}
      </Text>
      am
      <Text span weight="bolder">
        {` ${moment(eventDate).format('LL')} `}
      </Text>
      ausgefüllt
    </Text>
  );
};

export default QuestionnaireFilled;
