import { Stack } from '@mantine/core';
import EventItem from './EventItem';

export default function ({ bookingEvents }) {
  const eventsOfMonth = [];

  if (bookingEvents) {
    bookingEvents.map((event, index1) => {
      eventsOfMonth.push(<EventItem bookingEventDate={event}/>);
    });
  }

  return <Stack data-testid="eventList">{eventsOfMonth}</Stack>;
}
