import { useContext, useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { Stack } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import { TransButtonDefault, TransButtonPrimaryWithSpinner } from '../Atoms/Buttons';
import { ModalDialog } from '../Molecules/Dialogs';
import { EventParticipationsContext } from '../Service/Context/EventParticipationContext';
import { ToastS } from '../Service/ToastS';
import { getContactById } from '../Service/restapi/contactService';
import { CustomerContext } from '../Service/Context/CustomerContext';
import ContactListItem from './Contact/ContactListItem';
import { openEditContactModal } from './Contact/EditContactModal';

const ContactList = () => {
  const { participations, removeParticipation } = useContext(EventParticipationsContext);
  const [editingContact, setEditingContact] = useState(false);
  const [editContact, setEditContact] = useState(undefined);
  const [deleteContact, setDeleteContact] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [lastModified, setLastModified] = useState(undefined);
  const { customer, changeCustomer } = useContext(CustomerContext);

  const list = [];

  useEffect(() => {
    if (editingContact) {
      getContactById(editContact.contactId).then((resp) => {
        if (resp.ok) {
          resp.json().then((newContact) => {
            openEditContactModal(
              newContact,
              () => setLastModified(new Date()),
              (c) => {
                if (c.id === customer.id) {
                  changeCustomer(c);
                }
              },
              () => setEditingContact(false),
            );
          });
        } else {
          ToastS.generalError();
        }
      });
    }
    return () => closeAllModals();
  }, [editingContact]);

  participations.forEach((participation, index) => {
    list.push(
      <div key={index}>
        <ContactListItem
          lastModified={lastModified}
          participation={participation}
          setDeleteContact={setDeleteContact}
          setEditContact={setEditContact}
          setEditingContact={setEditingContact}
          editingContact={editingContact}
          index={index}
        />
      </div>,
    );
  });
  function deleteContactHandler() {
    if (!deleteContact.eventId) {
      removeParticipation(deleteContact.index);
      setDeleteContact(undefined);
    } else {
      setLoading(true);

      fetch(`${process.env.REACT_APP_EVENT_SERVICE_URL}/eventParticipations`, {
        method: 'DELETE',
        credentials: 'include',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({ contactId: deleteContact.contactId, eventId: deleteContact.eventId }),
      })
        .then((response) => {
          if (response.ok) {
            removeParticipation(deleteContact.index);
            setDeleteContact(undefined);
            ToastS.success('event.delete.contact', 'Kontakt erfolgreich entfernt');
          } else if (response.status === 412) {
            setDeleteContact(undefined);
            ToastS.warn('event.delete.contact', 'Das Event muss mindestens einen Kontakt haben.');
          } else {
            setDeleteContact(undefined);
            ToastS.error('event.delete.contact', 'Es ist ein Fehler aufgetreten');
          }
        })
        .catch(() => {
          ToastS.error('event.delete.contact', 'Es ist ein Fehler aufgetreten');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <>
      <Stack>{list}</Stack>
      {/* <CreateOrSelectContactModal show={show} toggle={toggle} onContactIdSelected={persistEventParticipation} /> */}
      <ModalDialog
        header={<Trans id="common.remove.contact.confirm.head" />}
        body={<Trans id="common.remove.contact.confirm" />}
        footer={
          <div>
            <TransButtonPrimaryWithSpinner
              isLoading={loading}
              onClickHandler={deleteContactHandler}
              text="actions.remove"
            />{' '}
            <TransButtonDefault
              onClickHandler={() => {
                setDeleteContact(undefined);
              }}
              text="actions.cancel"
            />
          </div>
        }
        show={deleteContact !== undefined}
        toggle={() => setDeleteContact(undefined)}
      />
    </>
  );
};

export default ContactList;
