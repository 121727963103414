import { AzureAccountDTO } from '../Types/MailT';
import { ajaxActions } from './AjaxActions';
import { ConstantS } from './ConstantS';
import { MixpanelS } from './MixpanelS';
import { ToastS } from './ToastS';

const BASE_URL = `${process.env.REACT_APP_MAIL_SERVICE_URL}/azure`;

const startOAuthProcess = async () => {
  const res = await ajaxActions.get(`${BASE_URL}/connect`);
  if (res.ok) {
    const redirectUrl = await res.text();
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }
};

const completeOAuthProcess = async (code: string) => {
  const res = await ajaxActions.get(`${BASE_URL}/oauth?authCode=${code}`);
  if (res.ok) {
    MixpanelS.track(ConstantS.TrackingEvents.AzureAccountConnected);
    ToastS.success('azure-oauth', '🎉 Konto erfolgreich verbunden');
    return true;
  }
  ToastS.error('azure-oauth', 'Das Konto kann derzeit nicht verbunden werden');
  return false;
};

const fetchConnectedAccounts = async (): Promise<AzureAccountDTO[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/accounts`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const removeAccount = async (accountId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/account/${accountId}`);
  if (res.ok) {
    ToastS.info('account-removed', 'Konto entfernt');
    return true;
  }
  ToastS.generalError();
  return false;
};

// eslint-disable-next-line import/prefer-default-export
export const AzureS = {
  startOAuthProcess,
  completeOAuthProcess,
  fetchConnectedAccounts,
  removeAccount,
};
