import { useEffect, useState } from 'react';
import { SevdeskConfigDTO } from '../../Types/SevdeskT';
import { SevdeskS } from '../SevdeskS';

// eslint-disable-next-line import/prefer-default-export
export const useSevdeskConfig = () => {
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState<SevdeskConfigDTO | null>(null);

  useEffect(() => {
    setLoading(true);
    SevdeskS.fetchConfig()
      .then(setConfig)
      .finally(() => setLoading(false));
  }, []);

  const updateConfig = async (patch: Partial<SevdeskConfigDTO>) => {
    const resJSON = await SevdeskS.editConfig(patch);
    if (resJSON) setConfig(resJSON);
  };

  return { loading, config, updateConfig };
};
