import { FC } from 'react';
import { IconCheck, IconX } from '@tabler/icons';
import { Avatar, ThemeIcon } from '@mantine/core';
import { GoogleCalendarDto } from '../../../../Types/GoogleT';
import Dot from '../../../../Atoms/Dot';
import GoogleCalendarActions from './GoogleCalendarActions';

interface Props {
  showIndicators: boolean;
  googleCalendar: GoogleCalendarDto;
  updateHandler: (patch: Partial<GoogleCalendarDto>) => void;
  removeHandler: () => void;
}

const GoogleCalendarItem: FC<Props> = ({ showIndicators, googleCalendar, updateHandler, removeHandler }) => {
  const {
    id,
    write,
    read,
    recommendation,
    insertAsFullTimeEvents,
    calendarName,
    backgroundColor,
    ownerIconUrl,
    useCustomStateLabels,
  } = googleCalendar;

  const getIcon = (granted: boolean) => (
    <ThemeIcon radius="xl" size="sm" color={granted ? 'blue' : 'gray'}>
      {granted ? <IconCheck size={14} /> : <IconX size={14} />}
    </ThemeIcon>
  );

  return (
    <tr key={id}>
      <td align="center">
        <Avatar radius="xl" size="sm" src={ownerIconUrl} />
      </td>
      <td>
        <Dot withoutShadow colour={backgroundColor} />
      </td>
      <td className="overflow-ellipsis" style={{ maxWidth: 175 }}>
        {calendarName}
      </td>
      {showIndicators && (
        <>
          <td align="center">{getIcon(write)}</td>
          <td align="center">{getIcon(read)}</td>
          <td align="center">{getIcon(recommendation)}</td>
          <td align="center">{getIcon(insertAsFullTimeEvents)}</td>
          <td align="center">{getIcon(useCustomStateLabels)}</td>
        </>
      )}
      <td style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', right: 0, bottom: 10 }}>
          <GoogleCalendarActions
            googleCalendar={googleCalendar}
            updateHandler={updateHandler}
            removeHandler={removeHandler}
          />
        </div>
      </td>
    </tr>
  );
};

export default GoogleCalendarItem;
