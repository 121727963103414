import { SevdeskConfigDTO, SevdeskVoucherDTO } from '../Types/SevdeskT';
import { ajaxActions } from './AjaxActions';
import { ConstantS } from './ConstantS';
import { MixpanelS } from './MixpanelS';
import { ToastS } from './ToastS';

const BASE_URL = `${process.env.REACT_APP_LEXOFFICE_SERVICE_URL}/sevdesk`;

const fetchConfig = async (): Promise<SevdeskConfigDTO | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/config`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const editConfig = async (patch: Partial<SevdeskConfigDTO>): Promise<SevdeskConfigDTO | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/config`, patch);
  if (res.ok) {
    if (patch.apiKey && patch.apiKey !== '') {
      ToastS.success('sevdesk-setup', '🎉 sevDesk erfolgreich eingerichtet');
      MixpanelS.track(ConstantS.TrackingEvents.SevdeskAccountConnected);
    }
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const syncAsVoucher = async (
  documentId: number,
  interceptViolation?: (httpResponse: Response) => void,
): Promise<SevdeskVoucherDTO | null> => {
  const res = await ajaxActions.put(`${BASE_URL}/voucher?documentId=${documentId}`);
  if (res.ok) {
    ToastS.success('sevdesk-transmit-completed', 'Erfolgreich in sevDesk übertragen');
    MixpanelS.track(ConstantS.TrackingEvents.SevdeskInvoiceSynced);
    return res.json();
  }
  if (res.status === 400 && interceptViolation) {
    interceptViolation(res);
  } else {
    ToastS.generalError();
  }
  return null;
};

const getVoucherDetails = async (documentId: number): Promise<SevdeskVoucherDTO | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/documents/${documentId}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

// eslint-disable-next-line import/prefer-default-export
export const SevdeskS = {
  fetchConfig,
  editConfig,
  syncAsVoucher,
  getVoucherDetails,
};
