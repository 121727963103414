/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Paper, Space, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { FC, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { AlertS } from '../../Service/AlertS';
import { AuthS } from '../../Service/restapi/authService';
import { RoutingS } from '../../Service/RoutingS';
import AlertBox from './AlertBox';
import {MixpanelS} from "../../Service/MixpanelS";
import {ConstantS} from "../../Service/ConstantS";
import {LoadingAnimation} from "../../Service/ResourceAdapter";

const AwaitAccountCreatedPage: FC = () => {
  const isPhoneDevice = useMediaQuery('(max-width: 480px)');
  const navigate: NavigateFunction = useNavigate();
  const [searchParams] = useSearchParams();
  const [alert, setAlert] = useState<string>();

  useEffect(() => {
    document.title = 'Konto wird erstellt...';
    const token = searchParams.get('submissionGuid');
    if (token) {
      let retryCount = 0;

      const pollBackend = () => {
        AuthS.confirmRegistrationAPI(token)
          .then((resp) => {
            if (resp.status === 412) {

              //success case: account was activated and user is redirected to password page
              resp.text().then((resetToken) => {
                setTimeout(() => {
                  MixpanelS.track(ConstantS.TrackingEvents.AccountActivated)
                  MixpanelS.track(ConstantS.TrackingEvents.AccountPasswordMissing)
                  navigate(`${RoutingS.ROUTES.RESET_PASSWORD}?token=${resetToken}`);
                }, 2000);
              });
            } else if (resp.status === 404) {
              // wait until account was created and verification token is available
              if (retryCount < 5) {
                retryCount++;
                setTimeout(pollBackend, 3000);
              } else {
                // fallback: guide user to click link in 'normal' verification email
                MixpanelS.track(ConstantS.TrackingEvents.AccountActivationRedirectFailed)
                navigate(`${RoutingS.ROUTES.REGISTRATION_COMPLETED}`);
              }
            }
          })
          .catch(() => setAlert(AlertS.AlertType.SERVER_UNAVAILABLE));
      };

      // Start the polling process
      setTimeout(pollBackend, 5000);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      shadow="xs"
      p={40}
      sx={{
        maxWidth: 480,
        borderRadius: 10,
        position: 'relative',
        backgroundClip: 'padding-box, border-box',
        backgroundImage: 'linear-gradient(#fff,#fff),linear-gradient(88deg,#ffba5e,#ff6b69)',
        borderTop: '10px double transparent',
      }}
    >
      <Space h="xl" />
      <Text size={isPhoneDevice ? 22 : 28}>Dein Account wird erstellt...</Text>
      <Space h="xs" />
      <Text color="dimmed" size={isPhoneDevice ? 'sm' : 'md'}>
        Das sollte nicht länger als 30 Sekunden dauern.
      </Text>
      <Space h="xl" />
      {!alert && <LoadingAnimation style={undefined}/>}
      <Space h="xs" />
      {alert && <AlertBox alert={alert} />}
      <Space h="xl" />
    </Paper>
  );
};

export default AwaitAccountCreatedPage;
