/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Menu, ThemeIcon, Divider, Text, Button } from '@mantine/core';
import { FC, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { IconCheck, IconRefreshAlert, IconPlug, IconFileImport } from '@tabler/icons';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import BookitupDocument from '../../../Types/BookitupDocument';
import { useSevdeskConfig } from '../../../Service/hooks/useSevdeskConfig';
import { SevdeskVoucherDTO } from '../../../Types/SevdeskT';
import { SevdeskS } from '../../../Service/SevdeskS';
import { LicenceS } from '../../../Service/LicenceS';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { DocumentContext } from '../../../Service/Context/DocumentContext';
import { DocumentS } from '../../../Templates/Documents/DocumentS';
import { ViolationS } from '../../../Service/ViolationS';

interface Props {
  bookingDocument: BookitupDocument;
  setLastModified: (d: Date) => void;
  dropDownVesion?: boolean;
}

const SevdeskSyncButton: FC<Props> = ({ bookingDocument, setLastModified, dropDownVesion }) => {
  const { id: documentId, draft } = bookingDocument;
  const navigate = useNavigate();
  const { interceptViolation, openErrorModal, executeWithLicenceCheck } = useContext(ViolationContext);
  const { setTransmittingDocument, handleChange } = useContext<any>(DocumentContext);
  const { config } = useSevdeskConfig();
  const { apiKey, setupAt } = config ?? {};
  const [voucher, setVoucher] = useState<SevdeskVoucherDTO | null>(null);
  const [loading, setLoading] = useState(false);
  const enabled = apiKey && setupAt;

  useEffect(() => {
    if (enabled) {
      setLoading(true);
      SevdeskS.getVoucherDetails(documentId)
        .then(setVoucher)
        .finally(() => setLoading(false));
    }
  }, [enabled, documentId]);

  const transmit = async () => {
    if (!enabled) {
      openErrorModal(ViolationS.Violations.SEVDESK_NOT_CONNECTED);
      return;
    }
    try {
      setLoading(true);
      setTransmittingDocument(true);
      if (draft) {
        const patched = await DocumentS.patchDocument(documentId, { draft: false }, interceptViolation);
        if (patched === null) {
          return;
        }
        handleChange({ draft: false });
        setLastModified(new Date());
      }
      const voucherDetails = await SevdeskS.syncAsVoucher(documentId, interceptViolation);
      if (voucherDetails) {
        setVoucher(voucherDetails);
      }
    } finally {
      setLoading(false);
      setTransmittingDocument(false);
    }
  };

  const upToDate = voucher !== null && moment(voucher.lastPublished) > moment(bookingDocument.lastModified);

  const getIcon = () => {
    if (voucher) {
      if (upToDate) {
        return <IconCheck size={14} />;
      }
      return <IconRefreshAlert size={14} />;
    }
    return <IconPlug size={14} />;
  };

  const getDescription = () => {
    if (voucher) {
      if (upToDate) {
        return (
          <>
            Einnahme übertragen{'  '}
            <Moment date={voucher.lastPublished} format="DD.MM.YY" />
          </>
        );
      }
      return <>Nicht auf dem neuesten Stand</>;
    }
    return (
      <Text span variant="link" onClick={() => navigate('/settings/bookkeeping')} sx={{ cursor: 'pointer' }}>
        Jetzt einrichten
      </Text>
    );
  };

  return (
    <>
      {dropDownVesion ? (
        <Menu.Item icon={<IconFileImport size={16} />} onClick={transmit} disabled={upToDate}>
          zu sevDesk übertragen
        </Menu.Item>
      ) : (
        <div>
          <img src="/img/sevdesk_icon.ico" width={24} height={24} style={{ marginLeft: 15, marginRight: 15 }} />
          <Button
            onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.SEVDESK, transmit)}
            disabled={upToDate || loading}
            color="red"
            sx={{ backgroundColor: '#FB523B' }}
          >
            zu sevDesk übertragen
          </Button>
          {voucher && (
            <Text align="center" size="xs" mt="xs">
              <ThemeIcon radius="xl" size="sm" mr="sm">
                {getIcon()}
              </ThemeIcon>
              {getDescription()}
            </Text>
          )}
        </div>
      )}
      <Divider />
    </>
  );
};

export default SevdeskSyncButton;
