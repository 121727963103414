import { ReactNode } from 'react';
import moment from 'moment';
import { i18n } from '@lingui/core';
import {
  ProcedureType,
  PatchWorkflowStepTemplate,
  WorkflowStepTemplateDTO,
  WorkflowTemplateDTO,
  EntityReferenceDTO,
  OccurrenceType,
  WorkflowDTO,
  WorkflowStepDTO,
  WorkflowOperation,
} from '../Types/LogT';
import { ajaxActions } from './AjaxActions';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_LOG_SERVICE_URL;

const fetchTemplates = async (): Promise<WorkflowTemplateDTO[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/workflow/templates`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchRunnableTemplates = async (eventId: string): Promise<WorkflowTemplateDTO[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/workflow/templates/runnable?eventId=${eventId}`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const createWorkflow = async (eventId: string, templateIds: string[], available = true): Promise<boolean> => {
  const res = await ajaxActions.post(`${BASE_URL}/workflow?eventId=${eventId}&available=${available}`, templateIds);
  if (res.status === 201) {
    ToastS.info('foo', 'Workflow erfolgreich erstellt');
    return true;
  }
  ToastS.generalError();
  return false;
};

const runWorkflowOperation = async (id: string, operation: WorkflowOperation): Promise<WorkflowDTO | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/workflow/${id}/${operation}`);
  if (res.ok) {
    ToastS.info('workflow-op', i18n._(`workflow.operation.success.${operation}`));
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const deleteWorkflow = async (workflow: WorkflowDTO): Promise<boolean> => {
  const { id, name } = workflow;
  const res = await ajaxActions.del(`${BASE_URL}/workflow/${id}`);
  if (res.status === 204) {
    ToastS.info('foo', `Workflow ${name} gelöscht`);
    return true;
  }
  ToastS.generalError();
  return false;
};

const fetchWorkflows = async (eventId: string): Promise<WorkflowDTO[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/workflow?eventId=${eventId}`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchWorkflowsByTemplate = async (workflowTemplateId: string): Promise<WorkflowDTO[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/workflow?workflowTemplateId=${workflowTemplateId}`);
  if (res.ok) {
    return res.json();
  }
  return [];
};


const fetchWorkflow = async (id: string): Promise<WorkflowDTO | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/workflow/${id}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchTemplate = async (id: string): Promise<WorkflowTemplateDTO | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/workflow/templates/${id}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const editTemplate = async (
  id: string,
  patch: Partial<WorkflowTemplateDTO>,
  interceptViolation: (httpResponse: Response) => void,
): Promise<WorkflowTemplateDTO | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/workflow/templates/${id}`, patch);
  if (res.ok) {
    return res.json();
  }
  interceptViolation(res);
  return null;
};

const createTemplate = async (name: string): Promise<WorkflowTemplateDTO | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/workflow/templates`, { name });
  if (res.status === 201) {
    ToastS.info('foo', `Workflow-Vorlage: ${name} erstellt`);
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const copyTemplate = async (originId: string): Promise<WorkflowTemplateDTO | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/workflow/templates/${originId}/copy`);
  if (res.status === 201) {
    ToastS.info('foo', `Vorlage dupliziert`);
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const deleteTemplate = async (
  template: WorkflowTemplateDTO,
  interceptViolation: (httpResponse: Response) => void,
): Promise<boolean> => {
  const { id, name } = template;
  const res = await ajaxActions.del(`${BASE_URL}/workflow/templates/${id}`);
  if (res.status === 204) {
    ToastS.info('template-deleted', `Vorlage: ${name} wurde gelöscht`);
    return true;
  }
  interceptViolation(res);
  return false;
};

const addWorkflowStep = async (templateId: string, alternative: boolean, alternativeFlow: boolean, index?: number) => {
  let targetUrl = `${BASE_URL}/workflow/templates/${templateId}/steps?alternativeStep=${alternative}`;
  if (index) {
    targetUrl += `&index=${index}`;
  }
  if (alternativeFlow) {
    targetUrl += `&alternativeFlow=${alternativeFlow}`;
  }
  const res = await ajaxActions.post(targetUrl, {});
  if (res.status === 201) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const editWorkflowStep = async (
  stepId: string,
  patch: Partial<PatchWorkflowStepTemplate>,
  interceptViolation?: (httpResponse: Response) => void,
): Promise<WorkflowStepTemplateDTO | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/workflow/steps/${stepId}`, patch);
  if (res.ok) {
    return res.json();
  }
  interceptViolation && interceptViolation(res);
  return null;
};

const deleteWorkflowStep = async (
  stepId: string,
  interceptViolation: (httpResponse: Response) => void,
): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/workflow/templates/steps/${stepId}`);
  if (res.status === 204) {
    ToastS.info('step-removed', 'Schritt entfernt');
    return true;
  }
  interceptViolation(res);
  return false;
};

const fetchProcedureConfig = async (type: ProcedureType, configId: string | null) => {
  const res = await ajaxActions.get(`${BASE_URL}/procedure/${type}/config/${configId}`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const patchProcedureConfig = async (type: ProcedureType, configId: string | null, patch: unknown) => {
  const res = await ajaxActions.patch(`${BASE_URL}/procedure/${type}/config/${configId}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const patchEntityReference = async (
  id: string,
  patch: Partial<EntityReferenceDTO>,
): Promise<EntityReferenceDTO | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/entity-reference/${id}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const filterEntities = (entities: EntityReferenceDTO[] | undefined, types: string[]) => {
  if (!entities) return [];
  return entities.filter((entity) => types.includes(entity.entityType));
};

const getOccurrenceType = (type: OccurrenceType | null) => {
  if (type === 'CONTRACT_SIGNED') {
    return 'contracts';
  }
  if (type === 'OFFER_CONFIRMED') {
    return 'documents';
  }
  if (type === 'QUESTIONNAIRE_FILLED') {
    return 'questionnaires';
  }
  if (type === 'MAIL_RECEIVED') {
    return 'contacts';
  }
  return ''; // TODO
};

const alternativeStepDefined = (steps: WorkflowStepTemplateDTO[], index: number) =>
  steps.length > index && steps[index].alternativeStep;

const getTimelineItemColor = (step: WorkflowStepDTO) => {
  const { executionReport } = step;
  const { executionStatus } = executionReport ?? {};

  if (executionStatus === 'FAILED') {
    return 'red';
  }
  if (executionStatus === 'COMPLETED' || executionStatus === 'FULFILLED') {
    return 'blue';
  }
  if (executionStatus === 'WAITING') {
    return 'orange';
  }
  return 'gray';
};

const getWorkflowDescription = (workflow: WorkflowDTO): ReactNode => {
  const { steps, completedAt, startedAt, pausedAt, currentStep, finishedPrematurely, executionStatus } = workflow;
  const nextStep = steps[currentStep];
  const failed = executionStatus === 'FAILED' || executionStatus === 'PRECONDITION_FAILED';

  if (steps.length === 0) {
    return 'Keine Schritte, die ausgeführt werden müssen';
  }

  if (failed) {
    const { executionReport } = nextStep;
    const { workflowError } = executionReport ?? {};
    return `${i18n._(`actionType.${nextStep?.procedureType}`)} fehlgeschlagen. ${
      workflowError ? i18n._(`workflow.precondition.failed.${workflowError}`) : ''
    }`;
  }
  if (executionStatus === 'ABORTED') {
    return 'Der Workflow wurde abgebrochen. Die Konfiguration ist ungültig.';
  }

  if (pausedAt) {
    return `Angehalten um ${moment(pausedAt).fromNow()}`;
  }
  if (!startedAt) {
    return 'Noch nicht gestartet';
  }
  if (!completedAt) {
    const { procedureType, executionConfig } = nextStep;
    if (procedureType === 'WAIT_UNTIL') {
      const { typeOfInterest, entityOfInterest } = executionConfig;
      if (!entityOfInterest) {
        return 'Ungültige Konfiguration';
      }
      return `Nächster Schritt: ${i18n._(`waitUntil.${entityOfInterest.entityType}.${typeOfInterest}`)}`;
    }
    return `Nächster Schritt: ${i18n._(`actionType.${nextStep?.procedureType}`)}${
      executionStatus === 'APPROVAL_REQUIRED' ? '. Genehmigung erforderlich.' : ''
    }`;
  }
  if (finishedPrematurely) {
    return 'Vorzeitig beendet';
  }
  return `Erfolgreich abgeschlossen ${moment(completedAt).fromNow()}`;
};

const getExecutedProcedures = (prev: WorkflowDTO, current: WorkflowDTO): ProcedureType[] => {
  if (prev.currentStep === current.currentStep) {
    return [];
  }
  return prev.steps.slice(prev.currentStep, current.currentStep).map((step) => step.procedureType);
};

const getProgressBarColor = (workflow: WorkflowDTO) => {
  const { pausedAt, executionStatus, finishedPrematurely, completedAt, steps } = workflow;
  const failed = executionStatus === 'FAILED' || executionStatus === 'PRECONDITION_FAILED';

  if (failed || executionStatus === 'ABORTED') {
    return '#FF8D8C';
  }
  if (pausedAt || steps.length === 0) {
    return 'gray';
  }
  if (completedAt && !finishedPrematurely) {
    return '#4EBF46';
  }
  if (completedAt && finishedPrematurely) {
    return '#FDA632';
  }
  return 'blue';
};

// eslint-disable-next-line import/prefer-default-export
export const WorkflowS = {
  fetchWorkflows,
  fetchWorkflowsByTemplate,
  fetchWorkflow,
  fetchRunnableTemplates,
  createWorkflow,
  runWorkflowOperation,
  deleteWorkflow,
  fetchTemplates,
  fetchTemplate,
  copyTemplate,
  editTemplate,
  createTemplate,
  deleteTemplate,
  addWorkflowStep,
  editWorkflowStep,
  fetchProcedureConfig,
  patchProcedureConfig,
  deleteWorkflowStep,
  filterEntities,
  getOccurrenceType,
  alternativeStepDefined,
  getTimelineItemColor,
  patchEntityReference,
  getWorkflowDescription,
  getExecutedProcedures,
  getProgressBarColor,
};
