/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Button, Center, Checkbox, Loader, Text, Textarea } from '@mantine/core';
import { i18n } from '@lingui/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { FC, useState } from 'react';
import { IconMessage } from '@tabler/icons';
import { RecommendationS } from '../../../../Service/RecommendationS';

interface Props {
  removeRecommendation: (recommendationId: string) => void;
  removeAllRecommendations: () => void;
  recommendationId?: string;
  eventId?: number;
  contactEmail: string | null | undefined;
}

const RecommendationDeleteModal: FC<Props> = ({
  recommendationId,
  eventId,
  removeRecommendation,
  removeAllRecommendations,
  contactEmail,
}) => {
  const form = useForm({
    initialValues: {
      sendMail: false,
      optionalMessage: '',
    },
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    const { sendMail, optionalMessage } = form.values;
    if (recommendationId) {
      RecommendationS.remove(recommendationId, sendMail, optionalMessage)
        .then((success) => success && removeRecommendation(recommendationId))
        .finally(() => {
          setLoading(false);
          closeAllModals();
        });
    } else if (eventId) {
      RecommendationS.removeEventRecommendations(eventId, sendMail, optionalMessage)
        .then(removeAllRecommendations)
        .finally(() => {
          setLoading(false);
          closeAllModals();
        });
    }
  };

  const { sendMail } = form.values;

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Text mb="xl">{i18n._('event.recommendations.delete.confirm')}</Text>
      <Text>Soll der Dienstleister darüber per E-Mail benachrichtigt werden?</Text>
      <Checkbox
        size="xs"
        mt="md"
        label="Ja, Dienstleister per E-Mail benachrichtigen"
        disabled={loading || !contactEmail}
        {...form.getInputProps('sendMail', { type: 'checkbox' })}
      />
      {sendMail && (
        <>
          <Textarea
            icon={<IconMessage size={16} />}
            maxRows={10}
            variant="filled"
            mt="xs"
            placeholder="Optionale Nachricht an den Dienstleister"
            disabled={loading}
            {...form.getInputProps('optionalMessage')}
          />
          <Text mt="xs" size="xs" color="dimmed">
            Hinweis: Diese Nachricht wird zur Zeit noch nicht im System gespeichert - Du kannst Sie also im nicht noch
            einmal einsehen.
          </Text>
        </>
      )}
      <Center>
        <Box sx={{ width: '60%' }}>
          <Button
            fullWidth
            mt={40}
            mb="xs"
            color="red"
            variant="subtle"
            sx={{ backgroundColor: '#FFEFEF' }}
            type="submit"
            disabled={loading}
            leftIcon={loading && <Loader size="xs" />}
          >
            {i18n._('Vermittlung löschen')}
          </Button>
        </Box>
      </Center>
    </form>
  );
};

export default RecommendationDeleteModal;
