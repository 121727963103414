import { FC } from 'react';
import {
  IconCalendarPlus,
  IconCalendarEvent,
  IconSignature,
  IconFileEuro,
  IconFileCheck,
  IconFileX,
  IconFileDescription,
  IconCalendar,
  IconMailOff,
  IconMail,
  IconClock,
  IconChecklist,
  Icon360,
  IconFilePlus,
  IconAffiliate,
  IconShare,
  IconListCheck,
  IconCircleCheck,
  IconSend,
  IconUserCheck,
  IconTrash,
  IconPlaylistX,
  IconCalendarOff,
  IconAt,
} from '@tabler/icons';
import { BookitupNotification, ProcedureType } from '../Types/LogT';
import { BookingState, RequestAnswer } from '../Types/RecommendationT';
import { BlueGradientIcon, GradientIcon, RedGradientIcon, GreenGradientIcon, OrangeGradientIcon } from './ThemeIcons';
import ActionTypeIcon from './ActionTypeIcon';

const BookitupNotificationIcon: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { occurrenceType, value } = notification;
  if (occurrenceType === 'EVENT_CREATED' || occurrenceType === 'REQUEST_RECEIVED') {
    return (
      <BlueGradientIcon>
        <IconCalendarPlus color="white" size={20} />
      </BlueGradientIcon>
    );
  }
  if (occurrenceType === 'EVENT_STATE_CHANGED' || occurrenceType === 'RECOMMENDATION_STATE_CHANGED') {
    const value = notification.value as BookingState;
    let color = 'blue';
    if (value === 'BOOKED') {
      color = 'green';
    }
    if (value === 'CANCELED') {
      color = 'red';
    }
    if (value === 'UNAVAILABLE') {
      color = 'gray';
    }
    if (value === 'OFFERED') {
      color = 'orange';
    }
    return (
      <GradientIcon color={color}>
        <IconCalendarEvent color="white" size={20} />
      </GradientIcon>
    );
  }
  if (occurrenceType === 'CONTRACT_SIGNED') {
    return (
      <BlueGradientIcon>
        <IconSignature color="white" size={20} />
      </BlueGradientIcon>
    );
  }
  if (occurrenceType === 'INVOICE_NOT_PAID') {
    return (
      <RedGradientIcon>
        <IconFileEuro color="white" size={20} />
      </RedGradientIcon>
    );
  }
  if (occurrenceType === 'OFFER_CONFIRMED') {
    return (
      <BlueGradientIcon>
        <IconFileCheck color="white" size={20} />
      </BlueGradientIcon>
    );
  }
  if (occurrenceType === 'OFFER_OVERDUE') {
    return (
      <RedGradientIcon>
        <IconFileX color="white" size={20} />
      </RedGradientIcon>
    );
  }
  if (occurrenceType === 'QUESTIONNAIRE_FILLED') {
    return (
      <BlueGradientIcon>
        <IconFileDescription color="white" size={20} />
      </BlueGradientIcon>
    );
  }
  if (occurrenceType === 'RECOMMENDATION_AVAILABILITY_CHANGED') {
    const val = notification.value as RequestAnswer;
    let col = 'blue';
    if (val === 'AVAILABLE') {
      col = 'green';
    }
    if (val === 'UNAVAILABLE' || val === 'UNFIT') {
      col = 'red';
    }
    if (val === 'BLOCKED') {
      col = 'orange';
    }
    if (val === 'UNKNOWN') {
      col = 'gray';
    }
    return (
      <GradientIcon color={col}>
        <IconCalendar color="white" size={20} />
      </GradientIcon>
    );
  }
  if (occurrenceType === 'SCHEDULED_MAIL_FAILED') {
    return (
      <RedGradientIcon>
        <IconMailOff color="white" size={20} />
      </RedGradientIcon>
    );
  }
  if (occurrenceType === 'SCHEDULED_MAIL_SUCCESSFUL') {
    return (
      <GreenGradientIcon>
        <IconMail color="white" size={20} />
      </GreenGradientIcon>
    );
  }
  if (occurrenceType === 'TASK_OVERDUE') {
    return (
      <RedGradientIcon>
        <IconClock color="white" size={20} />
      </RedGradientIcon>
    );
  }
  if (occurrenceType === 'TASK_COMPLETED') {
    return (
      <GreenGradientIcon>
        <IconChecklist color="white" size={20} />
      </GreenGradientIcon>
    );
  }
  if (
    occurrenceType === 'DOCUMENT_CREATED' ||
    occurrenceType === 'CONTRACT_CREATED' ||
    occurrenceType === 'QUESTIONNAIRE_CREATED'
  ) {
    return (
      <BlueGradientIcon>
        <IconFilePlus color="white" size={20} />
      </BlueGradientIcon>
    );
  }
  if (occurrenceType === 'PORTAL_CREATED') {
    return (
      <BlueGradientIcon>
        <IconAffiliate color="white" size={20} />
      </BlueGradientIcon>
    );
  }
  if (occurrenceType === 'ENTITY_SHARED') {
    return (
      <BlueGradientIcon>
        <IconShare color="white" size={20} />
      </BlueGradientIcon>
    );
  }
  if (occurrenceType === 'TASK_CREATED') {
    return (
      <BlueGradientIcon>
        <IconListCheck color="white" size={20} />
      </BlueGradientIcon>
    );
  }
  if (occurrenceType === 'WORKFLOW_COMPLETED') {
    return (
      <GreenGradientIcon>
        <IconCircleCheck color="white" size={20} />
      </GreenGradientIcon>
    );
  }
  if (occurrenceType === 'CONDITION_NOT_FULFILLED') {
    return (
      <RedGradientIcon>
        {value === 'CONTRACT_SIGNED' && <IconSignature color="white" size={20} />}
        {value === 'OFFER_CONFIRMED' && <IconFileCheck color="white" size={20} />}
        {value === 'QUESTIONNAIRE_FILLED' && <IconFileDescription color="white" size={20} />}
        {value === 'MAIL_RECEIVED' && <IconMail color="white" size={20} />}
        {value === 'OFFER_OVERDUE' && <IconFileX color="white" size={20} />}
        {value === 'INVOICE_NOT_PAID' && <IconFileEuro color="white" size={20} />}
      </RedGradientIcon>
    );
  }
  if (occurrenceType === 'PROCEDURE_FAILED') {
    return (
      <RedGradientIcon>
        <ActionTypeIcon actionType={value as ProcedureType} size={20} />
      </RedGradientIcon>
    );
  }
  if (occurrenceType === 'MAIL_SENT') {
    return (
      <BlueGradientIcon>
        <IconSend color="white" size={20} />
      </BlueGradientIcon>
    );
  }
  if (occurrenceType === 'APPROVAL_REQUIRED') {
    return (
      <OrangeGradientIcon>
        <IconUserCheck color="white" size={20} />
      </OrangeGradientIcon>
    );
  }

  if (occurrenceType === 'TASK_DELETED') {
    return (
      <BlueGradientIcon>
        <IconTrash color="white" size={20} />
      </BlueGradientIcon>
    );
  }

  if (occurrenceType === 'TASKS_DELETED') {
    return (
      <BlueGradientIcon>
        <IconPlaylistX color="white" size={20} />
      </BlueGradientIcon>
    );
  }

  if (occurrenceType === 'MAIL_RECEIVED') {
    return (
      <BlueGradientIcon>
        <IconMail color="white" size={20} />
      </BlueGradientIcon>
    );
  }

  if (occurrenceType === 'EXTERNAL_EMAIL_CONNECTED') {
    return (
      <BlueGradientIcon>
        <IconAt color="white" size={20} />
      </BlueGradientIcon>
    );
  }

  if (occurrenceType === 'EXTERNAL_CALENDAR_UNREACHABLE') {
    return (
      <RedGradientIcon>
        <IconCalendarOff color="white" size={20} />
      </RedGradientIcon>
    );
  }

  return (
    <RedGradientIcon>
      <Icon360 />
    </RedGradientIcon>
  );
};

export default BookitupNotificationIcon;
