/* eslint-disable react/jsx-no-useless-fragment */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Loader, Skeleton, Stack, Text } from '@mantine/core';
import { useEffect } from 'react';
import { i18n } from '@lingui/core';
import { TokenResponse } from '@react-oauth/google';
import PanePaper from '../../../Molecules/PanePaper';
import { GoogleS } from '../../../Service/GoogleS';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { openGoogleOAuthModal } from '../Calendar/GoogleOAuthModal';
import { MixpanelS } from '../../../Service/MixpanelS';
import { ConstantS } from '../../../Service/ConstantS';
import { useGoogleAccounts } from '../../../Service/hooks/useGoogleAccounts';
import GoogleAccount from './GoogleAccount';

const GoogleSection = () => {
  const { loading, googleAccounts, refresh, removeAccount, editAccount } = useGoogleAccounts();
  const migrationRequired = googleAccounts.length === 1 && !googleAccounts[0].email;

  useEffect(() => {
    document.title = 'Google-Sync';
  }, []);

  const manageGoogleAccount = (migration = false) => {
    const completeOAuth = async (tokenResponse: Partial<TokenResponse>) => {
      const success = await GoogleS.completeOAuthProcess(tokenResponse, migration);
      if (success) {
        refresh();
        MixpanelS.track(ConstantS.TrackingEvents.GoogleAccountAdded);
      }
    };
    openGoogleOAuthModal(completeOAuth);
  };

  return (
    <PanePaper
      title="Google-Synchronisierung"
      rightSection={
        loading ? (
          <Loader variant="dots" />
        ) : (
          <>{!migrationRequired && <BookitupAddIcon onClick={() => manageGoogleAccount(false)} />}</>
        )
      }
      sx={{ maxWidth: 650 }}
    >
      <Text mb="md">{i18n._('settings.google.section-description')}</Text>
      {loading ? (
        <Skeleton width="100%" height={500} />
      ) : (
        <div>
          {googleAccounts.length === 0 && (
            <Text italic color="dimmed" size="xs" weight="lighter" mt="md">
              Du hast noch keinen Google-Account?{' '}
              <Text
                span
                component="a"
                color="blue"
                href="https://accounts.google.com/signup/v2/createaccount?cc=DE&dsh=S-1610319890%3A1686719935456397&flowEntry=SignUp"
                target="_blank"
                sx={{ cursor: 'pointer' }}
              >
                Hier
              </Text>{' '}
              kannst du Dir einen kostenlosen Account erstellen, den Du auch auf deinem iPhone / Android / PCs
              einrichten kannst.
            </Text>
          )}
          <Stack>
            {googleAccounts.map((googleAccount) => (
              // eslint-disable-next-line react/jsx-key
              <GoogleAccount
                manageGoogleAccount={manageGoogleAccount}
                googleAccount={googleAccount}
                handleChange={editAccount}
                removeAccount={() => removeAccount(googleAccount.id)}
              />
            ))}
          </Stack>
        </div>
      )}
    </PanePaper>
  );
};

export default GoogleSection;
