// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Avatar, Group, Stack, Text } from '@mantine/core';
import { FC } from 'react';
import { GoogleAccountDTO } from '../../../Types/GoogleT';
import PanePaper from '../../../Molecules/PanePaper';
import GooglePeoplePane from './GooglePeoplePane';
import GoogleTasksPane from './GoogleTasksPane';
import GoogleCalendarPane from './GoogleCalendarPane';
import GoogleAccountActions from './GoogleAccountActions';
import GoogleMigrationAlert from './GoogleMigrationAlert';
import GoogleGmailPane from './GoogleGmailPane';

interface Props {
  googleAccount: GoogleAccountDTO;
  removeAccount: () => void;
  handleChange: (googleAccount: GoogleAccountDTO) => void;
  manageGoogleAccount: (migration?: boolean) => void;
}

const GoogleAccount: FC<Props> = ({ googleAccount, removeAccount, handleChange, manageGoogleAccount }) => {
  const { id, email, photoUrl } = googleAccount;

  return (
    <PanePaper
      rightSection={
        <GoogleAccountActions accountId={id} manageGoogleAccount={manageGoogleAccount} removeAccount={removeAccount} />
      }
    >
      <Group mb="sm">
        <Avatar radius="xl" size="md" src={photoUrl} />
        <div>
          <Text size="xs" color="dimmed">
            E-Mail
          </Text>
          <Text weight="bolder">{email}</Text>
        </div>
      </Group>
      {!email && <GoogleMigrationAlert migrate={() => manageGoogleAccount(true)} />}
      <Stack spacing="xs">
        <GooglePeoplePane googleAccount={googleAccount} handleChange={handleChange} />
        <GoogleTasksPane googleAccount={googleAccount} handleChange={handleChange} />
        <GoogleCalendarPane googleAccount={googleAccount} />
        {/* <GoogleGmailPane googleAccount={googleAccount} /> */}
      </Stack>
    </PanePaper>
  );
};

export default GoogleAccount;
