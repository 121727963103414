import { createContext, FC, useState, useEffect, ReactNode, useMemo, useContext } from 'react';
import { useLocation, NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { decode } from 'js-base64';
import { LoadingOverlay } from '@mantine/core';
import { AuthS } from '../restapi/authService';
import { RoutingS } from '../RoutingS';
import { CookiesS } from '../CookiesS';

export type Licence =
  | 'BASIC'
  | 'PRO'
  | 'PRO_TRIAL_30DAYS'
  | 'FLOW'
  | 'FLOW_TRIAL_30DAYS'
  | 'INTERACTIVE'
  | 'INTERACTIVE_TRIAL_30DAYS';

export interface JwtPayloadInterface {
  email: string;
  exp: number;
  licence: Licence;
  supportLogin: false;
  profileId: number;
  trialEndsAt: Date;
  registeredOn: Date;
  webSessions?: number | null;
}

interface AuthContextInterface {
  isAuthenticated: boolean;
  jwtClaims: JwtPayloadInterface;
  refreshToken: () => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface);

export const AuthContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [payload, setPayload] = useState<string | null>(CookiesS.getCookiePayload());
  const [jwtClaims, setJwtClaims] = useState<JwtPayloadInterface>({} as JwtPayloadInterface);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate: NavigateFunction = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const refreshToken = () => {
    if (jwtClaims) {
      const { email } = jwtClaims;
      if (email) {
        AuthS.refreshToken(email);
      }
    }
  };

  const logout = () => {
    setLoading(true);
    AuthS.logout().finally(() => setInterval(() => setLoading(false), 1000));
  };

  useEffect(() => {
    const tick = setInterval(() => {
      const cookiePayload = CookiesS.getCookiePayload();
      if (cookiePayload !== payload) {
        setPayload(cookiePayload);
      }
    }, 1000);
    return () => clearInterval(tick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  useEffect(() => {
    if (payload) {
      const decodedBase64 = decode(payload);
      setJwtClaims(JSON.parse(decodedBase64));
    } else {
      setJwtClaims({} as JwtPayloadInterface);
    }
  }, [payload]);

  useEffect(() => {
    if (payload) {
      refreshToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (payload) {
      if (RoutingS.isUnprotectedRoute(pathname)) {
        const redirect = searchParams.get('redirect-to');
        navigate(redirect || RoutingS.ROUTES.HOMEPAGE);
      }
    } else if (!RoutingS.isUnprotectedRoute(pathname) && !pathname.includes('recommendationRequest')) {
      if (loading) {
        navigate(RoutingS.ROUTES.LOGIN);
      } else {
        navigate(`${RoutingS.ROUTES.LOGIN}?redirect-to=${pathname}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, payload]);

  const value = useMemo(
    () => ({
      isAuthenticated: payload !== null && payload !== undefined,
      jwtClaims,
      refreshToken,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jwtClaims, payload],
  );

  return (
    <AuthContext.Provider value={value}>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      {children}
    </AuthContext.Provider>
  );
};

export const useJwtClaims = (): JwtPayloadInterface => {
  const { jwtClaims } = useContext(AuthContext);
  return jwtClaims;
};
