/* eslint-disable no-underscore-dangle */
import { CalendarApi, EventContentArg, EventSourceFuncArg } from '@fullcalendar/core';
import { Text, Tooltip } from '@mantine/core';
import { EventsFilter } from '../Types/Event';
import { ExternalSubscription } from '../Types/CalDavT';
import { GoogleCalendarDto } from '../Types/GoogleT';
import { AgendaS } from './restapi/taskService';
import { EventS } from './EventS';
import { CalDavS } from './CalDavS';
import { GoogleS } from './GoogleS';

const BOOKITUP_EVENT_SOURCE = 'bookitup_event_source';
export const TASKS_EVENT_SOURCE = 'tasks_event_source';

// Subscribe for bookitup events. Re-attachment of event source is required.
// Unfortunately filter state changes are not propagated in event source config
const addBookitupEvents = (calendar: CalendarApi, filter: EventsFilter) => {
  removeEventSource(calendar, BOOKITUP_EVENT_SOURCE);
  calendar.addEventSource({
    id: BOOKITUP_EVENT_SOURCE,
    events: (argv: EventSourceFuncArg) => {
      const { start, end } = argv;
      return EventS.fetchCalendarEvents({ ...filter, from: start, to: end });
    },
  });
};

// Subscribe for tasks event source
const addAgendaEventSource = (calendar: CalendarApi) => {
  removeEventSource(calendar, TASKS_EVENT_SOURCE);
  calendar.addEventSource({
    id: TASKS_EVENT_SOURCE,
    events: (argv: EventSourceFuncArg) => {
      const { start, end } = argv;
      return AgendaS.getCalendarItems(start, end, true);
    },
  });
};

// Toggle visibility with filter functionality
const setEventSourceVisibility = (calendar: CalendarApi, publicId: string, value: string) => {
  const eventSource = calendar.getEventSourceById(publicId) as any;
  if (eventSource) {
    calendar
      .getEvents()
      .filter((e: any) => e._def.sourceId === eventSource.internalEventSource.sourceId)
      .forEach((e) => e.setProp('display', value));
  }
};

const removeEventSource = (calendar: CalendarApi, publicId: string) => {
  const eventSource = calendar.getEventSourceById(publicId);
  if (eventSource) {
    eventSource.remove();
  }
};

const addExternalCalsAsEventSource = (calendar: CalendarApi, cals: ExternalSubscription[], invalidateCache = false) =>
  cals.filter((cal) => cal.healthy).forEach((cal) => addExternalCal(calendar, cal, invalidateCache));

const addExternalCal = (calendar: CalendarApi, cal: ExternalSubscription, invalidateCache = false) => {
  const { id, color } = cal;
  removeEventSource(calendar, id);
  calendar.addEventSource({
    id,
    url: `${CalDavS.getSubscriptionUrl(id)}?invalidateCache=${invalidateCache}`,
    format: 'ics',
    color,
  });
};

const markAsCompleted = (calendar: CalendarApi, taskId: string) => {
  const taskEvent = calendar.getEventById(taskId);
  if (taskEvent) {
    taskEvent.setExtendedProp('completed', true);
  }
};

const addGoogleCals = (calendar: CalendarApi, cals: GoogleCalendarDto[]) => {
  cals.forEach((cal) => {
    const { googleAccountId, calendarId, backgroundColor } = cal;
    calendar.addEventSource({
      id: calendarId,
      events: (argv: EventSourceFuncArg) => {
        const { start, end } = argv;
        return GoogleS.filterCalendarEvents(googleAccountId, calendarId, start, end);
      },
      color: backgroundColor,
    });
  });
};

const renderBookitupEvent = (view: string, obj: EventContentArg) => {
  const eventDetails = obj.event._def;
  const { emoji, kindLabel } = eventDetails.extendedProps;

  if (view === 'timeGridWeek' || view === 'timeGridDay') {
    return (
      <Tooltip label="Details öffnen">
        <div className="fc-event-main-frame" style={{ paddingLeft: 5 }}>
          <div className="fc-event-time">{obj.timeText}</div>
          <div className="fc-event-title-container">
            <div className="fc-event-title fc-sticky">
              {emoji && emoji} {kindLabel && <b>{kindLabel}:</b>} {obj.event._def.title}
            </div>
          </div>
        </div>
      </Tooltip>
    );
  }
  if (view === 'listWeek') {
    return (
      <a className="overflow-ellipsis" href={obj.event._def.url} target="_blank">
        {obj.event._def.title}
      </a>
    );
  }

  return (
    <Tooltip label="Details öffnen">
      <Text pl={5} className="overflow-ellipsis">
        {obj.backgroundColor && view === 'dayGridMonth' && (
          <Text span color={obj.backgroundColor}>
            ⏺{'  '}
          </Text>
        )}
        {`${obj.timeText} ${emoji ?? '🗓️'} `}
        <Text span weight="bolder">
          {obj.event._def.title}
        </Text>
      </Text>
    </Tooltip>
  );
};

const renderPrivateEvent = (view: string, obj: EventContentArg) => {
  if (view === 'timeGridWeek' || view === 'timeGridDay') {
    return (
      <Tooltip label="Privaten Termin">
        <div className="fc-event-main-frame" style={{ paddingLeft: 5 }}>
          <div className="fc-event-time">{obj.timeText}</div>
          <div className="fc-event-title-container">
            <div className="fc-event-title fc-sticky">{obj.event._def.title}</div>
          </div>
        </div>
      </Tooltip>
    );
  }
  if (view === 'listWeek') {
    return <Text className="overflow-ellipsis">{obj.event._def.title}</Text>;
  }
  return (
    <Tooltip label="Privaten Termin">
      <Text pl={5} className="overflow-ellipsis">
        {obj.backgroundColor && (
          <Text span color={obj.backgroundColor}>
            ⏺{'  '}
          </Text>
        )}
        {`${obj.timeText} `}
        <Text span weight="bolder">
          {obj.event._def.title}
        </Text>
      </Text>
    </Tooltip>
  );
};

const renderTask = (obj: EventContentArg, onClick: () => unknown) => {
  const eventDetails = obj.event._def;
  const { completed, priority } = eventDetails.extendedProps;
  // eslint-disable-next-line no-nested-ternary
  const taskEmoji = completed ? '✔️' : priority ? '❗' : '🛠️';
  return (
    <Tooltip label="Details anzeigen">
      <Text
        pl={5}
        className="overflow-ellipsis hover-class"
        onClick={onClick}
        sx={{ cursor: 'pointer' }}
        strikethrough={completed}
        role="button"
        tabIndex={0}
      >{`${taskEmoji} ${obj.event._def.title}`}</Text>
    </Tooltip>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const CalendarS = {
  addBookitupEvents,
  addAgendaEventSource,
  setEventSourceVisibility,
  markAsCompleted,
  removeEventSource,
  addExternalCalsAsEventSource,
  addExternalCal,
  addGoogleCals,
  renderBookitupEvent,
  renderPrivateEvent,
  renderTask,
};
