import { Stack } from '@mantine/core';
import EventsMonthPane from '../../Molecules/Eventlist/EventsMonthPane';

const EventsSortedMonthly = ({ eventDates }) => {
  if (!eventDates) {
    return null;
  }

  const monthYearArray = [];

  eventDates.forEach((eventDate) => {
    const date = new Date(eventDate.start);
    const monthYear = { year: date.getFullYear(), month: date.getMonth() + 1 };
    if (!monthYearArray.find((mY) => mY.year === monthYear.year && mY.month === monthYear.month)) {
      monthYearArray.push(monthYear);
    }
  });
  monthYearArray.sort((mY1, mY2) => new Date(mY1.year, mY1.month) - new Date(mY2.year, mY2.month));

  function eventOfMonth(eventDate, monthYear) {
    const tmpDate = new Date(eventDate.start);
    return tmpDate.getMonth() + 1 === monthYear.month && tmpDate.getFullYear() === monthYear.year;
  }

  return (
    <Stack spacing={10}>
      {monthYearArray.map((monthYear, index) => (
        <EventsMonthPane
          key={index}
          month={monthYear.month}
          year={monthYear.year}
          eventDates={eventDates.filter((e) => eventOfMonth(e, monthYear))}
        />
      ))}
    </Stack>
  );
};

export default EventsSortedMonthly;
