import { Box, Center, Flex, Skeleton, SkeletonProps, Stack, Text } from '@mantine/core';
import { FC } from 'react';

interface Props extends SkeletonProps {
  count?: number;
}

const SkeletonItems: FC<Props> = ({ count = 3, ...rest }) => (
  <div>
    {Array.from(Array(count)).map(() => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Skeleton key={Math.random()} radius="md" height={25} width="100%" mb={10} {...rest} />
    ))}
  </div>
);

export const NotificationSkeletonItems = () => (
  <Stack mt="xs">
    {Array.from(Array(4)).map(() => (
      // eslint-disable-next-line react/jsx-key
      <Flex gap="md" sx={{ maxWidth: 400 }}>
        <Skeleton circle height={40} sx={{ minWidth: 40 }} />
        <Skeleton height={50} />
      </Flex>
    ))}
  </Stack>
);

export const EmptyListSkeleton = () => (
  <Stack spacing="xs" p={5}>
    {Array.from(Array(5)).map(() => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Box sx={(theme) => ({ width: '100%', height: 80, backgroundColor: theme.colors.gray[0], borderRadius: 10 })} />
    ))}
    <Text italic color="dimmed" align="center" mb="xl" mt="xl">
      keine Emails
    </Text>
  </Stack>
);

export const EventItemSkeleton = () => (
  <Center>
    <Skeleton height={100} width={800} />
  </Center>
);

export default SkeletonItems;
