/* eslint-disable prettier/prettier */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import Mention from '@tiptap/extension-mention';
import suggestion from '../mentions/Suggestions';
import TemplatePlaceholder from './components/SettingsPlaceholder';
import EventPlaceholder from './components/EventPlaceholder';

export const SettingsPlaceholderExtension = Mention.configure({
  HTMLAttributes: {
    class: 'mention',
  },
  renderHTML({ node }) {
    return ['span', mergeAttributes(node.attrs)];
  },
  suggestion,
}).extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      class: {
        default: 'mention',
      },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(TemplatePlaceholder);
  },
});

export const EventPlaceholderExtension = SettingsPlaceholderExtension.extend({
  addNodeView() {
    return ReactNodeViewRenderer(EventPlaceholder);
  },
});
