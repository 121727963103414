// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Button, Center, Space, Stack, Text, Title } from '@mantine/core';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateEventIcon from './CreateEventIcon';

const EventsNotFound: FC<{ totalCount: number }> = ({ totalCount }) => (
  <Stack>
    <Box sx={(theme) => ({ width: '100%', height: 50, backgroundColor: theme.colors.gray[0], borderRadius: 10 })} />
    {totalCount === 0 && <AcademySection />}
    <Box sx={(theme) => ({ width: '100%', height: 100, backgroundColor: theme.colors.gray[0], borderRadius: 10 })} />
    {totalCount === 0 ? (
      <Text sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        Du hast noch kein Event erstellt. Klicke auf
        <Space w={5} />
        <CreateEventIcon />
        <Space w={2} /> um zu beginnen.
      </Text>
    ) : (
      <Text align="center">
        Die Kombination der ausgewählten <b>Filter</b> erzielt keine Ergebnisse.
      </Text>
    )}
  </Stack>
);

const AcademySection = () => {
  const navigate = useNavigate();

  return (
    <Box p="sm" sx={{ border: '2px solid #E2E2E2', borderRadius: 5 }}>
      <Title align="center" order={5} mb="sm">
        Herzlich Willkommen bei bookitup!
      </Title>
      <Text align="center" mb="sm">
        Wichtig: Starte am Besten in unserer Akademie. Dort bekommst du eine <b>Schritt für Schritt Anleitung</b>, um
        bookitup einzurichten und damit die Büroarbeit hinter deinem Event-Job deutlich zu reduzieren.
      </Text>
      <Center>
        <Button size="xs" sx={{ width: 200 }} onClick={() => navigate('/academy/welcome')}>
          Jetzt loslegen!
        </Button>
      </Center>
    </Box>
  );
};

export default EventsNotFound;
