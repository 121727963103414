// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text, Accordion } from '@mantine/core';
import { FC, useContext, useEffect, useState } from 'react';
import { IconChevronRight } from '@tabler/icons';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import PanePaper from '../../Molecules/PanePaper';
import { EventContext } from '../../Service/Context/EventContext';
import { getEventInterval } from '../../Utils/dateUtils';
import { EventResourcePlanningContext } from '../../Service/Context/EventResourcePlanningContext';
import SkeletonItems from '../../Atoms/SkeletonItems';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import { ResourceAddonContext } from '../../Service/Context/ResourceAddonsContext';
import { openPlanningGroupModal } from './EventPlanningsModals';
import EventPlanningGroup from './EventPlanningGroup';

const EventResources: FC = () => {
  const { event, dates } = useContext<any>(EventContext);
  const { loading, planningGroups, addGroup: appendGroup } = useContext(EventResourcePlanningContext);
  const [openedGroups, setOpenedGroups] = useState<string[]>([]);
  const { interceptViolation } = useContext(ViolationContext);
  const { executeWithCheck } = useContext(ResourceAddonContext);

  useEffect(() => {
    if (planningGroups.length > 0 && openedGroups.length === 0) {
      setOpenedGroups(planningGroups.map((p) => p.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planningGroups]);

  const addGroup = () => {
    const eventInterval = getEventInterval(event);
    openPlanningGroupModal({
      eventId: event.id,
      order: planningGroups.length + 1,
      eventStart: eventInterval[0],
      eventEnd: eventInterval[1],
      onFinish: appendGroup,
      interceptViolation,
      dates,
    });
  };

  const toggleAccordionItem = (itemId: string, show: boolean) => {
    if (show) {
      setOpenedGroups([...openedGroups, itemId]);
    } else {
      setOpenedGroups(openedGroups.filter((owId) => owId !== itemId));
    }
  };

  return (
    <PanePaper title="Planungsgruppen" rightSection={<BookitupAddIcon onClick={() => executeWithCheck(addGroup)} />}>
      {loading && <SkeletonItems height={100} />}
      {!loading && planningGroups.length === 0 && (
        <Text italic color="dimmed" size="xs" weight="lighter">
          Noch nichts hinzugefügt.
        </Text>
      )}
      {planningGroups.length > 0 && (
        <Accordion
          multiple
          value={openedGroups}
          defaultValue={planningGroups.map((group) => group.id)}
          ml={-10}
          mr={-10}
          chevronPosition="left"
          chevron={<IconChevronRight size="1rem" />}
          styles={{
            chevron: {
              '&[data-rotate]': {
                transform: 'rotate(90deg)',
              },
            },
          }}
        >
          {planningGroups.map((group) => (
            <EventPlanningGroup
              key={group.id}
              group={group}
              previewOpened={openedGroups.includes(group.id)}
              setShowPreview={(show: boolean) => toggleAccordionItem(group.id, show)}
            />
          ))}
        </Accordion>
      )}
    </PanePaper>
  );
};

export default EventResources;
