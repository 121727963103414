/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Divider, Menu, Text, ThemeIcon } from '@mantine/core';
import { IconCheck, IconFileImport, IconPlug, IconRefreshAlert } from '@tabler/icons';
import moment from 'moment';
import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { DocumentContext } from '../../Service/Context/DocumentContext';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import { LexOfficeConfig, LexOfficeInvoice, LexOfficeS } from '../../Service/LexOfficeS';
import { LicenceS } from '../../Service/LicenceS';
import { ToastS } from '../../Service/ToastS';
import BookitupDocument from '../../Types/BookitupDocument';
import { DocumentS } from '../../Templates/Documents/DocumentS';

const ImportButton: FC<{
  bookingDocument: BookitupDocument;
  setLastModified: (d: Date) => void;
  dropDownVesion?: boolean;
}> = ({ bookingDocument, setLastModified, dropDownVesion }) => {
  const navigate = useNavigate();
  const { interceptViolation, openErrorModal, executeWithLicenceCheck } = useContext(ViolationContext);
  const [lexOfficeConfig, setLexOfficeConfig] = useState<LexOfficeConfig>();
  const [lexOfficeInvoice, setLexOfficeInvoice] = useState<LexOfficeInvoice>();
  const [loading, setLoading] = useState<boolean>(false);
  const { id, lastModified } = bookingDocument;
  const { setTransmittingDocument, handleChange } = useContext<any>(DocumentContext);

  useEffect(() => {
    setLoading(true);
    LexOfficeS.fetchLexOfficeConfig()
      .then((config) => {
        if (config) {
          setLexOfficeConfig(config);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (lexOfficeConfig) {
      const { apiKey, connected } = lexOfficeConfig;
      if (apiKey || connected) {
        setLoading(true);
        LexOfficeS.fetchLexOfficeInvoice(id)
          .then((resp) => {
            if (resp.ok) {
              resp.json().then(setLexOfficeInvoice);
              setLastModified(new Date());
            }
          })
          .finally(() => setLoading(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, lexOfficeConfig]);

  if (!lexOfficeConfig) {
    return null;
  }

  const upToDate = lexOfficeInvoice && moment(lexOfficeInvoice.lastModified) > moment(lastModified);

  const transmit = async () => {
    if (LexOfficeS.publishInvoicePrecheck(bookingDocument, lexOfficeConfig, openErrorModal)) {
      try {
        setLoading(true);
        setTransmittingDocument(true);
        if (bookingDocument.draft) {
          const patched = await DocumentS.patchDocument(id, { draft: false }, interceptViolation);
          if (patched === null) {
            return;
          }
        }
        const loxInvoice = await LexOfficeS.publishInvoice(id, interceptViolation);
        if (loxInvoice) {
          setLexOfficeInvoice(loxInvoice);
          handleChange({ draft: false });
          setLastModified(new Date());
        }
      } finally {
        setLoading(false);
        setTransmittingDocument(false);
      }
    }
  };

  const getIcon = (): ReactNode => {
    if (lexOfficeInvoice) {
      if (upToDate) {
        return <IconCheck size={14} />;
      }
      return <IconRefreshAlert size={14} />;
    }
    return <IconPlug size={14} />;
  };

  const getDescription = (): ReactNode => {
    if (lexOfficeInvoice) {
      if (upToDate) {
        return (
          <>
            {LexOfficeS.getTypeTranslation(lexOfficeInvoice.type)} übertragen{' '}
            <Moment date={lexOfficeInvoice.lastModified} format="DD.MM.YY" />
          </>
        );
      }
      return <>Nicht auf dem neuesten Stand</>;
    }
    return (
      <Text span variant="link" onClick={() => navigate('/settings/bookkeeping')} sx={{ cursor: 'pointer' }}>
        Jetzt einrichten
      </Text>
    );
  };

  return (
    <>
      {dropDownVesion ? (
        <Menu.Item icon={<IconFileImport size={16} />} onClick={transmit} disabled={upToDate}>
          zu lexoffice übertragen
        </Menu.Item>
      ) : (
        <div>
          <img src="/img/lox_icon.ico" width={24} height={24} style={{ marginLeft: 15, marginRight: 15 }} />
          <Button
            onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.LEX_OFFICE, transmit)}
            disabled={upToDate || loading}
            sx={{ backgroundColor: '#FF4557' }}
            color="red"
          >
            zu lexoffice übertragen
          </Button>
          {lexOfficeInvoice && (
            <Text align="center" size="xs" mt="xs">
              <ThemeIcon radius="xl" size="sm" mr="sm">
                {getIcon()}
              </ThemeIcon>
              {getDescription()}
            </Text>
          )}
        </div>
      )}
      <Divider />
    </>
  );
};

export default ImportButton;
