import { Space, TextInput } from '@mantine/core';
import { FC, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from '@lingui/react';
import { MessageContext } from '../../Service/Context/MessageContextV2';
import { TransButtonDefault } from '../../Atoms/Buttons';
import AttachmentList from '../../Molecules/Message/AttachmentList';
import Attachments from '../Attachments';
import { EventBaseInput, EventRichInput, RichInputWithoutMentions } from '../tiptap/TiptapEditorInput';
import MessageToolbar from '../tiptap/toolbars/MessageToolbar';
import { MessageS } from '../../Service/MessageS';
import { patchDraft } from '../../Service/restapi/messageService';
import { ModalDialog } from '../../Molecules/Dialogs';
import BaseToolbar from '../tiptap/toolbars/BaseToolbar';
import EmailReceiver from './EmailReceiver';
import { Editor } from '@tiptap/react';

const MessageModal: FC<{ loadTemplate: () => void }> = ({ loadTemplate }) => {
  const { id: eventId } = useParams();
  const { message, changeMessage } = useContext(MessageContext);
  const [subjectEditor, setSubjectEditor] = useState<Editor>();

  function deleteFileAttachment(index: number) {
    if (message) {
      const tmpFiles = message.attachments;
      tmpFiles.splice(index, 1);
      patchDraft(message.id, { attachments: tmpFiles })
        .then((resp) => {
          if (resp.ok) {
            resp.json().then(changeMessage);
          } else {
            console.error('could not delete attachment');
          }
        })
        .catch((error) => {
          console.error({ error });
          console.error('could not delete attachment');
        });
    }
  }

  const updateMessage = async (key: string, val: unknown) => {
    if (message) {
      MessageS.patchDraftMessage({ [key]: val }, message.id).then((resJSON) => {
        if (resJSON) {
          changeMessage(resJSON);
          return true;
        }
        return false;
      });
    }
    return false;
  };

  const [open, setOpen] = useState(false);

  if (!message) {
    return null;
  }

  return (
    <>
      <EmailReceiver />
      <Space h="md" />

      {eventId && (
        <EventBaseInput
          label="Betreff"
          value={message.subject}
          patchField={(value) => updateMessage('subject', value)}
          toolbar={<BaseToolbar />}
          setEditor={setSubjectEditor}
        />
      )}
      {!eventId && (
        <TextInput
          placeholder="Betreff"
          defaultValue={message.subject}
          onBlur={(e) => updateMessage('subject', e.currentTarget.value)}
        />
      )}
      <Space h="sm" />
      <Attachments
        attachments={message.attachments}
        deleteFileAttachment={deleteFileAttachment}
        eventId={eventId}
        documentBlueprints={undefined}
        deleteDocumentAttachment={undefined}
      />
      {message.attachments && message.attachments.length > 0 && <Space h="sm" />}
      {eventId && (
        <EventRichInput
          label="Nachricht"
          value={message.message}
          patchField={(value) => updateMessage('message', value)}
          toolbar={
            <MessageToolbar
              addAttachment={() => setOpen(true)}
              loadTemplate={loadTemplate}
              subjectEditor={subjectEditor}
            />
          }
        />
      )}
      {!eventId && (
        <RichInputWithoutMentions
          label="Nachricht"
          value={message.message}
          patchField={(value) => updateMessage('message', value)}
          toolbar={<MessageToolbar addAttachment={() => setOpen(true)} />}
        />
      )}

      <ModalDialog
        help="help.email.attachments.available.pane"
        header={<Trans id="message.attach.file" />}
        body={<AttachmentList eventId={eventId} emailTemplate={undefined} templateName={undefined} />}
        footer={
          <TransButtonDefault
            onClickHandler={() => {
              setOpen(false);
            }}
            text="actions.close"
          />
        }
        show={open}
        toggle={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default MessageModal;
