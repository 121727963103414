// eslint-disable-next-line no-redeclare
import { Skeleton } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { calcLocationDistanceAndDuration } from '../../Service/restapi/locationService';
import { EventLocationContext } from '../../Service/Context/EventLocationContext';
import Distance from './Recommendations/Distance';
import { ContactContext } from '../../Service/Context/ContactContext';

export const OwnDistance = () => {
  const { loading, distance } = useContext(EventLocationContext);

  if (loading) {
    return <Skeleton width="70%" height={25} mt={15} />;
  }
  if (!distance) {
    return null;
  }
  return <Distance fullWidth distance={distance} />;
};


export const OwnLocationDistance = ({ location }) => {
  const { contact } = useContext(ContactContext);
  const { id: contactId } = contact;
  const [isLoading, setLoading] = useState(false);
  const [distance, setDistance] = useState(undefined);

  useEffect(() => {
    setDistance(undefined);
    calc();
  }, [location, contactId]);

  function calc() {
    if (contactId && location && location.id) {
      setLoading(true);
      calcLocationDistanceAndDuration(contactId, location.id)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((distance) => {
              setDistance(distance);
            });
          } else {
            // alert("Hinterlege in den Einstellungen Deine Anschrift, damit die Entfernung berechnet werden kann.")
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  if (isLoading) {
    return <Skeleton width="70%" height={25} mt={15} />;
  }

  if (!distance) {
    return null;
  }
  return <Distance fullWidth distance={distance} />;
};

const LocationDistance = ({ contactId }) => {
  const { location } = useContext(EventLocationContext);
  const [isLoading, setLoading] = useState(false);
  const [distance, setDistance] = useState(undefined);

  useEffect(() => {
    setDistance(undefined);
    calc();
  }, [location, contactId]);

  function calc() {
    if (contactId && location && location.id) {
      setLoading(true);
      calcLocationDistanceAndDuration(contactId, location.id)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((distance) => {
              setDistance(distance);
            });
          } else {
            // alert("Hinterlege in den Einstellungen Deine Anschrift, damit die Entfernung berechnet werden kann.")
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  if (isLoading) {
    return <Skeleton width="70%" height={25} mt={15} />;
  }

  if (!distance) {
    return null;
  }
  return <Distance fullWidth distance={distance} />;
};

export default LocationDistance;
