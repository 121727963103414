// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import moment from 'moment';
import { BookitupNotification } from '../../../../Types/LogT';
import { RoutingS } from '../../../../Service/RoutingS';

const RequestReceived: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { eventId, eventDate, personDescription } = notification;

  if (!eventDate) {
    return (
      <Text>
        <Text
          span
          weight="bolder"
          variant="link"
          color="blue"
          onClick={() => RoutingS.openInNewTab(`/events/${eventId}/details`)}
          sx={{ cursor: 'pointer' }}
        >
          Neue Anfrage
        </Text>{' '}
        ohne Datum von
        <Text span weight="bolder">
          {` ${personDescription}`}.
        </Text>
      </Text>
    );
  }

  return (
    <Text>
      Neue Anfrage für den{' '}
      <Text
        span
        weight="bolder"
        variant="link"
        color="blue"
        onClick={() => RoutingS.openInNewTab(`/events/${eventId}/details`)}
        sx={{ cursor: 'pointer' }}
      >
        {eventDate && `${moment(eventDate).format('LL')}`}
      </Text>
      .
    </Text>
  );
};

export default RequestReceived;
