import { useContext } from 'react';
import { Group, Menu, ThemeIcon, Timeline, Title, Tooltip } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { isSameDay } from 'date-fns';
import { IconBallpen, IconTrash } from '@tabler/icons';
import PanePaper from '../../Molecules/PanePaper';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import BookitupTutorialIcon from '../../Atoms/BookitupTutorialIcon';
import { EventContext } from '../../Service/Context/EventContext';
import { EventDate, EventDatesHandlers } from '../../Types/Event';
import { EventS } from '../../Service/EventS';
import { EventDateS } from '../../Service/EventDateS';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import EventDateIcon from '../../Atoms/EventDateIcon';
import EmptyText from '../../Atoms/EmptyText';
import EventDateItem from './EventDateItem';
import { openCreateEventDateModal, openDeleteEventDateModal } from './EventDateModals';

const EventTimeline = () => {
  const { id: eventId } = useParams();
  const { dates, datesHandlers, assignPrimaryDate } = useContext<any>(EventContext);
  const { interceptViolation } = useContext(ViolationContext);
  const { append, edit, remove } = datesHandlers as EventDatesHandlers;
  const isEmpty = dates.length === 0;
  const regularDates = dates.filter((ed: EventDate) => ed.type === 'REGULAR_DATE');

  const addDate = () => {
    if (eventId) {
      openCreateEventDateModal({ eventId, onCreate: append });
    }
  };

  const changePrimaryDate = async (eventDateId: number) => {
    const ok = await onUpdate(eventDateId, { useAsPrimary: true });
    if (ok) {
      assignPrimaryDate(eventDateId);
    }
  };

  const onUpdate = async (id: number, patch: Partial<EventDate>): Promise<boolean> => {
    const eventDate = await EventDateS.edit(id, patch, interceptViolation);
    if (eventDate) {
      edit(eventDate);
      return true;
    }
    return false;
  };

  const onDelete = (eventDate: EventDate) =>
    openDeleteEventDateModal({
      eventDate,
      onDelete: () => {
        remove(eventDate.id);
      },
      interceptViolation,
    });

  const mapEventDate = (eventDate: EventDate) => {
    const { date, weekday, type, useAsPrimary } = eventDate;
    const done = new Date(date).getTime() < new Date().getTime();
    const isToday = isSameDay(new Date(date), new Date());

    return (
      <Timeline.Item
        bulletSize={32}
        lineWidth={3}
        lineVariant={done && !isToday ? 'solid' : 'dotted'}
        lineActive={done && !isToday}
        title={
          <Group position="apart">
            <Title order={4} pt={3} pl={4} weight="bolder">{`${weekday}, ${EventS.formatEventDate(
              new Date(date),
            )}`}</Title>
            <Menu shadow="md" width={180} transition="scale-y" position="bottom-end">
              <Menu.Target>
                <div>
                  <IconBallpen className="hooverable-icon" size={18} style={{ cursor: 'pointer' }} />
                </div>
              </Menu.Target>
              <Menu.Dropdown>
                {type !== 'APPOINTMENT' && <Menu.Item
                  disabled={useAsPrimary}
                  onClick={() => changePrimaryDate(eventDate.id)}
                >
                  Als primär markieren
                </Menu.Item>}
                <Menu.Item
                  disabled={useAsPrimary && regularDates.length > 1}
                  color="red"
                  onClick={() => onDelete(eventDate)}
                  icon={<IconTrash size={16} />}
                >
                  Löschen
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        }
        bullet={
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <Tooltip label={<>{type === 'APPOINTMENT' ? 'Besprechung' : 'Event-Zeitraum'}</>} position="right" transition="slide-right">
            <ThemeIcon radius="xl" size="lg" color={type === 'APPOINTMENT' ? 'orange' : 'blue'}>
              <EventDateIcon done={done} eventDate={eventDate} isToday={isToday} />
            </ThemeIcon>
          </Tooltip>
        }
      >
        <EventDateItem key={eventDate.id} eventDate={eventDate} onUpdate={(patch) => onUpdate(eventDate.id, patch)} />
      </Timeline.Item>
    );
  };

  return (
    <PanePaper
      title="common.dateandkind"
      tooltip="help.event.dates.pane"
      rightSection={
        <Group spacing={5}>
          <BookitupTutorialIcon
            videoUrl="https://app.bookitup.de/academy/channels/perfect-overview?episodeId=77tp5g4owb"
            name="EventDetails"
          />
          <BookitupAddIcon onClick={addDate} />
        </Group>
      }
    >
      {isEmpty && <EmptyText />}
      {!isEmpty && (
        <Timeline mt="sm" bulletSize={24} lineWidth={4}>
          {dates.map(mapEventDate)}
        </Timeline>
      )}
    </PanePaper>
  );
};

export default EventTimeline;
