import { ActionIcon, Menu } from '@mantine/core';
import {
  IconBallpen,
  IconDotsVertical,
  IconPlayerPlay,
  IconPlayerSkipForward,
  IconPlayerStop,
  IconRefreshAlert,
  IconTrash,
  IconUserCheck,
} from '@tabler/icons';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkflowS } from '../../../Service/WorkflowS';
import { WorkflowDTO, WorkflowOperation } from '../../../Types/LogT';

interface Props {
  workflow: WorkflowDTO;
  setWorkflow: (workflow: WorkflowDTO) => void;
  onDelete: () => void;
  closeAccordionItem: () => void;
}

const WorkflowActions: FC<Props> = ({ workflow, setWorkflow, onDelete, closeAccordionItem }) => {
  const { id, templateId, startedAt, executionStatus, completedAt, abortedAt, steps } = workflow;
  const failed = executionStatus === 'FAILED' || executionStatus === 'PRECONDITION_FAILED';
  const navigate = useNavigate();

  const runOperation = async (operation: WorkflowOperation) => {
    const resJSON = await WorkflowS.runWorkflowOperation(id, operation);
    if (resJSON) {
      setWorkflow(resJSON);
    }
  };

  const removeWorkflow = async () => {
    const deleted = await WorkflowS.deleteWorkflow(workflow);
    if (deleted) {
      onDelete();
    }
  };

  return (
    <ActionIcon size="lg" mt="sm">
      <Menu radius="md" shadow="md" transition="scale-y" position="bottom-end">
        <Menu.Target>
          <div>
            <IconDotsVertical id={"open-workflow-menu"} color="gray" style={{ cursor: 'pointer' }} onClick={closeAccordionItem} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          {!startedAt && steps.length > 0 && (
            <Menu.Item
              id="start-workflow"
              icon={<IconPlayerPlay color="#4EBF46" size={16} />}
              onClick={() => runOperation('START')}
            >
              Workflow starten
            </Menu.Item>
          )}
          {failed && (
            <Menu.Item icon={<IconRefreshAlert size={16} color="red" />} onClick={() => runOperation('RETRY')}>
              Wiederholen
            </Menu.Item>
          )}
          {executionStatus === 'APPROVAL_REQUIRED' && (
            <Menu.Item icon={<IconUserCheck size={16} color="orange" />} onClick={() => runOperation('APPROVE')}>
              Jetzt genehmigen
            </Menu.Item>
          )}
          {executionStatus === 'PAUSED' && (
            <Menu.Item
              icon={<IconPlayerSkipForward size={16} color="orange" />}
              onClick={() => runOperation('CONTINUE')}
            >
              Weitermachen
            </Menu.Item>
          )}
          {startedAt &&
            !abortedAt &&
            !completedAt &&
            executionStatus !== 'PAUSED' &&
            !failed &&
            executionStatus !== 'APPROVAL_REQUIRED' && (
              <Menu.Item icon={<IconPlayerStop size={16} color="red" />} onClick={() => runOperation('PAUSE')}>
                Stoppen
              </Menu.Item>
            )}
          {templateId && (
            <Menu.Item
              icon={<IconBallpen size={16} />}
              onClick={() => navigate(`/settings/workflow/templates/${templateId}`)}
            >
              Vorlage bearbeiten
            </Menu.Item>
          )}
          <Menu.Item color="red" icon={<IconTrash size={16} />} onClick={removeWorkflow}>
            Löschen
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </ActionIcon>
  );
};

export default WorkflowActions;
