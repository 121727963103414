// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import moment from 'moment';
import { BookitupNotification } from '../../../../Types/LogT';
import { RoutingS } from '../../../../Service/RoutingS';

const MailReceived: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { personDescription, mailSubject, eventDate, eventId } = notification;

  return (
    <Text
      onClick={() => eventId && RoutingS.openInNewTab(`/events/${eventId}/emails/inbox`)}
      sx={{ cursor: eventId ? 'pointer' : 'default' }}
    >
      Du hast eine E-Mail{'  '}
      <Text span weight="bolder">
        {mailSubject}
      </Text>
      {'  '}
      von{'  '}
      <Text span weight="bolder">
        {personDescription}
      </Text>
      {eventDate && (
        <>
          {'  '}für das Event am {moment(eventDate).format('LL')}
        </>
      )}
      {'  '}
      erhalten.
    </Text>
  );
};

export default MailReceived;
