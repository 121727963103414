import { Menu } from '@mantine/core';
import { IconBallpen, IconDotsVertical, IconPlus, IconTrashX } from '@tabler/icons';
import { FC, useContext } from 'react';
import { WorkflowStepTemplateDTO } from '../../../Types/LogT';
import { WorkflowS } from '../../../Service/WorkflowS';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { openEditStepModal } from './WorkflowTemplateModals';
import { WorkflowTemplateContext } from './WorkflowTemplate';

interface Props {
  index: number;
  workflowStep: WorkflowStepTemplateDTO;
  alternativeFlow: boolean;
  handleConfigChange?: (cfg: any) => unknown;
}

const WorkflowStepOptions: FC<Props> = ({ index, workflowStep, alternativeFlow = false, handleConfigChange }) => {
  const { id: stepId, procedureType } = workflowStep;
  const { refresh, addStep, getAvailableEntities, setRemovingStep } = useContext(WorkflowTemplateContext);
  const { interceptViolation } = useContext(ViolationContext);

  const editStep = () => {
    const availableEntities = getAvailableEntities(index, alternativeFlow);
    openEditStepModal({ workflowStep, refresh, availableEntities, interceptViolation, handleConfigChange });
  };

  const deleteStep = async () => {
    setRemovingStep(true);
    const removed = await WorkflowS.deleteWorkflowStep(stepId, interceptViolation);
    setRemovingStep(false);
    if (removed) refresh();
  };

  return (
    <div>
      <Menu radius="md" shadow="md" width={200} transition="slide-down" position="bottom-end">
        <Menu.Target>
          <div>
            <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<IconBallpen size={16} />} onClick={editStep}>
            Bearbeiten
          </Menu.Item>
          {procedureType !== 'WAIT_UNTIL' && (
            <Menu.Item icon={<IconPlus size={16} />} onClick={() => addStep(index + 1, alternativeFlow)}>
              Unterhalb hinzufügen
            </Menu.Item>
          )}
          <Menu.Item color="red" icon={<IconTrashX size={16} />} onClick={deleteStep}>
            Löschen
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default WorkflowStepOptions;
