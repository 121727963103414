import { Box, Checkbox, Group, Skeleton, Text, TextInput, ThemeIcon, Tooltip } from '@mantine/core';
import React, { FC, useContext, useEffect, useState } from 'react';
import {
  IconBallpen, IconCalendar,
  IconChevronDown,
  IconChevronRight,
  IconSearch
} from '@tabler/icons';
import { useDebouncedCallback } from 'use-debounce';
import { i18n } from '@lingui/core';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { WindowContext } from '../../Service/Context/WindowContext';
import BookingStateItem from '../../Templates/EventList/BookingStateItem';
import ResourceCategoryS from '../../Service/restapi/resourceCategoryService';
import { ResourceCategory } from '../../Types/ResourceT';
import { ResourcesContext } from '../../Service/Context/ResourcesContext';
import { ResourceAddonContext } from '../../Service/Context/ResourceAddonsContext';
import AddResourceIcon from './AddResourceIcon';
import { openAvailabilityModal } from './ResourceModals';
import BookitupTutorialIcon from "../../Atoms/BookitupTutorialIcon";

const ResourcesNavBar = () => {
  const { sm } = useContext(WindowContext);
  const { filter, updateFilter } = useContext(ResourcesContext);
  const { executeWithCheck } = useContext(ResourceAddonContext);
  const navigate = useNavigate();
  const { query, typesOfInterest, showArchived } = filter;

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<ResourceCategory[]>([]);

  const toggleType = (type: string) => {
    if (typesOfInterest.includes(type)) {
      const newTypes = typesOfInterest.filter((c) => c !== type);
      updateFilter({ typesOfInterest: newTypes });
    } else {
      updateFilter({ typesOfInterest: [...typesOfInterest, type] });
    }
  };

  const updateQuery = useDebouncedCallback((val: string) => {
    updateFilter({ query: val });
  }, 500);

  useEffect(() => {
    setLoading(true);
    ResourceCategoryS.getCategories()
      .then(setCategories)
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {!sm && (
        <Group sx={{ position: 'relative' }} mt={5} mb={5}>
          <Text p="md" weight="bolder" size="xl">
            Ressourcen
          </Text>
          <Group spacing="xs" sx={{ position: 'absolute', right: 10, top: 15 }}>
            <BookitupTutorialIcon videoUrl={"https://app.bookitup.de/academy/channels/rental?episodeId=5ych0299qm"} name={"CreateResource"} />
            <Tooltip withArrow label="Verfügbarkeit der Ressourcen abfragen" position="bottom" transition="slide-down">
              <ThemeIcon
                size={20}
                style={{ cursor: 'pointer' }}
                variant="gradient"
                gradient={{ from: '#FEC170', to: '#FDA632', deg: 135 }}
              >
                {/* TODO: Change icon later to clock-check */}
                <IconCalendar onClick={openAvailabilityModal} />
              </ThemeIcon>
            </Tooltip>
            <AddResourceIcon />
          </Group>
        </Group>
      )}
      <Box p="xs" mt={5}>
        <TextInput
          defaultValue={query}
          variant="filled"
          placeholder="Ressourcen durchsuchen"
          icon={<IconSearch size={16} />}
          onChange={(e) => updateQuery(e.currentTarget.value)}
        />
      </Box>
      <Text mt="md" pl={10} mb="sm">
        Type
      </Text>
      {['KIT', 'CASE', 'ITEM'].map((type) => (
        <BookingStateItem
          key={type}
          color="#757575"
          bgColor="#eeeeee"
          label={i18n._(`resource.type.${type}`)}
          onClick={() => toggleType(type)}
          active={typesOfInterest.includes(type)}
        />
      ))}
      <Group position="apart">
        <Text mt="xl" pl={10} mb="sm">
          Kategorie
        </Text>
        <div style={{ marginRight: 15, marginTop: 10 }}>
          <IconBallpen
            size={16}
            style={{ cursor: 'pointer' }}
            onClick={() => executeWithCheck(() => navigate('/settings/resources'))}
          />
        </div>
      </Group>
      {loading && (
        <Box p="md" pt={0}>
          <Skeleton height={100} />
        </Box>
      )}
      {!loading && categories.map((c) => <CategoryFilterItem key={c.id} category={c} />)}
      {categories.length === 0 && (
        <Text ml="sm" italic color="dimmed" size="xs" weight="lighter">
          Keine Kategorie angelegt.
        </Text>
      )}
      <Checkbox
        p="sm"
        mt="md"
        label="Archivierte anzeigen"
        checked={showArchived}
        onChange={() => updateFilter({ showArchived: !showArchived })}
      />
    </>
  );
};

interface Props {
  category: ResourceCategory;
  depth?: number;
}

const CategoryFilterItem: FC<Props> = ({ category, depth = 0 }) => {
  const { name, children } = category;
  const [opened, { toggle }] = useDisclosure(false);
  const { filter, updateFilter } = useContext(ResourcesContext);
  const { categoriesOfInterest } = filter;

  // eslint-disable-next-line no-underscore-dangle
  const _onClick = (_category: ResourceCategory) => {
    if (children.length === 0) {
      toggleCategory(_category.name);
    } else {
      toggleParentCategory(_category, !categoriesOfInterest.includes(_category.name));
    }
  };

  const toggleCategory = (categoryName: string) => {
    if (categoriesOfInterest.includes(categoryName)) {
      const newCategories = categoriesOfInterest.filter((c) => c !== categoryName);
      updateFilter({ categoriesOfInterest: newCategories });
    } else {
      updateFilter({ categoriesOfInterest: [...categoriesOfInterest, categoryName] });
    }
  };

  const toggleParentCategory = (_category: ResourceCategory, add: boolean) => {
    const subCategories = ResourceCategoryS.getAllSubCategories(_category);
    subCategories.push(_category.name);
    if (add) {
      const tmp = subCategories.filter((_name) => !categoriesOfInterest.includes(_name));
      updateFilter({ categoriesOfInterest: [...categoriesOfInterest, ...tmp] });
    } else {
      const newCategories = categoriesOfInterest.filter((c) => !subCategories.includes(c));
      updateFilter({ categoriesOfInterest: newCategories });
    }
  };

  return (
    <>
      <Group spacing={0} sx={{ paddingLeft: depth * 10 }}>
        <div style={{ width: 20 }}>
          {children.length > 0 && (
            <>
              {opened && <IconChevronDown size={20} onClick={toggle} style={{ cursor: 'pointer' }} />}
              {!opened && <IconChevronRight size={20} onClick={toggle} style={{ cursor: 'pointer' }} />}
            </>
          )}
        </div>
        <BookingStateItem
          sx={{ flexGrow: 1 }}
          color="#757575"
          bgColor="#eeeeee"
          label={name}
          active={categoriesOfInterest.includes(name)}
          onClick={() => _onClick(category)}
        />
      </Group>
      {opened &&
        children.map((childrenCategory) => (
          <CategoryFilterItem key={childrenCategory.id} category={childrenCategory} depth={depth + 1} />
        ))}
    </>
  );
};

export default ResourcesNavBar;
