// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Menu, Box, Text } from '@mantine/core';
import { openModal } from '@mantine/modals';
import { IconDots, IconDotsVertical, IconMailForward, IconSend, IconTrashX } from '@tabler/icons';
import { FC, useContext } from 'react';
import { i18n } from '@lingui/core';
import { addDays } from 'date-fns';
import { RecommendationDto } from '../../../Types/RecommendationT';
import { ToastS } from '../../../Service/ToastS';
import CheckAvailabilityModal from './Modals/CheckAvailabilityModal';
import RecommendationDeleteModal from './Modals/RecommendationDeleteModal';
import RecommendationEmailModal from './Modals/RecommendationEmailModal';
import { RecommendationContext } from './RecommendationsPane';

interface Props {
  recommendation: RecommendationDto;
  contactEmail: string | null | undefined;
}

const RecommendationActions: FC<Props> = ({ recommendation, contactEmail }) => {
  const { id: recommendationId, lastRequested } = recommendation;
  const { removeRecommendation, removeAll, reload } = useContext(RecommendationContext);

  const openDeleteModal = () =>
    openModal({
      title: (
        <Text weight="bolder" size="xl" sx={{ lineHeight: 1.2 }}>
          {i18n._('event.recommendations.delete.confirm.head')}
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: (
        <RecommendationDeleteModal
          recommendationId={recommendationId}
          removeRecommendation={removeRecommendation}
          removeAllRecommendations={removeAll}
          contactEmail={contactEmail}
        />
      ),
    });

  const openSendInfoEmailModal = () =>
    openModal({
      title: (
        <Text weight="bolder" size="xl" sx={{ lineHeight: 1.2 }}>
          E-Mail senden
        </Text>
      ),
      children: <RecommendationEmailModal recommendationId={recommendationId} />,
    });

  const openQueryAvailabilityModal = () => {
    const queryAvailable = 1;// !lastRequested || (lastRequested && addDays(new Date(lastRequested), 1) < new Date());
    if (queryAvailable) {
      openModal({
        size: 'md',
        title: (
          <Text weight="bolder" size="xl" sx={{ lineHeight: 1.2 }}>
            {i18n._('recommendation.request.submit.head')}
          </Text>
        ),
        children: <CheckAvailabilityModal recommendations={[recommendation]} reload={reload} />,
      });
    } else {
      ToastS.info(
        'query-availability',
        'Die Verfügbarkeit eines Dienstleisters kann nur alle 24 Stunden abgefragt werden.',
      );
    }
  };

  return (
    <Menu shadow="md" width={225} position="bottom-end" transition="scale-y">
      <Menu.Target>
        <Box sx={{ cursor: 'pointer' }}>
          <IconDots color="gray" />
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item icon={<IconSend size={16} />} onClick={openSendInfoEmailModal} disabled={!contactEmail}>
          E-Mail senden
        </Menu.Item>
        <Menu.Item icon={<IconMailForward size={16} />} onClick={openQueryAvailabilityModal}>
          Verfügbarkeit abfragen
        </Menu.Item>
        <Menu.Item color="red" icon={<IconTrashX size={16} />} onClick={openDeleteModal}>
          Löschen
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default RecommendationActions;
