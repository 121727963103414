import { UseListStateHandlers } from '@mantine/hooks';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { CalendarApi } from '@fullcalendar/core';
import { ExternalSubscription } from '../../Types/CalDavT';
import { useExternalSubscriptions } from '../hooks/useExternalSubscriptions';
import { CalendarS, TASKS_EVENT_SOURCE } from '../CalendarS';
import { GoogleCalendarDto } from '../../Types/GoogleT';
import { GoogleS } from '../GoogleS';
import { EventsContext } from './EventsContext';

interface ICalContextInterface {
  loading: boolean;
  subscriptions: ExternalSubscription[];
  handlers: UseListStateHandlers<ExternalSubscription>;
  setCalendar: (cal: CalendarApi) => void;
  loadTasks: () => void;
  googleCalendars: GoogleCalendarDto[];
  refreshExternalICal: (subscription: ExternalSubscription) => void;
  showTasks: boolean;
  setShowTasks: (val: boolean) => void;
}

export const ICalContext = React.createContext<ICalContextInterface>({} as ICalContextInterface);

export const ICalContextProvider = () => {
  const { filter } = useContext(EventsContext);
  const { loading, subscriptions, handlers } = useExternalSubscriptions();
  const [calendar, setCalendar] = useState<CalendarApi>();
  const [googleCalendars, setGoogleCalendars] = useState<GoogleCalendarDto[]>([]);
  const [loadingGoogleCals, setLoadingGoogleCals] = useState(false);
  const [showTasks, setShowTasks] = useState(true);

  useEffect(() => {
    setLoadingGoogleCals(true);
    GoogleS.fetchReadOnlyCalendars()
      .then(setGoogleCalendars)
      .finally(() => setLoadingGoogleCals(false));
  }, []);

  useEffect(() => {
    if (calendar && googleCalendars.length > 0) {
      CalendarS.addGoogleCals(calendar, googleCalendars);
    }
  }, [calendar, googleCalendars]);

  useEffect(() => {
    if (calendar) {
      CalendarS.addBookitupEvents(calendar, filter);
    }
  }, [filter, calendar]);

  useEffect(() => {
    if (calendar) {
      CalendarS.addExternalCalsAsEventSource(calendar, subscriptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar]);

  useEffect(() => {
    if (calendar) {
      if (showTasks) {
        loadTasks();
      } else {
        CalendarS.removeEventSource(calendar, TASKS_EVENT_SOURCE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar, showTasks]);

  const refreshExternalICal = (sub: ExternalSubscription) => {
    if (calendar) {
      CalendarS.addExternalCal(calendar, sub, true);
    }
  };

  const loadTasks = () => {
    if (calendar) {
      CalendarS.addAgendaEventSource(calendar);
    }
  };

  const value = useMemo(
    () => ({
      loading: loading && loadingGoogleCals,
      subscriptions,
      handlers,
      showTasks,
      setShowTasks,
      setCalendar,
      loadTasks,
      refreshExternalICal,
      googleCalendars,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handlers, showTasks, loading, subscriptions, googleCalendars],
  );
  return (
    <ICalContext.Provider value={value}>
      <Outlet />
    </ICalContext.Provider>
  );
};
