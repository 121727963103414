// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookitupNotification } from '../../../../Types/LogT';

const CalendarUnreachable: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { value } = notification;
  const navigate = useNavigate();

  return (
    <Text>
      Der Kalender{' '}
      <Text span weight="bolder">
        {value}
      </Text>{' '}
      konnte nicht abgerufen werden. Bitte prüfe deine{' '}
      <Text
        span
        className="pointer"
        weight="bolder"
        variant="link"
        color="blue"
        onClick={() => navigate(`/settings/calendar`)}
      >
        Kalender-Einstellungen
      </Text>
      .
    </Text>
  );
};

export default CalendarUnreachable;
