import { useState, useEffect } from 'react';
import { useListState } from '@mantine/hooks';
import { GoogleAccountDTO } from '../../Types/GoogleT';
import { GoogleS } from '../GoogleS';

// eslint-disable-next-line import/prefer-default-export
export const useGoogleAccounts = () => {
  const [loading, setLoading] = useState(false);
  const [googleAccounts, { setState: setGoogleAccounts, applyWhere, filter }] = useListState<GoogleAccountDTO>([]);

  useEffect(() => {
    fetchGoogleAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchGoogleAccounts = () => {
    setLoading(true);
    GoogleS.fetchAccounts()
      .then(setGoogleAccounts)
      .finally(() => setLoading(false));
  };

  const editAccount = (account: GoogleAccountDTO) => {
    applyWhere(
      (acc) => acc.id === account.id,
      () => account,
    );
  };

  const removeAccount = (accountId: number) => filter((acc) => acc.id !== accountId);

  return { loading, googleAccounts, refresh: fetchGoogleAccounts, editAccount, removeAccount };
};
